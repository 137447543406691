<template>
    <section class="admon-banyax" style="display: flex; align-items: center; justify-content: center;">
      <v-container fluid>
        <div class="mb-2">
          <v-btn
            class="rounded-xl mr-1"
            large
            depressed
            :color="opcion === 'reporte' ? 'primary' : 'white'"
            :dark="opcion === 'reporte' ? true : false"
            @click="opcion = 'reporte'"
          >
            Reporte Semanal
          </v-btn>
          <v-btn
            class="rounded-xl mr-1"
            large
            depressed
            :color="opcion === 'usuarios' ? 'primary' : 'white'"
            :dark="opcion === 'usuarios' ? true : false"
            @click="opcion = 'usuarios'"
          >
            Usuarios
          </v-btn>
        </div>
        <div v-if="opcion === 'reporte'">
          <AdmonBanyax />
        </div>
        <div v-else-if="opcion === 'usuarios'">
          <AdmonUsuariosBanyax />
        </div>
      </v-container>
    </section>
  </template>
  
  <script>
  import AdmonBanyax from "./AdmonBanyax.vue";
  import AdmonUsuariosBanyax from "./AdmonUsuariosBanyax.vue";
  
  export default {
    components: {
      AdmonBanyax,
      AdmonUsuariosBanyax,
    },
    data() {
      return {
        opcion: "reporte", 
      };
    },
  };
  </script>
  