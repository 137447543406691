<template>
    <div>
        <v-snackbar v-model="snackbar.visible" :color="snackbar.color">
            {{ snackbar.text }}
            <v-btn color="white" text @click="snackbar.visible = false">Cerrar</v-btn>
        </v-snackbar>

        <v-dialog v-model="dialogCargaExcel" width="800" content-class="fix-border-dialog" persistent>
            <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" color="primary" class="rounded-pill elevation-0 mb-3" block>
                    <v-icon left>mdi-cash-multiple</v-icon>
                    Cargar intereses
                </v-btn>
            </template>

            <v-card class="rounded-xl">
                <v-card-title class="overline font-weight-bold white--text primary">
                    Carga de intereses por tipo de nómina
                    <v-spacer></v-spacer>
                    <v-btn v-show="!mostrarLoading" icon dark @click="dialogCargaExcelCerrar">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>

                <v-card-text class="pt-5">
                    <v-row>
                        <v-col cols="12" sm="4" md="4">
                            <v-select v-show="!mostrarLoading" :rules="reglasNomina" v-model="tipoNomina" :items="tiposNomina"
                                item-text="text" item-value="value" label="Tipo de nómina"
                                prepend-icon="mdi-clipboard-text" required></v-select>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-text-field v-show="!mostrarLoading" :rules="reglasPeriodo" placeholder="000"
                                v-model="periodo" label="Periodo" prepend-icon="mdi-calendar" required></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="4" md="4">
                            <v-file-input v-show="!mostrarLoading" accept=".xlsx, .xls, .xlsm, .xlsb, .csv"
                                placeholder="Intereses de caja" label="Selecciona el excel"
                                prepend-icon="mdi-microsoft-excel" @change="handleFileUpload" v-model="file"
                                :disabled="!tipoNomina || !periodo"></v-file-input>
                            <span v-show="mostrarLoading">Procesando e insertando información...</span>
                            <v-progress-linear v-show="mostrarLoading" indeterminate color="blue darken-4"
                                class="mt-2"></v-progress-linear>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    mounted() {

    },
    data() {
        return {
            dialogCargaExcel: false,
            snackbar: {
                visible: false,
                text: '',
                color: ''
            },
            //
            menu: false,
            archivo: null,
            file: null,
            mostrarLoading: false,
            tiposNomina:
                [
                    { text: "Semanal", value: 1 },
                    { text: "Quincenal", value: 2 },
                    { text: "Comisionistas", value: 3 },
                ],
            tipoNomina: null,
            periodo: null,
            reglasPeriodo: [
                (v) => !!v || "Campo requerido para carga de excel",
                (v) => /^\d{3}$/.test(v) || "Ingresa un periodo valido, como '024'",
            ],
            reglasNomina: [
                (v) => !!v || "Campo requerido para carga de excel",
            ],
        };
    },
    methods: {
        async handleFileUpload() {
            if (!this.file) return;

            this.mostrarLoading = true;
            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('tipoNomina', this.tipoNomina);
            formData.append('periodo', this.periodo);

            try {
                const response = await axios.put(`${this.$store.getters.getHostNode}/api/setInteresesCaja`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        authorization: `${localStorage.getItem("token")}`,
                    },
                });

                if (response.data.status === 'OK') {
                    this.snackbar = {
                        visible: true,
                        text: 'Archivo procesado correctamente',
                        color: 'success'
                    };
                    this.muestraAgregaExcel = false;
                } else {
                    this.snackbar = {
                        visible: true,
                        text: 'Error al procesar el archivo',
                        color: 'error'
                    };
                }
            } catch (error) {
                this.snackbar = {
                    visible: true,
                    text: 'Error de servidor: ' + error.message,
                    color: 'error'
                };
            } finally {
                this.mostrarLoading = false;
                this.file = null;  // Clear the file input
                this.comisionistas.length = 0;
                const comisionesData = await this.getComisiones();
                this.comisionistas = comisionesData.comisionistas;
                this.itemsExcel = comisionesData.itemsExcel;
            }
        },
        dialogCargaExcelCerrar() {
            this.tipoNomina = null;
            this.periodo = null;
            this.dialogCargaExcel = false;
        }
    },
};
</script>