<template>
  <v-card class="rounded-lg" elevation="10" transition="scroll-x-transition">
    <div class="text-center pa-2">
      <v-btn class="rounded-lg mx-1 font-weight-bold" depressed large color="blue darken-3" dark
        @click="openShowFam = true">
        <v-icon left>mdi-eye</v-icon>
        {{ $t("DH360.textBtnShowFamilies") }}
      </v-btn>
      <!--   <v-btn
        class="rounded-lg mx-1 blue--text text--darken-4 font-weight-bold"
        depressed
        large
        color="#f2e200"
        @click="confirmReloadEmpLead = true"
      >
        <v-icon left>mdi-reload</v-icon>
        {{ $t("DH360.textBtnReloadRelEmpResp1") }}
        <br />
        {{ $t("DH360.textBtnReloadRelEmpResp2") }}
      </v-btn>-->
      <v-btn v-if="avance < 100" class="rounded-lg mx-1 font-weight-bold" depressed disabled large color="primary">
        <v-icon left>mdi-email-fast-outline</v-icon>
        {{ $t("DH360.textBtnNotifyClientLoad") }}
      </v-btn>
      <v-btn v-if="avance == 100" class="rounded-lg mx-1 font-weight-bold" depressed large color="primary" dark
        @click="confirmSendEmailClients = true">
        <v-icon left>mdi-email-fast-outline</v-icon>
        {{ $t("DH360.textBtnNotifyClientLoad") }}
      </v-btn>
    </div>
    <div style="display:flex; justify-content:center;">
      <v-progress-linear class="my-5 text-center" v-model="avance" color="teal darken-3" rounded height="25" :loading="loadingData"
        style="max-width:700px;">
        <strong style="color:white">{{ $t("DH360.progress") }} {{ $t("DH360.creating").toLowerCase() }}
          {{ $t("DH360.families").toLowerCase() }}
          {{ avance.toFixed(1) }}%</strong>
      </v-progress-linear>
    </div>
    <v-card class="rounded-xl elevation-5 mt-1 pa-3 ma-2">
      <v-data-table class="mx-2 mb-5" :headers="headers" :items="participantesEnFamilias" :items-per-page="itemsPerPage"
        :footer-props="{ 'items-per-page-text': 'Elementos por página' }" no-data-text="No hay registros"
        :header-props="headerProps" :search="search" :loading="loadingData"
        :loading-text="$t('DH360.gettingParticipants')" :expanded.sync="expanded" :single-expand="singleExpand"
        item-key="ID_EMPLEADO" show-expand dense>

        <template v-slot:top>
          <div style="display: flex; justify-content: space-between;">
            <v-text-field v-model="search" outlined dense class="rounded-xl" :label="$t('DH360.findTextBox')"
              style="max-width: 350px;" prepend-inner-icon="mdi-table-search"></v-text-field>
            <div>
              <vue-json-to-csv :json-data="itemsExcelParticipantes" :labels="headers_report_to_exportParticipantes"
                :csv-title="titleParticipantes" class="ma-0">
                <v-tooltip bottom color="green darken-2">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined depressed class="rounded-pill ml-2" dark color="green darken-2" v-bind="attrs"
                      v-on="on" @click="exportToCsvParticipantes">
                      <v-icon fab>mdi-microsoft-excel</v-icon>
                      <v-icon fab>mdi-account-multiple-outline</v-icon>
                    </v-btn>
                  </template>
                  <span class="text-uppercase font-weight-bold white--text">Descarga los participantes</span>
                </v-tooltip>
              </vue-json-to-csv>
              <vue-json-to-csv :json-data="itemsExcelFamilias" :labels="headers_report_to_exportFamilias"
                :csv-title="titleFamilias" class="ma-0">
                <v-tooltip bottom color="indigo">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn outlined depressed class="rounded-pill ml-2" dark color="indigo" v-bind="attrs"
                      v-on="on" @click="exportToCsvFamilias">
                      <v-icon fab>mdi-microsoft-excel</v-icon>
                      <v-icon fab>mdi-family-tree</v-icon>
                    </v-btn>
                  </template>
                  <span class="text-uppercase font-weight-bold white--text">Descarga las familias creadas</span>
                </v-tooltip>
              </vue-json-to-csv>
              <v-tooltip bottom color="blue lighten-4">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn outlined small fab depressed class="ml-2" v-bind="attrs" v-on="on" color="primary"
                    @click="getParticipantes360()">
                    <v-icon>mdi-reload</v-icon>
                  </v-btn>
                </template>
                <label class="text-uppercase font-weight-bold primary--text">Actualizar tabla</label>
              </v-tooltip>
            </div>
          </div>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td :colspan="headers.length" class="px-0 mx-0">
            <v-row dense>
              <v-col cols="12" xs="6" sm="6" md="3">
                <v-subheader class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold">
                  {{ $t("DH360.positionTextHeader") }}
                </v-subheader>
                <v-subheader class="tam-subheader py-0 mt-0">
                  {{ item.PUESTO }}
                </v-subheader>
              </v-col>
              <v-col cols="12" xs="6" sm="6" md="3">
                <v-subheader class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold">
                  {{ $t("DH360.depTextHeader") }}
                </v-subheader>
                <v-subheader class="tam-subheader py-0 mt-0">
                  {{ item.DEPTO }}
                </v-subheader>
              </v-col>
              <v-col cols="12" xs="6" sm="6" md="3">
                <v-subheader class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold">
                  {{ $t("DH360.areaTextHeader") }}
                </v-subheader>
                <v-subheader class="tam-subheader py-0 mt-0">
                  {{ item.Area }}
                </v-subheader>
              </v-col>
              <v-col cols="12" xs="6" sm="6" md="3">
                <v-subheader class="tam-subheader py-0 mt-0 blue-grey--text text--darken-1 font-weight-bold">
                  {{ $t("DH360.statusTextHeader") }} EMP.
                </v-subheader>
                <v-subheader class="tam-subheader py-0 mt-0">
                  {{ item.EMP_STATUS }}
                </v-subheader>
              </v-col>
            </v-row>
          </td>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon small v-bind="attrs" v-on="on" class="mx-2" @click="openDelete(item)">
                mdi-delete
              </v-icon>
            </template>
            <span>Eliminar de evaluación</span>
          </v-tooltip>
        </template>

        <template v-slot:item.Status="{ item }">
          <p class="font-weight-bold text-center green--text text--darken-3 my-2 pa-0" v-if="item.Status == 1">
            {{ $t("DH360.assigned") }}
          </p>
          <p class="font-weight-bold text-center red--text text--darken-3 my-2 pa-0" v-else>
            {{ $t("DH360.dontAssigned") }}
          </p>
        </template>
      </v-data-table>
    </v-card>
    <v-dialog v-model="confirmReloadEmpLead" max-width="450" scrollable persistent>
      <v-card class="rounded-lg">
        <p class="text-center pt-4 px-2" style="font-size: 1.1rem; font-weight:600; color:darkslategray">
          {{ $t("DH360.textTitleDialogConfirmReload") }} <br />
          {{ $t("DH360.textTitleDialogConfirmReloadQuestion") }}
        </p>
        <v-card-actions style="display:flex; justify-content: space-around;">
          <v-btn class="rounded-lg" large depressed color="pink darken-3" width="180" :disabled="loadButtonReload" dark
            @click="confirmReloadEmpLead = false">
            {{ $t("DH360.cancel") }}
          </v-btn>
          <v-btn class="rounded-lg" large depressed color="teal" width="180" :loading="loadButtonReload" dark @click="
            loadButtonReload = true;
          reloadRelEmpLead();
          ">
            {{ $t("DH360.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmDelete" max-width="450" scrollable persistent>
      <v-card class="rounded-lg">
        <p class="text-center pt-4 px-2" style="font-size: 1.1rem; font-weight:600; color:darkslategray">
          {{ $t("DH360.confirmTextDeleteParticipant") }}
          {{ participantToDelete }} ?
        </p>
        <v-card-actions style="display:flex; justify-content: space-around;">
          <v-btn class="rounded-lg" large depressed color="pink darken-3" :disabled="loadButtonDelPart" dark @click="
            confirmDelete = false;
          participantToDelete = '';
          ">
            {{ $t("DH360.cancel") }}
          </v-btn>
          <v-btn class="rounded-lg" large depressed color="teal" :loading="loadButtonDelPart" dark @click="
            loadButtonDelPart = true;
          delParticipant(itemToDelete);
          ">
            {{ $t("DH360.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmSendEmailClients" max-width="450" scrollable persistent>
      <v-card class="rounded-lg">
        <v-card-title class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold">
          {{ $t("DH360.textTitleDialogConfirmReloadQuestion") }}
          <v-spacer></v-spacer>
          <v-btn icon dark @click="confirmSendEmailClients = false" :disabled="loadButtonSenMail">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-actions>
          <v-btn class="rounded-lg" large depressed color="teal" :loading="loadButtonSenMail" dark @click="
            loadButtonSenMail = true;
          sendAddClientes();
          ">
            {{ $t("DH360.confirm") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <SnackBarErrorIncidencias :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra"
      @cierraBarra="muestraBarra = false" />

    <MostrarFamilias360 :verFamilias="openShowFam" :idProyecto="idProyecto" :compania="compania"
      @cerrarVerFamilias="cerraVerFamilias" />
  </v-card>
</template>

<script>
import axios from "axios";
import SnackBarErrorIncidencias from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import MostrarFamilias360 from "./MostrarFamilias360.vue";
import VueJsonToCsv from 'vue-json-to-csv';


export default {
  name: "admon-familias-360",
  components: { SnackBarErrorIncidencias, MostrarFamilias360, VueJsonToCsv },
  props: ["participantes", "estatus", "idProyecto", "proyecto", "compania"],
  data() {
    return {
      headers: [
        { value: "ID_EMPLEADO", text: "EMPLID", class: "text-uppercase font-weight-bold blue-grey--text text--darken-2", },
        { value: "NAME", text: "", class: "text-uppercase font-weight-bold blue-grey--text text--darken-2", },
        { value: "Status", text: "", align: "center", class: "text-uppercase font-weight-bold blue-grey--text text--darken-2", },
        { value: "actions", text: "", align: "center", class: "text-uppercase font-weight-bold blue-grey--text text--darken-2", },
      ],
      expanded: [],
      headerProps: {
        sortByText: "Ordenar por",
      },
      itemToDelete: {
        ID_EMPLEADO: 0,
        NAME: "",
        PUESTO: "",
        DEPTO: "",
        Area: "",
        EMP_STATUS: "",
        NombreMostrar: "",
        Status: 0,
      },
      itemsPerPage: 15,
      avance: 0,
      search: "",
      textoBarra: "",
      colorBarra: "",
      participantToDelete: "",
      singleExpand: true,
      confirmReloadEmpLead: false,
      loadButtonReload: false,
      loadButtonDelPart: false,
      showSuccessMessage: false,
      muestraBarra: false,
      openShowFam: false,
      confirmDelete: false,
      loadingData: false,
      confirmSendEmailClients: false,
      loadButtonSenMail: false,
      //
      headers_report_to_exportParticipantes: {
        ID_EMPLEADO: { title: "Folio" },
        NAME: { title: "Nombre" },
        PUESTO: { title: "Puesto" },
        DEPTO: { title: "Departamento" },
        Area: { title: "Area" },
        Estatus: { title: "Estatus" },
      },
      itemsExcelParticipantes: [],
      titleParticipantes: 'Participantes en Evaluación 360',

      itemsExcelFamilias: [],
      headers_report_to_exportFamilias: {
        nombreFam: { title: "Nombre familia" },
        folio: { title: "Folio" },
        empleado: { title: "Empleado" },
        departamento: { title: "Departamento" },
      },
      titleFamilias: 'Familias participantes en Evaluación 360',
      participantesEnFamilias : []
    };
  },
  created() {
   // this.participantesEnFamilias = this.participantes;
  },
  computed: {},
  mounted() {
    this.headers[1].text = this.$t("DH360.nameTextHeader");
    this.headers[2].text = this.$t("DH360.statusTextHeader");
    this.headers[3].text = this.$t("DH360.actionsTextHeader");
    this.getParticipantes360();

  },
  methods: {
    reloadRelEmpLead() {
      let params = {
        Opcion: 12,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          this.loadButtonReload = false;
          this.confirmReloadEmpLead = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.colorBarra = "success";
            this.textoBarra =
              this.$t("DH360.successfullyMessageAddParticipants") +
              response.data.resultado +
              this.$t("DH360.relations") +
              ". :)";
            this.muestraBarra = true;
          } else {
            this.colorBarra = "pink darken-3";
            this.textoBarra = this.$t("DH360.errorMessaggeDB") + ". :)";
            this.muestraBarra = true;
          }
        });
    },

    openDelete(item) {
      this.participantToDelete = item.NAME;
      this.itemToDelete = item;
      this.confirmDelete = true;
    },

    delParticipant(item) {
      let editedIndex = this.participantesEnFamilias.indexOf(item);
      this.participantesEnFamilias.splice(editedIndex, 1);

      let params = {
        Opcion: 16,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        empleado: item.ID_EMPLEADO,
        status: item.Status,
      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          this.loadButtonDelPart = false;
          this.confirmDelete = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.colorBarra = "success";
            this.textoBarra =
              this.$t("DH360.successfullyMessageDeleteParticipants") + " ";
            response.data.resultado;
            (". :)");
            this.muestraBarra = true;
          } else {
            this.colorBarra = "pink darken-3";
            this.textoBarra = this.$t("DH360.errorMessaggeDB") + ". :(";
            this.muestraBarra = true;
          }
        });
    },

    cerraVerFamilias() {
      this.openShowFam = false;
      this.loadingData = true;
      this.getParticipantes360();
    },

    getParticipantes360() {
      this.loadingData = true;
      this.participantesEnFamilias = [];

      this.participantesEnFamilias.length = 0;
      let params = {
        Opcion: 11,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
        editarRelaciones: 1,
        compania: this.compania,

      };
      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          } else if (response.data.status == "OK") {
            this.participantesEnFamilias = response.data.participantes;
            this.itemsExcelFamilias = response.data.familias;
            this.itemsExcelParticipantes = this.participantesEnFamilias.map(p => ({
              ...p,
              Estatus: p.Status === 1 ? "Asignado" : "No asignado"
            }));

            this.loadingData = false;
            this.updateProgress();
          }
        });
    },

    updateProgress() {
      let arrayAvance = this.participantesEnFamilias.filter(function condition(
        element
      ) {
        if (element.Status === 1) return true;
        return false;
      });

      this.avance =
        (arrayAvance.length / this.participantesEnFamilias.length) * 100;
    },

    sendAddClientes() {
      let params = {
        Opcion: 18,
        token: localStorage.getItem("token"),
        idProyecto: this.idProyecto,
      };

      axios
        .post(
          `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
          params
        )
        .then((response) => {
          this.loadButtonSenMail = false;
          if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
          else if (response.data.status == "OK") {
            if (response.data.respuesta == 1) {
              this.confirmSendEmailClients = false;
              this.$emit('updateEstatus', 'NOTIFICACION1')
            } else {
              alert("error")
            }
          }
        });
    },

    exportToCsvParticipantes() {
      // Exportar datos a CSV
      if (this.itemsExcelParticipantes.length > 0) {
        this.$refs.exporter.exportCSV();
      } else {
        alert('No hay datos para exportar');
      }
    },

    exportToCsvFamilias() {
      // Exportar datos a CSV
      if (this.itemsExcelFamilias.length > 0) {
        this.$refs.exporter.exportCSV();
      } else {
        alert('No hay datos para exportar');
      }
    },
  },
};
</script>

<style>
.tam-subheader {
  height: 25px;
  max-height: 75px;
}
</style>
