import AdmonBanyax from "./components/AdmonBanyax.vue"
import AdmonUsuariosBanyax from "./components/AdmonUsuariosBanyax.vue";
import BanyaxGeneral from "./components/BanyaxGeneral.vue";
export default {
  name: 'admon-banyax',
  components: {AdmonBanyax,AdmonUsuariosBanyax, BanyaxGeneral},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}


