import axios from 'axios'
import {
    deleteToken
} from '../../../../store/Funciones/funciones';
import LoadigDialog from "../../DesarrolloOrganizacional/components/loadingBar.vue"
import MuestraUsuariosPorPerfil from "./components/MuestraUsuariosPorPerfil.vue"
export default {
    name: 'admon-accesos-sistemas',
    components: {
        LoadigDialog,
        MuestraUsuariosPorPerfil
    },
    props: [],
    data() {
        return {
            sistemas: [],
            perfiles: [],
            loadingSistemas: true,
            loadingBar: false,
            dialogUsers: false,
            muestraUsuarios: false,
            perfilIdSelected: null,
            sistemaIdSelected: null,
            loadingText: "Obteniendo Información...",
            sistemaSeleccionado: "",
            perfilDecSelected: "",
            tieneAccesso: false,

        }
    },
    computed: {

    },
    mounted() {
        this.validaAccesos()
        this.getSistemas()
        //  window.document.title = "DH:URREA - Administración accesos sistemas.";
    },
    methods: {
        getSistemas() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem("token")
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/General/AministracionSistema/ws-perfiles.php`,
                    params
                )
                .then((response) => {
                    this.loadingSistemas = false;
                    if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else if (response.data.status == "OK") {
                        this.sistemas = response.data.sistemas;
                        // this.getPerfiles()
                    }
                });
        },

        validaAccesos() {
            let rol = "'AdministracionPerfiles'";
            const headers = {
                Authorization: localStorage.getItem("token"),
            };
            return new Promise((res) => {
                axios
                    .get(`${this.$store.getters.getHostNode}/api/get-roles/${rol}`, {
                        headers: headers,
                    })
                    .then((response) => {
                        if (response.data.status == "EXPRESSION") {
                            deleteToken();
                        } else if (response.data.status == "OK") {
                            res(response.data.data);
                            this.tieneAccesso = response.data.data;
                        } else {
                            res([]);
                        }
                    })
                    .finally(() => {
                        this.loadingInfo = false;
                    });
            });
        },
        getPerfiles(sistema) {
            if (this.perfiles.length > 0) {
                this.perfiles.length = 0
            }
            this.sistemaSeleccionado = sistema.Nombre
            this.sistemaIdSelected = sistema.sId
            let params = {
                Opcion: 2,
                token: localStorage.getItem("token"),
                sistema: sistema.sId
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/General/AministracionSistema/ws-perfiles.php`,
                    params
                )
                .then((response) => {
                    this.loadingBar = false;
                    if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else if (response.data.status == "OK") {
                        this.perfiles = response.data.sistemas;
                        // this.getPerfiles()
                    }
                });

        },
        openDialogUsers(perfil) {
            this.perfilDecSelected = perfil.Perfil
            this.perfilIdSelected = perfil.pId
            this.dialogUsers = true

        },
    }
}