<template>
    <v-card class="rounded-md elevation-3 mt-0 pa-1" color="#ECEFF1">

        <v-card class="rounded-xl elevation-2 mt-1 pa-4 ">
            
            <v-data-table dense :headers="headers" :search="search" :items="usuarios" no-data-text="No hay registros "
                :footer-props="{
                    'items-per-page-text': 'Elementos por página',
                }" :header-props="{ 'short-by-text': 'Ordenar por', }" loading-text="Cargando contenido..."
                :loading="loadingComisiones">

                <template v-slot:item.Fecha="{ item }">
                    {{ formatearFecha(item.Fecha) }}
                </template>

                <template v-slot:top>
                    <div style="display: flex; justify-content: space-between;">
                        <v-text-field v-model="search" outlined dense class="rounded-xl" style="max-width: 350px;"
                            prepend-inner-icon="mdi-table-search" placeholder="Buscar en tabla..."></v-text-field>

                        <vue-json-to-csv :json-data="itemsExcel" :labels="headers_report_to_export" :csv-title="title"
                            class="ma-0">
                            <v-tooltip bottom color="blue lighten-4">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn depressed class="rounded-pill ml-2" dark color="green darken-2"
                                        v-bind="attrs" v-on="on" @click="exportToCsv" outlined>
                                        <v-icon left>mdi-microsoft-excel</v-icon>
                                        Exportar
                                    </v-btn>
                                </template>
                                <label class="text-uppercase font-weight-bold primary--text">Descarga la info mostrada
                                    en la tabla</label>
                            </v-tooltip>
                        </vue-json-to-csv>
                        <div>
                            <v-tooltip bottom color="blue lighten-4">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn depressed class="rounded-pill ml-2" color="teal" outlined dark v-bind="attrs"
                                        v-on="on" @click="abrirCargaReporte()">
                                        <v-icon left> mdi-plus </v-icon>
                                        <label class="text-uppercase font-weight-bold ">Cargar Reporte</label>
                                    </v-btn>
                                </template>
                                <label class="text-uppercase font-weight-bold primary--text">CARGA REPORTE SEMANAL
                                </label>
                            </v-tooltip>
                            <v-tooltip bottom color="blue lighten-4">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn small fab depressed class="ml-2" v-bind="attrs" outlined v-on="on"
                                        @click="colseAgregaComisionistas(1)">
                                        <v-icon>mdi-reload</v-icon>
                                    </v-btn>
                                </template>
                                <label class="text-uppercase font-weight-bold primary--text">Actualizar tabla</label>
                            </v-tooltip>
                        </div>
                    </div>
                </template>
            </v-data-table>

            <v-dialog v-model="mostrarCargarUsuarios" max-width="700" scrollable persistent
                content-class="fix-border-dialog">
                <v-card class="rounded-xl">
                    <v-card-title class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold">
                        Cargar Reporte
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="mostrarCargarUsuarios = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="8" md="8">
                                <v-file-input accept=".xlsx, .xls, .csv" placeholder="Selecciona el archivo"
                                    prepend-icon="mdi-microsoft-excel" v-model="file"></v-file-input>

                                <v-btn color="primary" class="mt-2" @click="handleFileUpload" :disabled="!file">
                                    Cargar Archivo
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>


            <v-snackbar v-model="snackbar.visible" :color="snackbar.color">
                {{ snackbar.text }}
                <v-btn color="white" text @click="snackbar.visible = false">Cerrar</v-btn>
            </v-snackbar>
        </v-card>
    </v-card>
</template>

<script>
import axios from "axios";
import VueJsonToCsv from 'vue-json-to-csv'

export default {
    name: "muestra-beneficios",
    components: { VueJsonToCsv },
    data() {
        return {
            headers: [
                {
                    text: "No. Empleado",
                    value: "NumeroEmpleado",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                    sortable: false
                },
                {
                    text: "Nombre",
                    value: "Nombre",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Departamento",
                    value: "Departamento",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Puesto",
                    value: "Puesto",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Correo",
                    value: "Correo",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Gerente",
                    value: "NombreGerente",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Correo del gerente",
                    value: "CorreoGerente",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                    sortable: false
                },
                {
                    text: "Estatus",
                    value: "Estatus",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Fecha",
                    value: "Fecha",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },

            ],

            usuarios: [],
            tablaActiva: "usuarios",
            loadingComisiones: true,
            //
            search: "",
            muestraAgregaComisionista: false,
            mostrarCargarUsuarios: false,
            muestraAgregaExcel: false,
            beneficioAux: '',
            //snackbar
            snackbar: {
                visible: false,
                text: '',
                color: ''
            },
            //dialog colaborador
            colaborador: null,
            muestraComisionista: false,
            itemsColaborador: [],

            loadingColaborador: true,
            //
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            archivo: null,
            file: null,
            mostrarLoading: false,
            //Excel
            itemsExcel: [],
            title: 'Reporte de Zentric Comisionistas',
            headers_report_to_export: {
                emplid: { title: "Trabajador" },
                concepto: { title: "Concepto" },
                nomina: { title: "Nomina" },
                fechaCalculo: { title: "Fecha" },
                promedioDiario: { title: "Monto" },
                dato: { title: "Dato" },
                aplicado: { title: "Aplicado" },
            },

        };
    },
    async mounted() {
        const usuariosData = await this.getRegistrosBanyax();
        this.usuarios = usuariosData.usuarios;
        this.itemsExcel = usuariosData.itemsExcel
    },
    methods: {
        formatearFecha(fecha) {
            if (!fecha) return "";
            return fecha.split("T")[0];
        },
        abrirCargaReporte() {
        this.mostrarCargarUsuarios = true;
    },
        muestraSnackBar(color, texto) {
            this.colorBarra = color;
            this.textoBarra = texto;
            this.muestraBarra = true;
        },
        async getRegistrosBanyax() {
            this.loadingComisiones = false;
            return new Promise((resolve, reject) => {
                axios
                    .get(`${this.$store.getters.getHostNode}/api/getRegistrosBanyax`, {
                        headers: {
                            authorization: `${localStorage.getItem("token")}`,
                        },
                    })
                    .then((response) => {
                        console.log(JSON.stringify(response.data.data, null, 2));
                        if (response.data.status) {
                            resolve({
                                usuarios: response.data.data,
                                itemsExcel: response.data.dataZentric
                            });
                        } else {
                            resolve({
                                usuarios: [],
                                itemsExcel: []
                            });
                        }
                    })
                    .catch((error) => {
                        reject({
                            error: error,
                        });
                    })
                    .finally(() => {
                        this.loadingComisiones = false;
                    });
            });
        },
        async handleFileUpload() {
            if (!this.file) return;
            this.mostrarLoading = true;
            const formData = new FormData();
            formData.append('file', this.file);
            try {
                const response = await axios.put(`${this.$store.getters.getHostNode}/api/cargarReporteSemanal`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        authorization: `${localStorage.getItem("token")}`,
                    },
                });

                if (response.data.status === 'OK') {
                    this.snackbar = {
                        visible: true,
                        text: 'Archivo procesado correctamente',
                        color: 'success'
                    };
                    this.muestraAgregaExcel = false;
                } else {
                    this.snackbar = {
                        visible: true,
                        text: 'Error al procesar el archivo',
                        color: 'error'
                    };
                }
            } catch (error) {
                this.snackbar = {
                    visible: true,
                    text: 'Error de servidor: ' + error.message,
                    color: 'error'
                };
            } finally {
                this.mostrarLoading = false;
                this.file = null;
                this.usuarios.length = 0;
                const usuariosData = await this.getRegistrosBanyax();
                this.usuarios = usuariosData.usuarios;
                this.itemsExcel = usuariosData.itemsExcel;
            }
        },
        exportToCsv() {

            if (this.itemsExcel.length > 0) {
                this.$refs.exporter.exportCSV();
            } else {
                alert('No hay datos para exportar');
            }
        },
    },
};
</script>
