import axios from 'axios'
import AsignaPuntosPorPregunta from "./components/AsignaPuntosPorPregunta"
import SnackBarErrorIncidencias from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue"
import PersonaDialogSendMail from "../../../../../components/PersonaDialogSendMail.vue";
import MuestraResultados360 from "./components/MuestraResultados360";
import VueJsonToCsv from "vue-json-to-csv";
import {
    deleteToken
} from "../../../../../store/Funciones/funciones";
export default {
    name: 'mi-evaluacion-de-desempenio',
    components: {
        SnackBarErrorIncidencias,
        AsignaPuntosPorPregunta,
        PersonaDialogSendMail,
        MuestraResultados360,
        VueJsonToCsv
    },
    props: [],
    data() {
        return {
            statusProyecto: '',
            numSubACargo: '',
            enElProyecto: '',
            enElProyectoBusqueda: '',
            clientesSeleccionados: [],
            bloquear: false,
            msgNoHayEncuesta: '',

            textoBarra: '',
            colorBarra: '',
            muestraBarra: false,
            loadingInfo: true,

            tab: null,
            items: [],
            clientes: [],
            numEmpleadoClientes: [],
            NumeroClientes: ['0', '1', '2', '3', '4', '5', '6', '7'],
            nombre: '',
            miFolio: '',
            colaboradores: [],
            colaboradoresNombre: [],
            encuestasLanzadas: [],
            encuestasLanzadasInternacional: [],
            loadingColaboradores: true,
            numeroEmpleado: '',
            muestra: false,

            absolute: true,
            equipoNoColaboradores: [],
            NPequipoNoColaboradores: [],
            tipoEncuesta: '',
            idEncuesta: '',
            idEncuestaLanzada: '',
            idProyecto: '',
            familia: null,
            companiaEncuesta: '',
            modalTicket: false,
            validado: false,
            limiteClientes: '',

            //autorizacion
            headers: [{
                text: 'Empleado ',
                align: 'start',
                value: 'nombre',
                sortable: false,
            },
            {
                text: 'Cliente 1',
                value: 'nombreCliente1',
                sortable: false
            },
            {
                text: 'Cliente 2',
                value: 'nombreCliente2',
                sortable: false
            },
            {
                text: 'Cliente 3',
                value: 'nombreCliente3',
                sortable: false
            },
            {
                text: 'Cliente 4',
                value: 'nombreCliente4',
                sortable: false
            },
            {
                text: 'Cliente 5',
                value: 'nombreCliente5',
                sortable: false
            },
            {
                text: 'Cliente 6',
                value: 'nombreCliente6',
                sortable: false
            },
            {
                text: 'Cliente 7',
                value: 'nombreCliente7',
                sortable: false
            },
            {
                text: 'Estatus',
                value: 'autorizacion',
                sortable: false,
            },
            ],
            headersInternacional: [{
                text: 'Empleado ',
                align: 'start',
                value: 'nombre',
                sortable: false,
            },
            {
                text: 'Cliente 1',
                value: 'nombreCliente1',
                sortable: false
            },
            {
                text: 'Cliente 2',
                value: 'nombreCliente2',
                sortable: false
            },
            {
                text: 'Cliente 3',
                value: 'nombreCliente3',
                sortable: false
            },
            {
                text: 'Estatus',
                value: 'autorizacion',
                sortable: false,
            },
            ],
            clientesPorAutorizar: [],
            clientesPorAutorizarInternacional: [],
            colorsTema: [{
                color: 'red',
                tema: false
            },
            {
                color: 'green darken-2',
                tema: false
            }
            ],
            cargando: true,
            buscar: '',
            dialogDetalles: false,
            npClienteActual: '',
            nombreDetalles: '',
            comentarioDetalles: '',
            subordinado: '',
            fotoClienteActual: '',
            deptoClienteActual: '',
            puestoClienteActual: '',
            fotoClienteNuevo: '',
            nombreClienteNuevo: '',
            deptoClienteNuevo: '',
            puestoClienteNuevo: '',
            comentarioClienteNuevo: '',
            nuevoCliente: '',
            dialogConfirmar: false,
            //SELECCION CLIENTES
            dialogComentario: false,
            comentarioCliente: '',
            comentariosArray: [],
            comentario: '',
            dialogModoEdicion: false,
            indexComentarioEdit: null,  // Nuevo: Índice del comentario en edición
            modoEdicion: false,
            //mail
            sendMail: false,
            textMsgMail: "",
            mailSelected: "",
            correoJefeInmediato: '',
            panelExpandido: 0,

            //EVALUACION DE DESEMPEÑO
            expand: false,
            mdi1: 'mdi-chevron-down',
            openObjetivosDialog: false,
            anioProyecto: '',
            anioProyectoJS: '',
            estatusAutorizacionResultados: '',
            comentarioRechazo: '',
            nivelRango: '',
            RankingFinal: '',
            maximo: '',
            nombreColaborador: '',
            fotoColaborador: '',
            areaColaborador: '',
            deptoColaborador: '',
            puestoColaborador: '',
            openConfirmaResultados: false,
            muestraResultados: false,
            confirmarRestultados: '',
            sumPuntos: [],

            PuntosEval: '',
            PuntosObjetivos: '',
            PuntosLogros: '',
            Puntos360: '',
            PuntosMejoras: '',
            PuntosCultura: '',

            estatusAutorizacion: '',
            encFin: 0,

            cargandoPantalla: false,
            iniciarEncuesta: true,

            objetivos: [],
            hobjetivos: [
                { text: 'Objetivo ', value: 'Objetivo', align: 'start', sortable: false, },
                { text: 'VO', value: 'VO', align: 'end', sortable: false, },
                { text: 'LE', value: 'LE', align: 'end', sortable: false, },
                { text: '', value: 'valor1', align: 'end', sortable: false, },
                { text: '', value: 'valor2', align: 'end', sortable: false, },
                { text: '', value: 'valor3', align: 'end', sortable: false, },
                { text: '% pond. ', value: 'ponderacion', align: 'end', sortable: false, },
                { text: 'Desempeño', value: 'desempenio', align: 'end', sortable: false, },
                { text: 'Puntos', value: 'puntos', align: 'end', sortable: false, },
            ],

            search: '',
            resultadosSubordinados: [],
            categoriaFilterValue: "",
            loadingTabla: false,
            nameFile: "",
            categorias: [
                "Todos",
                "Comite",
                "Gerencia",
                "Responsable",
                "Administrativo",
            ],
            hResultados: [
                { value: "NumeroPersonal", align: "center", text: "NumeroPersonal" },
                { value: "Nombre", align: "center", text: "Nombre" },
                { value: "Estatus", align: "center", text: "Estatus" },
                { value: "PuntosEval", align: "center", text: "Total" },
                { value: "PuntosObjetivos", align: "center", text: "Objetivos" },
                { value: "PuntosLogros", align: "center", text: "Logros" },
                { value: "Puntos360", align: "center", text: "360" },
                { value: "PuntosMejoras", align: "center", text: "Mejoras" },
                {
                    value: "PorcentajeIncremento",
                    align: "center",
                    text: "Incremento",
                },
                { value: "Ranking", align: "center", text: "Ranking Gral." },
                { value: "RankingFinal", align: "center", text: "Ranking Categ." },
                {
                    value: "Categoria",
                    align: "center",
                    text: "Categoria",
                    filter: this.categoriaFilter,
                },
            ],

            showComments: false,
            loadingComentario: true,
            respuestaComentario: '',
            headers_report_to_export: {
                NumeroPersonal: { title: "EMPLID" },
                Nombre: { title: "Nombre" },
                PuntosEval: { title: "Total" },
                PuntosObjetivos: { title: "Objetivos" },
                PuntosLogros: { title: "Logros" },
                Puntos360: { title: "360" },
                PuntosMejoras: { title: "Mejoras" },
                PorcentajeIncremento: { title: "Porcentaje" },
                Ranking: { title: "Ranging Gral." },
                RankingFinal: { title: "Ranking categoria" },
                Categoria: { title: "Categoría" },
            },
            compania: '',
            companiaCliente: '',
            timeout: 2000,
            maxClientes: 7 ,

            loadingResSub: true,
        }
    },
    computed: {
        clientesMaximos() {
            if (this.compania === '00100') return 7;
            if (this.compania === '00700') return 1;
            if (this.compania === '01000') return 2;
            return 7; // Valor por defecto si no hay una compañía específica
          }
    },
    mounted() {
        this.$forceUpdate();
        const fechaActual = new Date();
        this.anioProyectoJS = fechaActual.getFullYear() - 1;
        this.getClientesPorAutorizar();
        this.getStatusProyecto();
        //  window.document.title = "DH:URREA - Evaluación 360.";
    },
    watch: {
        compania: {
          immediate: true,
          handler(newVal) {
            console.log('Nuevo valor de compania:', newVal);
            this.maxClientes = this.clientesMaximos;
          }
        }
      },
    methods: {
        SelectTag(item) {
            if (item.content == "Autorización") {
                this.getClientesPorAutorizar();
                console.log("autorizacion");
                this.getColaboradores();
            } else if (item.content == "Mis resultados" || item.content == "My results") {
                this.getResultadosColaborador();
            } else if (item.content == "Evaluación") {
                this.getEncuestasLanzadas();
            } else if (item.content == "Resultados subordinados") {
                this.getResultadosSubordinados();
            }
        },

        getStatusProyecto() {
            let params = {
                Opcion: 4,
                token: localStorage.getItem("token"),
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-miEvaluacionDeDesempenio.php`, params).then((response) => {

                console.log(response, 'Respuesta')
                this.statusProyecto = response.data.statusProyecto;
                this.numSubACargo = response.data.numSubACargo;
                this.enElProyecto = response.data.enElProyecto;
                this.clientesSeleccionados = response.data.clientesSeleccionados;
                this.idProyecto = response.data.idProyecto;
                this.compania = response.data.compania;
                this.estatusAutorizacion = response.data.estatusAutorizacion;
                if (this.enElProyecto == false) {
                    if (this.numSubACargo > 0) {
                        this.items = [{
                            tab: this.$t("DH360.assignedAssessments"),
                            content: 'Autorización'
                        },];
                    } else {
                        this.msgNoHayEncuesta = "Esta evaluación no está disponible para ti";
                    }
                }
                this.getColaboradores();
          
                if (this.statusProyecto == 'INICIO') {
                    this.enElProyecto = false;
                    this.msgNoHayEncuesta = "Esta evaluación no está disponible aún";
                } else if (this.statusProyecto == "sinCompañia") {
                    this.msgNoHayEncuesta = "Para comenzar con la asignación de clientes, solicita que te indiquen la compañia donde laboras, ya sea UHP, UPT o UHC";
                } else if (this.statusProyecto == 'RESPONSABLE') {
                    this.enElProyecto = false;
                    this.msgNoHayEncuesta = "Esta evaluación no está disponible aún...Pero pronto lo estará";
                } else if (this.statusProyecto == 'NOTIFICACION1' && this.enElProyecto == true) {
                    if (this.numSubACargo > 0) {
                        this.items = [{
                            tab: this.$t("DH360.customer"),
                            content: 'Selección de clientes'
                        },
                        {
                            tab: this.$t("DH360.clientsAuthorized"),
                            content: 'Autorización'
                        },
                        ];
                    } else {
                        this.items = [{
                            tab: this.$t("DH360.customer"),
                            content: 'Selección de clientes'
                        },];
                    }
                } else if (this.statusProyecto == 'PROCESO' && this.enElProyecto == true) {
                    if (this.numSubACargo > 0) {
                        this.items = [{
                            tab: this.$t("DH360.customer"),
                            content: 'Selección de clientes'
                        },
                        {
                            tab: this.$t("DH360.clientsAuthorized"),
                            content: 'Autorización'
                        },
                        {
                            tab: this.$t("DH360.assignedAssessments"),
                            content: 'Evaluación'
                        },
                        ];
                    } else {
                        this.items = [{
                            tab: this.$t("DH360.customer"),
                            content: 'Selección de clientes'
                        },
                        // { tab: this.$t("DH360.assignedAssessments"), content: 'Autorización' },
                        {
                            tab: this.$t("DH360.assignedAssessments"),
                            content: 'Evaluación'
                        },
                        ];
                    }
                } else if ((this.statusProyecto == 'TERMINADO' || this.statusProyecto == 'RANKING') && this.enElProyecto == true) {
                    console.log(this.$store.getters.getUser['Folio']);
                    if (this.numSubACargo > 0 && this.$store.getters.getUser['Folio'] != 0) {
                        this.items = [{
                            tab: this.$t("DH360.customer"),
                            content: 'Selección de clientes'
                        },
                        {
                            tab: this.$t("DH360.clientsAuthorized"),
                            content: 'Autorización'
                        },
                        {
                            tab: this.$t("DH360.assignedAssessments"),
                            content: 'Evaluación'
                        },
                        {
                            tab: this.$t("DH360.myResults"),
                            content: 'Mis resultados'
                        },
                        {
                            tab: 'Resultados subordinados',
                            content: 'Resultados subordinados'
                        },
                        ];
                    } else if (this.numSubACargo == 0 && this.$store.getters.getUser['Folio'] != 0) {
                        this.items = [{
                            tab: this.$t("DH360.customer"),
                            content: 'Selección de clientes'
                        },
                        // { tab: this.$t("DH360.assignedAssessments"), content: 'Autorización' },
                        {
                            tab: this.$t("DH360.assignedAssessments"),
                            content: 'Evaluación'
                        },
                        {
                            tab: this.$t("DH360.myResults"),
                            content: 'Mis resultados'
                        },
                        ];
                    } else if (this.numSubACargo > 0 && this.$store.getters.getUser['Folio'] == 0) {
                        this.items = [
                            {
                                tab: 'Resultados subordinados',
                                content: 'Resultados subordinados'
                            },
                        ];
                        this.SelectTag(this.items[0]);
                    }
                }
                if (this.clientesSeleccionados) {
                    this.clientes = this.clientesSeleccionados;
                    this.bloquear = true;
                }
                this.loadingInfo = false;
            });
        },
        //SELECCION DE CLIENTES
        getColaboradores() {
            if (this.colaboradores.length == 0) {
                let params = {
                    Opcion: 36,
                    token: localStorage.getItem("token"),
                    compania: this.compania
                };
                axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, params).then((response) => {
                    var data = response.data.colaboradores;
                    this.colaboradores = data;
                    this.colaboradores.map((elemento) => {
                        this.colaboradoresNombre.push('[' + elemento["EMPLID"] + '] ' + elemento["NAME"]);
                        if (elemento["EMPLID"] == response.data.miFolio) {
                            this.miFolio = response.data.miFolio;
                        }
                    });
                    this.loadingColaboradores = false;
                });
            } else {
                console.log("entra");
            }
            console.log(this.colaboradores.length);
            console.log(this.colaboradores);
        },

        getColaboradoresInternacional() {
            this.colaboradores = [];
            this.colaboradoresNombre = [];
            let params = {
                Opcion: 29,
                token: localStorage.getItem("token"),
                companiaCliente: this.companiaCliente
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, params).then((response) => {
                var data = response.data.colaboradores;
                this.colaboradores = data;
                this.colaboradores.map((elemento) => {
                    this.colaboradoresNombre.push('[' + elemento["EMPLID"] + '] ' + elemento["NAME"]);
                    if (elemento["EMPLID"] == response.data.miFolio) {
                        this.miFolio = response.data.miFolio;
                    }
                });
                this.loadingColaboradores = false;
            });
        },

        buscarNumeroEmpleado() {
            this.loadingColaboradores = true;
            if (this.compania == '00100') {
                this.limiteClientes = 7;
            } else if (this.compania == '00700') {
                this.limiteClientes = 1;
            } else if (this.compania == '01000') {
                this.limiteClientes = 2;
            }
            if (this.nombre) {
                if (this.clientes.length < this.limiteClientes) {
                    if (this.numEmpleadoClientes.includes(this.nombre) == false) {
                        let resultdo = this.colaboradores.filter((dato) => '[' + dato["EMPLID"] + '] ' + dato['NAME'] == this.nombre);
                        this.numeroEmpleado = resultdo[0]['EMPLID'];
                        if (this.numeroEmpleado != this.miFolio) {
                            //VALIDAR PARES Y JEFES 
                            let params = {
                                Opcion: 3,
                                token: localStorage.getItem("token"),
                                clientePosible: this.numeroEmpleado
                            };
                            axios.post(`${this.$store.getters.getHost}/General/ws-miEvaluacionDeDesempenio.php`, params).then((response) => {
                                this.enElProyectoBusqueda = response.data.enElProyecto;

                                if (this.enElProyectoBusqueda == false) {
                                    this.textoBarra = 'El colaborador que seleccionaste no se encuentra en el proyecto';
                                    this.colorBarra = 'error';
                                    this.muestraBarra = true;
                                }
                                if (response.data.status == "Ok" && this.enElProyectoBusqueda == true) {
                                    this.dialogComentario = true;
                                } else if (response.data.status == "pares") {
                                    this.textoBarra = this.$t("DH360.txtNoSelectPairs");
                                    this.colorBarra = 'error';
                                    this.muestraBarra = true;
                                } else if (response.data.status == "JefeDirecto") {
                                    this.textoBarra = this.$t("DH360.txtNoSelectBoss");
                                    this.colorBarra = 'error';
                                    this.muestraBarra = true;
                                } else if (response.data.status == "Mayor") {
                                    this.textoBarra = this.$t("DH360.txtSeveralTimes");
                                    this.colorBarra = 'error';
                                    this.muestraBarra = true;
                                } else if (response.data.status == "subordinado") {
                                    this.textoBarra = this.$t("DH360.txtNoSubLikeCustomer");
                                    this.colorBarra = 'error';
                                    this.muestraBarra = true;
                                }
                            });
                        } else {
                            this.textoBarra = this.$t("DH360.txtCustomerYourself");
                            this.colorBarra = 'error';
                            this.muestraBarra = true;
                        }
                    } else {
                        this.textoBarra = this.$t("DH360.txtCustomerHasSelected");
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    }
                } else {
                    this.textoBarra = this.$t("DH360.txtCustomerHasSavedShort") + this.limiteClientes + ' ' + this.$t("DH360.customer");
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }
            }
            this.loadingColaboradores = false;
        },
        editarComentario(index) {
            this.comentarioCliente = this.comentariosArray[index]; // Cargar comentario existente
            this.indexComentarioEdit = index;  // Guardar el índice para la edición
            this.modoEdicion = true;  // Cambiar a modo edición
            this.dialogComentario = true; // Mostrar el diálogo
        },
        guardarComentario() {
            if (this.modoEdicion) {
                // Si está en modo edición, actualiza el comentario existente
                this.comentariosArray[this.indexComentarioEdit] = this.comentarioCliente;
                this.modoEdicion = false;  // Restablece el modo
                this.comentarioCliente = '';
                this.indexComentarioEdit = null;
            } else {
                // Si es un nuevo comentario, lo agrega normalmente
                let resultdo = this.colaboradores.filter((dato) => '[' + dato["EMPLID"] + '] ' + dato['NAME'] == this.nombre);
                this.clientes.push({
                    'numEmpleado': this.numeroEmpleado,
                    'Nombre': resultdo[0]["NAME"]
                });
                this.numEmpleadoClientes.push('[' + this.numeroEmpleado + '] ' + resultdo[0]["NAME"]);
                this.comentariosArray.push(this.comentarioCliente);
            }
        
            this.dialogComentario = false;
            this.comentarioCliente = '';
            this.textoBarra = "Comentario guardado exitosamente";
            this.colorBarra = 'success';
            this.muestraBarra = true;
        },
        splitIntegrante(tag) {
            let puntero = '';
            this.clientes.forEach(function (element, index) {
                if (element.numEmpleado == tag) {
                    puntero = index;
                }
            });
            this.clientes.splice(puntero, 1);
            this.numEmpleadoClientes.splice(puntero, 1);
            this.comentariosArray.splice(puntero, 1);
        },

        guardar() {
            let params = {
                Opcion: 1,
                token: localStorage.getItem("token"),
                clientes: this.clientes,
                comentarios: this.comentariosArray,
                compania: this.compania
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-miEvaluacionDeDesempenio.php`, params).then((response) => {
                if (response.data.status == "OK") {
                    this.textoBarra = this.$t("DH360.successfullyMessageAddParticipants");
                    this.colorBarra = 'success';
                    this.muestraBarra = true;
                    this.bloquear = true;
                    this.clean();
                    this.getStatusProyecto();
                } else if (response.data.status == "error") {
                    this.textoBarra = this.$t("DH360.errorMessaggeDB");
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                } else if (response.data.status == "RegistroExistente") {
                    this.textoBarra = this.$t("DH360.txtCustomerHasSaved");
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }
            });
        },

        clean() {
            // this.clientes=[];
            // this.numEmpleadoClientes=[];
            // this.comentariosArray=[];
            this.nombre = '';
            this.numeroEmpleado = '';
            this.comentarioCliente = '';
        },
        //


        // Autorizacion de clientes
        getClientesPorAutorizar() {
            this.clientesPorAutorizar = [];
            this.clientesPorAutorizarInternacional = [];
            this.comentarioClienteNuevo = '';
            this.cargando = true;
            this.nuevoCliente = '';
            let params = {
                Opcion: 5,
                token: localStorage.getItem("token"),
                compania: this.$store.getters.getUser['compania']
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-miEvaluacionDeDesempenio.php`, params).then((response) => {
                var data = response.data.data;
                if (response.data.data) {
                    this.clientesPorAutorizar = data;
                }
                if (response.data.dataInternacional) {
                    this.clientesPorAutorizarInternacional = response.data.dataInternacional;
                }
                this.cargando = false;
            });
        },

        getPerfilColaborador(item) {
            let NPcliente1 = item.NPcliente1;
            let params = {
                Opcion: 19,
                token: localStorage.getItem("token"),
                NPcliente1: NPcliente1,
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-general.php`, params).then((response) => {

                if (response.data.status == 'OK') {
                    this.companiaCliente = response.data.data["compania"];
                    console.log(this.companiaCliente);
                    if (this.companiaCliente != '00100') {
                        this.getColaboradoresInternacional(this.companiaCliente);
                        console.log("aqui toy");
                    }
                    this.getComentarios(item);
                    if (response.data.data['foto']) {
                        this.fotoClienteActual = "https://apidh.urrea.com/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/" + response.data.data['foto'];
                    } else {
                        this.fotoClienteActual = "https://community.f5.com/legacyfs/online/avatars/0051T000008uOPOQA2.png";
                    }
                    if (response.data.data['puesto']) {
                        this.puestoClienteActual = response.data.data['puesto'];
                    } else {
                        this.puestoClienteActual = "Puesto no encontrado";
                    }
                    if (response.data.data['depto']) {
                        this.deptoClienteActual = response.data.data['depto'];
                    } else {
                        this.deptoClienteActual = "Departamento no encontrado";
                    }
                }
            });
        },

        getComentarios(item) {
            let NPcliente1 = item.NPcliente1;
            let params = {
                Opcion: 8,
                token: localStorage.getItem("token"),
                cliente: NPcliente1,
                emplid: this.subordinado
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-miEvaluacionDeDesempenio.php`, params).then((response) => {
                if (response.data.status == 'Ok') {
                    this.comentarioDetalles = response.data.comentario;
                    if (response.data.comentario == null) {
                        this.comentarioDetalles = "No hay comentarios";
                    }
                }
            });
        },

        openDdetalles(item) {
            this.dialogDetalles = true;
            this.nombreDetalles = item.nombre;
            this.npClienteActual = item.NPcliente1;
            this.posicionCliente = item.numCliente;
            this.subordinado = item.subordinado;
            this.getPerfilColaborador({
                NPcliente1: item.NPcliente1
            });
        },

        validarJefePares() {
            this.validado = false;
            if (this.nuevoCliente) {
                let resultdo = this.colaboradores.filter((dato) => '[' + dato["EMPLID"] + '] ' + dato['NAME'] == this.nuevoCliente);
                let params = {
                    Opcion: 3,
                    token: localStorage.getItem("token"),
                    perfil: 'Responsable',
                    emplidSub: this.subordinado,
                    clientePosible: resultdo[0]['EMPLID']
                };
                axios.post(`${this.$store.getters.getHost}/General/ws-miEvaluacionDeDesempenio.php`, params).then((response) => {
                    if (response.data.nuevoCliente) {
                        if (response.data.nuevoCliente['foto']) {
                            this.fotoClienteNuevo = "https://apidh.urrea.com/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/" + response.data.nuevoCliente['foto'];
                        } else {
                            this.fotoClienteNuevo = "https://community.f5.com/legacyfs/online/avatars/0051T000008uOPOQA2.png";
                        }
                        this.puestoClienteNuevo = response.data.nuevoCliente['puesto'];
                        this.deptoClienteNuevo = response.data.nuevoCliente['depto'];
                        this.nombreClienteNuevo = response.data.nuevoCliente['nombre'];
                    }
                    if (response.data.status == "Ok") {
                        this.validado = true;
                    }

                    if (response.data.status == "pares") {
                        this.textoBarra = this.$t("DH360.txtNoSelectPairs");
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    } else if (response.data.status == "JefeDirecto") {
                        this.textoBarra = this.$t("DH360.txtNoSelectBoss");
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    } else if (response.data.status == "subordinado") {
                        this.textoBarra = this.$t("DH360.txtNoSubLikeCustomer");
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    } else if (response.data.status == "Mayor") {
                        this.textoBarra = this.$t("DH360.txtSeveralTimes");
                        this.colorBarra = 'error';
                        this.muestraBarra = true;
                    }
                });
            } else {
                this.fotoClienteNuevo = '';
                this.puestoClienteNuevo = '';
                this.deptoClienteNuevo = '';
                this.nombreClienteNuevo = '';
            }
        },

        guardarNuevoCliente() {
            let resultdo = this.colaboradores.filter((dato) => '[' + dato["EMPLID"] + '] ' + dato['NAME'] == this.nuevoCliente);
            let params = {
                Opcion: 6,
                token: localStorage.getItem("token"),
                nuevoCliente: resultdo[0]['EMPLID'],
                posicionCliente: this.posicionCliente,
                subordinado: this.subordinado,
                comentario: this.comentarioClienteNuevo,
                actualCliente: this.npClienteActual,
                compania: this.companiaCliente
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-miEvaluacionDeDesempenio.php`, params).then((response) => {
                if (response.data.status == "Ok") {
                    this.dialogDetalles = false;
                    this.clientesPorAutorizar = [];
                    this.comentarioClienteNuevo = '';
                    this.cargando = true;
                    this.nuevoCliente = '';
                    this.textoBarra = 'Cliente modificado con exito.';
                    this.colorBarra = 'success';
                    this.muestraBarra = true;
                    this.getClientesPorAutorizar();
                } else if (response.data.status == "error") {
                    this.textoBarra = 'Algo sucedió.';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                } else if (response.data.status == "Mayor") {
                    this.textoBarra = 'Lo siento,este colaborador ya fue seleccionado demasiado';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }
            });
        },

        openAutorizarClientes(item) {
            this.subordinado = item.subordinado;
            this.getPerfilColaborador({ NPcliente1: item.subordinado });
            this.dialogConfirmar = true;
        },

        autorizarClientes() {
            let params = {
                Opcion: 7,
                token: localStorage.getItem("token"),
                subordinado: this.subordinado,
                compania: this.companiaCliente
            }
            axios.post(`${this.$store.getters.getHost}/General/ws-miEvaluacionDeDesempenio.php`, params).then((response) => {
                if (response.data.status == "Ok") {
                    this.clientesPorAutorizar = [];
                    this.cargando = true;
                    this.nuevoCliente = '';
                    this.textoBarra = 'Autorización realizada correctamente.';
                    this.colorBarra = 'success';
                    this.muestraBarra = true;
                    this.dialogConfirmar = false;
                    this.getClientesPorAutorizar();
                }
            });
        },
        //
        //Evaluaciones lanzadas
        addNoEquipo(item) { //seleccionar quienes vamos a responder la encuesta por desconocimiento del colaborador
            if (this.NPequipoNoColaboradores.length > 0) {
                if (this.NPequipoNoColaboradores.includes(item.NPEvaluado) == false) {
                    this.equipoNoColaboradores.push(item);
                    this.NPequipoNoColaboradores.push(item.NPEvaluado);
                    this.textoBarra = 'Encuesta seleccionada a encuestas por rechazar';
                    this.colorBarra = 'success';
                    this.muestraBarra = true;
                }
            } else {
                this.equipoNoColaboradores.push(item);
                this.NPequipoNoColaboradores.push(item.NPEvaluado);
                this.textoBarra = 'Encuesta seleccionada a encuestas por rechazar';
                this.colorBarra = 'success';
                this.muestraBarra = true;
            }

        },

        splitNoEquipo(tag) {
            let puntero = '';
            this.equipoNoColaboradores.forEach(function (element, index) {
                if (element.NPEvaluado == tag) {
                    puntero = index;
                }
            });
            this.equipoNoColaboradores.splice(puntero, 1);
            this.NPequipoNoColaboradores.splice(puntero, 1);
            this.textoBarra = 'Encuesta removida de encuestas por rechazar';
            this.colorBarra = 'success';
            this.muestraBarra = true;
        },

        getEncuestasLanzadas() {
            this.cargandoPantalla = true;
            // this.iniciarEncuesta = true;
            let params = {
                Opcion: 2,
                token: localStorage.getItem("token"),
                compania: this.compania
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-miEvaluacionDeDesempenio.php`, params).then((response) => {
                if (response.data.status == "OK") {
                    if (response.data.data) {
                        this.encuestasLanzadas = response.data.data;
                    } else {
                        this.textoBarra = 'En estos momentos no cuentas con encuestas para responder';
                        this.colorBarra = 'primary';
                        this.muestraBarra = true;
                    }

                    if (response.data.dataInternacional) {
                        this.encuestasLanzadasInternacional = response.data.dataInternacional;
                        console.log("hay");
                    }

                } else if (response.data.status == "error") {
                    this.textoBarra = 'Algo ocurrió mal ';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }
                this.cargandoPantalla = false;
                this.encFin = 0;
                this.correoJefeInmediato = response.data.correoJI;
            });
        },

        openSendMail(mailTo) {
            let colaboradorestxt = '';
            let msg = '';
            this.equipoNoColaboradores.map((elemento) => {
                msg += colaboradorestxt.concat("\n", elemento['NombreEvaluado']);

            });
            let temp = "A continuación se presenta una lista de los colaboradores que su subordinado solicita " +
                "el cambio ya que no los reconoce como clientes.\n" +
                msg +
                " \nMensaje del colaborador: ";
            this.textMsgMail = temp.concat(" ", this.comentario);
            this.mailSelected = mailTo;
            this.sendMail = true;
        },

        closeSendMail(type) {
            switch (type) {
                case -1:
                    {
                        this.sendMail = false;
                        break;
                    }
                case 0:
                    {
                        this.sendMail = false;
                        this.colorBarra = "error";
                        this.textoBarra = this.$t("DH360.errorMessaggeDB");
                        this.muestraBarra = true;
                        break;
                    }
                case 1:
                    {
                        this.sendMail = false;
                        this.colorBarra = "success";
                        this.textoBarra = "enviado";
                        this.muestraBarra = true;
                        break;
                    }
            }
        },

        getParticipantes(item) {
            this.modalTicket = true;
            this.tipoEncuesta = item.tipoEncuesta[0].TipoEncuesta;
            this.idEncuesta = item.tipoEncuesta[0].idEncuesta;
            this.idEncuestaLanzada = item.tipoEncuesta[0].idEncuestaLanzada;
            this.idProyecto = item.tipoEncuesta[0].idProyecto;
            this.familia = item.tipoEncuesta[0].Familia;
            this.companiaEncuesta = item.tipoEncuesta[0].compania;
            console.log(item);
            console.log(this.companiaEncuesta);
        },
        //

        // EVALUACION DE DESEMPEÑO
        mostrar(item) {
            if (item == 'card') {
                if (this.expand == false) {
                    this.expand = true;
                    this.mdi1 = "mdi-chevron-up";
                } else {
                    this.expand = false;
                    this.mdi1 = "mdi-chevron-down";
                }
            }
        },

        getResultadosColaborador() {
            let params = {
                Opcion: 9,
                token: localStorage.getItem("token"),
                compania: this.compania
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-miEvaluacionDeDesempenio.php`, params).then((response) => {
                if (response.data.status == "EXPSESSION") {
                    deleteToken();
                } else if (response.data.status == "Ok") {
                    if (response.data.data) {
                        this.PuntosEval = response.data.data[0]["PuntosEval"];
                        this.PuntosObjetivos = response.data.data[0]["PuntosObjetivos"];
                        this.PuntosLogros = response.data.data[0]["PuntosLogros"];
                        this.Puntos360 = response.data.data[0]["Puntos360"];
                        this.PuntosMejoras = response.data.data[0]["PuntosMejoras"];
                        this.PuntosCultura = response.data.data[0]["PuntosCultura"];
                        this.estatusAutorizacionResultados = response.data.data[0]["estatusAutRes"];
                        this.nivelRango = response.data.nivel;
                        this.RankingFinal = response.data.rankingFinal;
                        this.maximo = response.data.maximo;
                        this.nombreColaborador = response.data.nombre;
                        this.fotoColaborador = response.data.foto["foto"];
                        this.areaColaborador = response.data.foto["Area"];
                        this.deptoColaborador = response.data.foto["depto"];
                        this.puestoColaborador = response.data.foto["puesto"];
                    } else {
                        this.textoBarra = 'Aun no se encuentran datos registrados';
                        this.colorBarra = 'primary';
                        this.muestraBarra = true;
                    }
                } else if (response.data.status == "error") {
                    this.textoBarra = 'Algo ocurrió mal ';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }
                this.correoJefeInmediato = response.data.correoJI;
            });
        },
        //

        refresh() {
            if (this.encFin == 1) {
                this.getEncuestasLanzadas();
                this.encFin = 0;
            }
        },

        openObjetivos() {
            this.cargandoPantalla = true;
            let params = {
                Opcion: 10,
                token: localStorage.getItem("token")
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-miEvaluacionDeDesempenio.php`, params).then((response) => {
                if (response.data.status == "EXPSESSION") {
                    deleteToken();
                } else if (response.data.status == "Ok") {
                    this.openObjetivosDialog = true;
                    this.objetivos = response.data.data;
                    this.anioProyecto = response.data.anioProyecto;
                    this.sumPuntos = response.data.SumPuntos;

                } else if (response.data.status == "error") {
                    this.textoBarra = 'Algo ocurrió mal ';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }

                this.cargandoPantalla = false;
            });
        },
        guardarEstatusResultados() {
            let params = {
                Opcion: 11,
                estatus: this.confirmarRestultados,
                comentarioRechazo: this.comentarioRechazo,
                nombre: this.nombreColaborador,
                area: this.areaColaborador,
                depto: this.deptoColaborador,
                puesto: this.puestoColaborador,
                compania: this.compania,
                token: localStorage.getItem("token")
            };
            axios.post(`${this.$store.getters.getHost}/General/ws-miEvaluacionDeDesempenio.php`, params).then((response) => {
                if (response.data.status == "EXPSESSION") {
                    deleteToken();
                } else if (response.data.status == "Ok") {
                    this.openConfirmaResultados = false;
                    this.confirmarRestultados = '';
                    this.comentarioRechazo = '';
                    this.getResultadosColaborador();

                    this.textoBarra = 'Estatus guardado correctamente ';
                    this.colorBarra = 'success';
                    this.muestraBarra = true;

                } else if (response.data.status == "error") {
                    this.textoBarra = 'Algo ocurrió mal ';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }
            });
        },

        getResultadosSubordinados() {
            let params = {
                Opcion: 46,
                idProyecto: this.idProyecto,
                token: localStorage.getItem("token"),
                compania: this.compania
            };
            // console.log(params);
            axios.post(`${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`, params).then((response) => {
                if (response.data.status == "EXPSESSION") {
                    deleteToken();
                } else if (response.data.status == "OK") {
                    this.resultadosSubordinados = response.data.respuesta;
                } else if (response.data.status == "ERROR") {
                    this.textoBarra = 'Algo ocurrió mal ';
                    this.colorBarra = 'error';
                    this.muestraBarra = true;
                }
                this.loadingResSub = false;
                // console.log(response.data.respuesta);
            });
        },
        categoriaFilter(value) {
            if (!this.categoriaFilterValue) {
                return true;
            }
            if (this.categoriaFilterValue == "Todos") {
                return true;
            }
            return value
                .toLowerCase()
                .includes(this.categoriaFilterValue.toLowerCase());
        },
        fixName() {
            let numero_aleatorio =
                Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
            this.nameFile = "ranking_" + numero_aleatorio.toString();
            this.loadingTabla = false;
        },

        getComentario(empleado) {
            let params = {
                Opcion: 47,
                token: localStorage.getItem("token"),
                empleado: empleado.NumeroPersonal,
                idProyecto: this.idProyecto,
            };
            // console.log(params);
            axios
                .post(
                    `${this.$store.getters.getHost}/RelacionesLaborales/AdmonEvaluacionesDesempenio/ws-admon-360.php`,
                    params
                )
                .then((response) => {
                    this.loadingComentario = false;
                    // console.log(response.data);
                    if (response.data.status == "OK") {
                        this.respuestaComentario = response.data.respuesta;
                    } else if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else {
                        this.colorBarra = "error";
                        this.textoBarra = "ocurrió un error";
                        this.muestraBarra = true;
                    }
                })
                .catch(function (e) {
                    alert("Ocurrió un error: " + e);
                });
        },

        closeComentarios() {
            this.respuestaComentario = {
                ID: 0,
                estatus: 0,
                fechaRegistro: {
                    date: "",
                    timezone_type: 0,
                    timezone: "0",
                },
                comentario: "",
            };
            this.showComments = false;
        },
    }
}