<template>
  <v-card class="rounded-xl elevation-0 pa-3">
    <v-row align="center">
      <v-col cols="12" sm="6" md="4">
        <v-menu
          v-model="menu1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="formattedDateRange"
              label="Fecha inicial - Fecha final"
              readonly
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="dateRange"
            :max="maxDate"
            range
            no-title
            scrollable
            @change="updateFormattedDateRange"
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-autocomplete
          dense
          clearable
          v-model="decolaborador"
          :loading="loadingcolaboradores"
          :items="colaboradoresNombre"
          label="De"
          item-text="nombre"
          item-value="emplid"
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-autocomplete
          dense
          clearable
          v-model="paracolaborador"
          :loading="loadingcolaboradores"
          :items="colaboradoresNombrevalorado"
          label="Para"
          item-text="nombre"
          item-value="emplid"
        ></v-autocomplete>
      </v-col>
      <!-- Selects con opciones obtenidas de una consulta axios -->
      <v-col cols="12" sm="6" md="4">
        <v-select
          dense
          clearable
          v-model="selectedarea"
          :items="areas"
          label="Área"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-select
          dense
          clearable
          v-model="selectedprincipio"
          :items="principios"
          label="Principio"
        ></v-select>
      </v-col>
      <v-col cols="12" sm="3" md="2">
        <v-btn
          @click="getAsignacion()"
          dark
          small
          class="mb-1 font-weight-bold rounded-lg"
          color="primary"
        >
          <v-icon left small>mdi-magnify</v-icon>
          Consultar</v-btn
        >
      </v-col>
      <v-col cols="12" sm="3" md="2" v-show="!habilitarexcel">
        <vue-json-to-csv
          :json-data="asignaciones"
          :labels="headers_report_to_export"
          :csv-title="title"
        >
          <v-btn
            color="green darken-2"
            ref="exporter"
            dark
            small
            class="mb-1 font-weight-bold rounded-lg"
            @click="exportToCsv()"
          >
            <v-icon left small>mdi-microsoft-excel</v-icon>
            Exportar
          </v-btn>
        </vue-json-to-csv>
      </v-col>
    </v-row>
    <v-data-table
      dense
      :headers="headers"
      :search="search"
      :items="asignaciones"
      no-data-text="Ningún elemento coincide con el criterio de búsqueda"
      :footer-props="{
        'items-per-page-text': 'Elementos por página',
      }"
      :header-props="{ 'short-by-text': 'Ordenar por' }"
      loading-text="Cargando contenido..."
      :loading="loadingAsignacion"
    >
      <template v-slot:top>
        <div style="display: flex; justify-content: space-between">
          <!-- <v-text-field v-model="search" outlined dense class="rounded-xl" style="max-width: 350px;"
                        prepend-inner-icon="mdi-table-search" placeholder="Buscar en tabla..."></v-text-field>
                        
                    <div>
                        <v-tooltip bottom color="blue lighten-4">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn small fab depressed class="ml-2" v-bind="attrs" v-on="on"
                                    @click="getAsignacion()">
                                    <v-icon>mdi-reload</v-icon>
                                </v-btn>
                            </template>
    <label class="text-uppercase font-weight-bold primary--text">Actualizar tabla</label>
    </v-tooltip>
    </div>-->
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
          color="cyan darken-2"
          small
          @click="verComentario(item.Comentarios)"
        >
          mdi-comment-account-outline
        </v-icon>
      </template>
    </v-data-table>
    <v-dialog
      v-model="muestraComentario"
      content-class="fix-border-dialog"
      persistent
      max-width="290"
    >
      <v-card class="rounded-xl">
        <v-card-title
          class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold"
        >
          Comentario de asignación
          <v-spacer></v-spacer>
          <v-btn icon dark @click="muestraComentario = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          {{ comentario }}
        </v-card-text>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import axios from "axios";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  name: "muestra-reporte",
  components: { VueJsonToCsv },

  data() {
    return {
      comentario: "",
      muestraComentario: false,
      title: "Asiganciones de principios",
      headers: [
        {
          text: "# De ",
          value: "NoPersonal",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "De",
          value: "NombrePersonal",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "# Valorado",
          value: "NoPersonalValorado",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Valorado",
          value: "NombrePersonalValorado",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Área",
          value: "Area",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Registro",
          value: "FechaAlta",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Principio",
          value: "Valor",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
        {
          text: "Acciones",
          value: "actions",
          sortable: false,
          align: "center",
          class:
            "text-uppercase font-weight-bold blue-grey--text text--darken-2",
        },
      ],
      headers_report_to_export: {
        NoPersonal: { title: "# De" },
        NombrePersonal: { title: "De" },
        NoPersonalValorado: { title: "# Valorado" },
        NombrePersonalValorado: { title: "Valorado" },
        Area: { title: "Área" },
        FechaAlta: { title: "Registro" },
        Valor: { title: "Principio" },
        Comentarios: { title: "Comentario" },
      },
      asignaciones: [],
      beneficioAux: {},
      loadingAsignacion: true,
      loadingcolaboradores: true,
      muestraAgregaBenficio: false,
      muestraBarra: false,
      textoBarra: "",
      colorBarra: "",
      search: "",
      menu1: false,
      colaboradoresNombre: [],
      colaboradoresNombrevalorado: [],
      principios: [],
      areas: [
        "Administración y Finanzas",
        "Desarrollo Humano",
        "GUDH",
        "Innovación y competitividad",
        "Logistica",
        "Mercadotecnia",
        "Operaciones",
        "Ventas Internacional",
        "Ventas nacionales",
      ],
      decolaborador: "",
      paracolaborador: "",
      selectedarea: "",
      selectedprincipio: "",
      habilitarexcel: true,
      formattedDateRange: "",
      //fecha
      dateRange: [],
      maxDate: "",
      minDate: "",
    };
  },
  async mounted() {
    // const today = new Date();
    // const minDate = new Date();
    // minDate.setMonth(minDate.getMonth() - 1);

    this.minDate = this.getLastMonthDate();
    this.maxDate = this.getTodayDate();
    this.dateRange = [this.minDate, this.maxDate];
    this.formattedDateRange = `${this.minDate} - ${this.maxDate}`;
    // this.maxDate = today
    //   .toISOString()
    //   .substr(0, 10)
    //   .split("-")
    //   .reverse()
    //   .join("-");
    // this.minDate = minDate
    //   .toISOString()
    //   .substr(0, 10)
    //   .split("-")
    //   .reverse()
    //   .join("-");
    // console.log(this.maxDate);
    // console.log(this.minDate);
    this.getPrincipios();
    this.getAsignacion();
    this.getColaboradores();
  },
  methods: {
    getTodayDate() {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`; // Formato: YYYY-MM-DD
    },

    getLastMonthDate() {
      const today = new Date();
      today.setMonth(today.getMonth() - 1); // Resta un mes
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`; // Formato: YYYY-MM-DD
    },

    formatDateToDDMMYYYY(date) {
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`; // Formato: dd-mm-YYYY
    },

    getFormattedDateRange() {
      if (this.dateRange.length === 2) {
        return this.dateRange.map(this.formatDateToDDMMYYYY);
      }
      return [];
    },

    updateFormattedDateRange() {
      console.log(this.dateRange.length);
      if (this.dateRange.length === 2) {
        const [start, end] = this.dateRange;
        this.formattedDateRange = `${start} - ${end}`;
      }
    },

    getAsignacion() {
      let formattedRange = this.getFormattedDateRange();
      console.log("Enviando al backend:", formattedRange);
      this.loadingAsignacion = true;
      let params = {
        Opcion: 8,
        token: localStorage.getItem("token"),
        fechainicial: formattedRange[0],
        fechafinal: formattedRange[1],
        decolaborador: this.decolaborador,
        paracolaborador: this.paracolaborador,
        selectedarea: this.selectedarea,
        selectedprincipio: this.selectedprincipio,
      };
      this.habilitarexcel = true;
      axios
        .post(`${this.$store.getters.getHost}/Valores/ws-valores.php`, params)
        .then((response) => {
          if (response.data.status == "OK") {
            this.asignaciones = response.data.asignaciones;
            this.habilitarexcel = false;
          } else {
            this.asignaciones = [];
            this.habilitarexcel = true;
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loadingAsignacion = false;
        });
    },
    getColaboradores() {
      let params = {
        Opcion: 7,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/Valores/ws-valores.php`, params)
        .then((response) => {
          this.colaboradores = response.data.colaboradores;
          this.colaboradores.map((elemento) => {
            this.colaboradoresNombre.push({
              nombre: elemento["NAME"],
              emplid: elemento["EMPLID"],
            });
            this.colaboradoresNombrevalorado.push({
              nombre: elemento["NAME"],
              emplid: elemento["EMPLID"],
            });
          });
          this.loadingcolaboradores = false;
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    getPrincipios() {
      let params = {
        Opcion: 1,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/Valores/ws-valores.php`, params)
        .then((response) => {
          this.colaboradores = response.data.datosValores;
          this.colaboradores.map((elemento) => {
            this.principios.push(elemento["titulo"]);
          });
          this.loadingColaboradores = false;
        })
        .catch(function (e) {
          let result = e.toString().includes("401");
          if (result) {
            window.location.reload();
          } else {
            alert(e);
          }
        });
    },
    exportToCsv() {
      // Exportar datos a CSV
      if (this.asignaciones.length > 0) {
        this.$refs.exporter.exportCSV();
      } else {
        alert("No hay datos para exportar");
      }
    },
    verComentario(comentarios) {
      this.muestraComentario = true;
      console.log(comentarios);
      this.comentario = comentarios;
    },
  },
};
</script>