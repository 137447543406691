<template>
  <div>
    <v-dialog v-model="openRegistro" fullscreen>
      <v-card class="rounded-0">
        <v-toolbar flat class="" color="primary" dark>
          <label class="overline font-weight-bold">Registro de descanso laborado</label>
          <v-spacer></v-spacer>
          <v-icon @click="$emit('cierraRegistro', false)">mdi-close</v-icon>
        </v-toolbar>
        <v-card-text class="pt-3">
          <v-row v-if="loadingData" class="fill-height mt-5" align-content="center" justify="center">
            <v-col cols="6" class="center-item-in-div">
              <v-progress-circular color="primary" indeterminate :size="150" :width="10">
                Cargando...
              </v-progress-circular>
            </v-col>
          </v-row>
          <div v-else>
            <v-radio-group v-model="areaSelected" mandatory row class="font-weight-bold" @change="cambiaArea">
              <div v-for="(area, index) in areas" :key="index">
                <v-radio :label="area.areaDesc" :value="area.area"></v-radio>
              </div>
            </v-radio-group>
            <v-radio-group v-model="deptSelected" mandatory row dense class="mt-0 pt-0"
              @change="updateTablaColaboradores">
              <div v-for="(depto, index) in areas[indexArea].depsPorArea" :key="index">
                <v-radio :label="depto.DESCR" :value="depto.id_departamento_zn"></v-radio>
              </div>
            </v-radio-group>
            <v-divider class="mt-4 mb-3 mx-15"></v-divider>
            <div class="center-item-in-div-column">
              <label class="overline blue-grey--text text--darken-1 font-weight-bold">
                SELECCIONA UNA FECHA PARA OBTENER A LOS COLABORADORES
              </label>
              <label class="caption blue-grey--text text--darken-1 font-italic">
                "La fecha seleccionada será la misma para todos los
                colaboradores"
              </label>
              <div>
                <label class="overline">fecha</label>
                <v-text-field v-model="date" placeholder="Selecciona la fecha"
                  :style="isSmallScreen ? 'width: 100%;' : 'width: 450px;'" class="rounded-lg mt-0"
                  prepend-inner-icon="mdi-calendar" readonly dense outlined @click="dialogFechas = true"></v-text-field>
              </div>
            </div>
            <v-row dense>
              <v-col :cols="isSmallScreen ? 12 : 6">
                <v-card class="rounded-xl elevation-0" style="border: 0.7px solid #1565C0">
                  <v-card-title class="primary text-center" :class="itemsPerPage == 5 ? 'center-item-in-div-column' : ''
                    ">
                    <label class="white--text text-center text-uppercase subtitle-1">
                      departamento {{ deptSelected }}
                    </label>
                    <v-spacer v-show="itemsPerPage == 10"></v-spacer>
                    <div class="center-item-in-div-column">
                      <label class="overline caption white--text" style="line-height: 1.2;">
                        Costo de planilla
                      </label>
                      <label class="font-weight-bold subtitle-1 white--text" style="line-height: 1.4;">
                        {{ formatMXN(getSumatoria) }}
                      </label>
                    </div>
                    <v-btn v-show="colaboradores.length > 0" class="ml-4 rounded-lg" depressed color="blue lighten-3"
                      @click="seleccionaATodos">
                      <v-icon left> mdi-plus </v-icon>
                      <div>
                        seleccionar <br> a todos
                      </div>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="pt-3">
                    <v-data-table v-model="colaboradoresSeleccionados" :search="searchColaborador" :footer-props="{
                      'items-per-page-text': 'Elementos por página',
                      pageText: '{0}-{1} de {2}',
                    }" no-data-text="No hay registros" loading-text="Cargando información..." :no-results-text="'No se encontró ningun resultado que coincida con ' +
                      searchColaborador
                      " :header-props="headerProps" :mobile-breakpoint="0" dense :single-select="false" show-select
                      item-key="EMP_KEYEMP" :items="colaboradores" :headers="headers"
                      :loading="loadingTablaColaboradores" :items-per-page="itemsPerPage"
                      @item-selected="handleSelectionUpdate" @input="handleSelect">
                      <template v-slot:top>
                        <v-text-field v-model="searchColaborador" dense outlined class="rounded-pill" clareable
                          prepend-inner-icon="mdi-magnify" placeholder="Buscar..."></v-text-field>
                        <v-spacer></v-spacer>
                      </template>
                      <template v-slot:item.EMP_NOMEMP="{ item }">
                        <v-tooltip v-if="item.dias_desde_ultimo_ausentismo <= 15" top color="error">
                          <template v-slot:activator="{ on, attrs }">
                            <v-chip color="error" dark v-bind="attrs" v-on="on" small>
                              {{ item.EMP_NOMEMP }}
                            </v-chip>
                          </template>
                          <span>Faltas en los últimos 15 días</span>
                        </v-tooltip>
                        <div v-else>
                          {{ item.EMP_NOMEMP }}
                        </div>
                      </template>
                      <template v-slot:item.TOTAL="{ item }">
                        {{ formatMXN(item.TOTAL) }}
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col :cols="isSmallScreen ? 12 : 6">
                <v-card class="rounded-xl elevation-0" style="border: 0.7px solid #37474F">
                  <v-card-title class="blue-grey darken-1" :class="itemsPerPage == 5 ? 'center-item-in-div-column' : ''
                    ">
                    <label class="white--text text-center text-uppercase subtitle-1">
                      colaboradores seleccionados
                    </label>
                    <v-spacer v-show="itemsPerPage == 10"></v-spacer>
                    <v-btn class="rounded-lg" :class="!accionHabilitada
                      ? 'green lighten-5 green--text text--darken-3 font-weight-bold'
                      : ''
                      " :style="!accionHabilitada
                        ? 'border: 1.8px solid #43A047 !important;'
                        : ''
                        " color="#32CD32" dark large :disabled="accionHabilitada" @click="muestraMotivos = true">
                      registrar descanso laborado
                      <v-icon right> mdi-text-box-plus-outline </v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-text class="pt-3">
                    <v-data-table :search="searchColaboradorSeleccionado" :footer-props="{
                      'items-per-page-text': 'Elementos por página',
                      pageText: '{0}-{1} de {2}',
                    }" no-data-text="No hay registros" loading-text="Cargando información..." :no-results-text="'No se encontró ningun resultado que coincida con ' +
                      searchColaboradorSeleccionado
                      " :header-props="headerProps" dense :items="colaboradoresSeleccionados" :headers="headers"
                      :loading="loadingTablaColaboradores">
                      <template v-slot:top>
                        <v-text-field v-model="searchColaboradorSeleccionado" dense outlined class="rounded-pill"
                          clareable prepend-inner-icon="mdi-magnify" placeholder="Buscar..."></v-text-field>
                        <v-spacer></v-spacer>
                      </template>
                      <template v-slot:item.TOTAL="{ item }">
                        {{ formatMXN(item.TOTAL) }}
                      </template>
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </div>

          <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" color="colorBarra" :texto="textoBarra"
            @cierraBarra="muestraBarra = false" />
        </v-card-text>

        <v-dialog v-model="dialogFechas" persistent max-width="350px">
          <v-card class="rounded-lg">
            <div class="text-center">
              <v-date-picker v-model="date" max-width="350px" :min="anteriorlunes" :max="nextDomingo" no-title
                elevation="0" locale="es-mx" @change="
                  cargaColaboradores();
                dialogFechas = false;
                ">
              </v-date-picker>
            </div>
          </v-card>
        </v-dialog>
      </v-card>
    </v-dialog>

    <MotivosTE v-if="muestraMotivos" :muestraMotivos="muestraMotivos" @cierraMotivos="cierraMotivos" />

    <LoadingBar text="Cargando. Espere..." :dialog="muestraLoadingBar" />
  </div>
</template>

<script>
import axios from "axios";
import {
  deleteToken,
  formatDate,
} from "../../../../../store/Funciones/funciones";
import SnackBar from "../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import MotivosTE from "./MotivosTE.vue";
import LoadingBar from "../../../DesarrolloOrganizacional/components/loadingBar.vue";
import moment from "moment/moment";

export default {
  props: ["openRegistro"],
  components: { SnackBar, MotivosTE, LoadingBar },
  data() {
    return {
      departamentos: [],
      areas: [],
      colaboradores: [],
      colaboradoresSeleccionados: [],
      date: null,
      headers: [
        { value: "EMP_KEYEMP", text: "EMPLID" },
        { value: "EMP_NOMEMP", text: "NOMBRE" },
        { value: "DESCR", text: "PUESTO" },
        { value: "TOTAL", text: "COSTO" },
      ],
      headerProps: {
        sortByText: "Ordenar por",
      },
      indexArea: 0,
      searchColaboradorSeleccionado: null,
      searchColaborador: null,
      numAreas: null,
      numDepartamento: null,
      areaSelected: null,
      deptSelected: null,
      dateDialog: null,
      anteriorlunes: null,
      nextDomingo: null,
      loadingData: true,
      loadingTablaColaboradores: false,
      dialogFechas: false,
      muestraBarra: false,
      muestraMotivos: false,
      muestraLoadingBar: false,
      colorBarra: "",
      textoBarra: "",
      fechaBloqueo: "",
      semanaactual: "",
    };
  },
  async mounted() {
    this.departamentos = await this.getDepartamentosAccesos();
    if (![undefined, null].includes(this.departamentos.result)) {
      const areasMap = {};
      this.departamentos.result.forEach((item) => {
        if (!areasMap[item.area]) {
          areasMap[item.area] = {
            area: item.area,
            areaDesc: item.areaDesc,
            depsPorArea: [],
          };
        }
        areasMap[item.area].depsPorArea.push({
          id_departamento_ps: item.id_departamento_ps,
          id_departamento_zn: item.id_departamento_zn,
          DESCR: item.DESCR,
        });
      });

      this.areas = Object.values(areasMap);
      this.areaSelected = this.areas[0].area;
      this.deptSelected = this.areas[0].depsPorArea[0].id_departamento_zn;
      this.mostrarsemana(this.getWeekNumber());
      this.loadingData = false;
    }
  },
  computed: {
    itemsPerPage() {
      return ["xs", "sm", "md"].includes(this.$vuetify.breakpoint.name)
        ? 5
        : 10;
    },
    isSmallScreen() {
      return ["xs", "sm"].includes(this.$vuetify.breakpoint.name)
        ? true
        : false;
    },
    getSumatoria() {
      return this.colaboradoresSeleccionados.length == 0
        ? 0
        : this.colaboradoresSeleccionados.reduce((acumulador, empleado) => {
          return acumulador + empleado.TOTAL;
        }, 0);
    },
    dateRangeText() {
      let inicio = "";

      if (this.dates[0] != null) {
        inicio = formatDate(this.dates[0]);
      }
      if (this.dates.length == 0) {
        return "";
      }
      return inicio;
    },
    accionHabilitada() {
      return this.colaboradoresSeleccionados.length > 0 &&
        ![null, undefined, ""].includes(this.date)
        ? false
        : true;
    },
  },
  methods: {
    async cargaColaboradores() {
      this.loadingTablaColaboradores = true;
      this.colaboradores = await this.getColaboradoresPorDepartamento(
        this.deptSelected
      ).catch(() => {
        return [];
      });
    },
    mostrarsemana(semana) {
      const ahora = moment();
      let primerdia = moment()
        .isoWeek(semana)
        .startOf("isoweek");
      let ultimodia = moment()
        .isoWeek(semana)
        .endOf("isoweek");
      let LUNES = primerdia.format("YYYY-MM-DD");
      let DOMINGO = ultimodia.format("YYYY-MM-DD");

      //comprobar si es lunes.
      if (ahora.day() == 1) {
        //comprobar si es antes de las 11:10.
        const horalimite = moment("11:10 AM", "h:mm A");
        if (ahora.isAfter(horalimite)) {
          this.anteriorlunes = LUNES;
          this.nextDomingo = DOMINGO;
        } else {
          //Mostrar la semana anterior
          let lunesAnterior = moment(this.LUNES)
            .subtract(7, "days")
            .format("YYYY-MM-DD");
          this.anteriorlunes = lunesAnterior;
          // console.log(lunesAnterior);
        }
      } else {
        this.anteriorlunes = LUNES;
        this.nextDomingo = DOMINGO;
      }
    },
    showSnackBar(texto, color = "error") {
      this.textoBarra = texto;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    getDepartamentosAccesos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/solicitantes/departamentos-por-solicitante`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else {
              res(response.data.data);
            }
          });
      });
    },
    getColaboradoresPorDepartamento(idDepartamento) {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/solicitantes/departamentos-por-solicitante/${this.date}/${idDepartamento}`,
            {
              headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingTablaColaboradores = false;
          });
      });
    },
    getWeekNumber(date = new Date()) {
      // Crear una copia de la fecha para no modificar la original
      const currentDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));

      // Establecer el día de la semana, considerando que lunes es el primer día (1) y domingo el último (7)
      const day = currentDate.getUTCDay() || 7;

      // Ajustar la fecha al lunes de la semana actual
      currentDate.setUTCDate(currentDate.getUTCDate() + 4 - day);

      // Calcular el inicio del año ISO (jueves de la primera semana del año)
      const yearStart = new Date(Date.UTC(currentDate.getUTCFullYear(), 0, 1));
      yearStart.setUTCDate(yearStart.getUTCDate() + (4 - (yearStart.getUTCDay() || 7)));

      // Calcular el número de la semana ISO
      const weekNumber = Math.ceil(((currentDate - yearStart) / 86400000 + 1) / 7);
      return weekNumber;
    },
    cambiaArea() {
      const indice = this.areas.findIndex(
        (objeto) => objeto.area === this.areaSelected
      );
      this.indexArea = indice;
      this.deptSelected = this.areas[indice].depsPorArea[0].id_departamento_zn;
      this.updateTablaColaboradores();
    },
    async updateTablaColaboradores() {
      alert("Se debe crear una solicitud diferente para cada uno de los departamentos!");
      this.colaboradoresSeleccionados = [];
      this.loadingTablaColaboradores = true;
      this.colaboradores = await this.getColaboradoresPorDepartamento(
        this.deptSelected
      );
    },
    fixFormatDate(date) {
      return formatDate(date);
    },
    cierraDialog() {
      if (this.dates.length == 1) {
        this.dialogFechas = false;
      }
    },
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },
    async cierraMotivos(motivo) {
      this.muestraMotivos = false;
      if (motivo !== null) {
        this.muestraLoadingBar = true;
        const respuesta = await this.setDesacansoLaborado(motivo);
        if (
          respuesta.status === "OK" &&
          respuesta.data.colaboradoresRegistrados >= 1
        ) {
          this.showSnackBar(
            "Solicitud generada correctamente.",
            "teal darken-2"
          );
          this.colaboradoresSeleccionados.length = 0;
          this.date = null;
          this.$emit("cierraRegistro", true);
        } else if (respuesta.status === "OK" && respuesta.data == 0) {
          this.showSnackBar("No se generó la solicitud. Intenta más tarde.");
        } else {
          this.showSnackBar(
            "ERROR. No se generó la solicitud. Intenta más tarde."
          );
        }
      }
    },
    setDesacansoLaborado(datosMotivo) {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      const indexDepto = this.areas[this.indexArea].depsPorArea.findIndex(
        (d) => d.id_departamento_zn === this.deptSelected
      );

      const params = {
        fecha: this.date,
        motivo: datosMotivo.motivo,
        motivoDesc: datosMotivo.motivoDesc,
        departamento: this.deptSelected,
        departamentoDesc: this.areas[this.indexArea].depsPorArea[indexDepto]
          .DESCR,
        costoTotalFormatMXN: this.formatMXN(this.getSumatoria),
        costoTotal: this.getSumatoria,
        responsableSeleccionado: datosMotivo.responsableSeleccionado,
        figuras: datosMotivo.figuras,
        justificacion: datosMotivo.justificacion,
        minimo: datosMotivo.minimo,
        maximo: datosMotivo.maximo,
        monto: datosMotivo.monto,
        area: datosMotivo.area,
        colaboradores: this.colaboradoresSeleccionados,
      };

      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/descanso-laborado/guarda-incidencia`,
            params,
            {
              headers,
            }
          )
          .then(async (response) => {
            if (response.data.status == "EXPSESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data);
            } else {
              res(response.data);
            }
          })
          .finally(() => {
            this.muestraLoadingBar = false;
          });
      });
    },
    handleSelectionUpdate(emp) {
      console.log("Entra seleccion de todos 1")
      if (emp.item.dias_desde_ultimo_ausentismo <= 15 && emp.value) {
        this.colaboradoresSeleccionados = this.colaboradoresSeleccionados.filter(item => item.dias_desde_ultimo_ausentismo > 15);
      }
    },
    seleccionaATodos() {
      this.colaboradoresSeleccionados = this.colaboradores.filter(item => item.dias_desde_ultimo_ausentismo > 15);
    },
    handleSelect(selectedItems) {
      this.colaboradoresSeleccionados = selectedItems.filter(item => item.dias_desde_ultimo_ausentismo > 15);
    }
  },
};
</script>
