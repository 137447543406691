<template>
  <v-container fluid>
    <v-card
      class="rounded-xxl elevation-2"
      v-if="contadores && contadores.length > 0"
    >
      <h2 class="text-center primary--text font-weight-bold">
        Filtro por estatus
      </h2>
      <v-row class="justify-space-between mt-5">
        <v-col
          class="pa-0 d-flex justify-center"
          cols="12"
          xl="2"
          lg="2"
          md="2"
          sm="12"
          xs="12"
          v-for="(item, index) in contadores"
          :key="index"
        >
          <v-chip-group column>
            <v-btn
              class="mb-2"
              small
              rounded
              elevation="1"
              :color="colorsTema[item.estatus - 1].color"
              :dark="colorsTema[item.estatus - 1].tema"
              @click="filterStatus(item.estatus)"
            >
              {{ item.nombre }} [{{ item.num }}]
            </v-btn>
          </v-chip-group>
        </v-col>
      </v-row>
    </v-card>
    <br />
    <v-data-table
      :headers="headers"
      :items="personalTemporal"
      :search="buscar"
      :loading="loadingInfo"
      class="rounded-xl rounded-t-0 elevation-0"
      loading-text="Cargando colaboradores"
      dense
      mobile-breakpoint="0"
      :footer-props="{ 'items-per-page-text': 'Elementos por página' }"
      no-data-text="No se encontraron registros"
      no-results-text="No se encontraron coincidencias con tu búsqueda"
    >
      <template v-slot:top>
        <v-toolbar flat class="rounded-lg rounded-b-0 rounded-t-0">
          <v-text-field
            v-model="buscar"
            :label="$t('DH360.findTextBox')"
            class="mt-6 rounded-pill"
            dense
            clearable
            outlined
            solo-inverted
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
          <v-spacer></v-spacer>
          <v-btn
            v-if="
              personalTemporal &&
              ($vuetify.breakpoint.name !== 'xs' ||
                $vuetify.breakpoint.name !== 'xs')
            "
            outlined color="teal darken-1" class="rounded-pill ma-2" small
            @click="filterStatus('all_incidencias')"
            >Consultar todo
          </v-btn>
          <v-btn
            v-else-if="
              personalTemporal &&
              ($vuetify.breakpoint.name === 'xs' ||
                $vuetify.breakpoint.name === 'xs')
            "
            outlined color="teal darken-1" class="rounded-pill ma-2" small
            @click="filterStatus('all_incidencias')"
            >Ver todo
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.nombre="{ item }">
        <div align="left">{{ item.nombre }}</div>
      </template>
      <template v-slot:item.DESCR="{ item }">
        <div align="left">{{ item.DESCR }}</div>
      </template>
      <template v-slot:item.descr1="{ item }">
        <div align="left">{{ item.descr1 }}</div>
      </template>
      <template v-slot:item.Area="{ item }">
        <div align="left">{{ item.Area }}</div>
      </template>
      <template v-slot:item.nombreEstatus="{ item }">
        <div align="left">{{ item.nombreEstatus }}</div>
      </template>

      <template v-slot:item.estatus="{ item }">
        <div v-if="item.estatus == 1 && !jobcodes.includes(item.JOBCODE)">
          <v-tooltip left color="blue lighten-4">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="blue darken-4"
                v-bind="attrs"
                v-on="on"
                @click="openConfirm(item.id, item.nombre)"
              >
                mdi-account-reactivate-outline
              </v-icon>
            </template>
            <label class="text-uppercase font-weight-bold primary--text"
              >Activar. Evaluación disponible el: <br />{{
                item.fecha_aplicacion
              }}</label
            >
          </v-tooltip>
          <v-tooltip right color="pink lighten-4" v-if="item.DIAS > 180">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="pink darken-4"
                v-bind="attrs"
                v-on="on"
                @click="openDialogRemove(item)"
              >
                mdi-account-remove-outline
              </v-icon>
            </template>
            <label class="text-uppercase font-weight-bold pink--text"
              >Remover encuesta</label
            >
          </v-tooltip>
        </div>
        <div v-if="item.estatus == 1 && jobcodes.includes(item.JOBCODE)">
          <v-tooltip top color="blue lighten-4">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="blue darken-4"
                v-bind="attrs"
                v-on="on"
                @click="openConsultaKardex(item)"
              >
                mdi-notebook-outline
              </v-icon>
            </template>
            <label class="text-uppercase font-weight-bold primary--text"
              >Consultar Kardex</label
            >
          </v-tooltip>
        </div>
        <div v-else-if="item.estatus == 2 && !jobcodes.includes(item.JOBCODE)">
          <v-tooltip left color="green lighten-4">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="green darken-4"
                v-bind="attrs"
                v-on="on"
                @click="dialogCapturaClientes(item)"
              >
                mdi-file-document-edit-outline
              </v-icon>
            </template>
            <label class="text-uppercase font-weight-bold green--text"
              >Capturar evaluadores</label
            >
          </v-tooltip>
        </div>
        <div v-else-if="item.estatus == 2 && jobcodes.includes(item.JOBCODE)">
          <v-tooltip top color="blue lighten-4">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="blue darken-4"
                v-bind="attrs"
                v-on="on"
                @click="openConsultaKardex(item)"
              >
                mdi-notebook-outline
              </v-icon>
            </template>
            <label class="text-uppercase font-weight-bold primary--text"
              >Consultar Kardex</label
            >
          </v-tooltip>
        </div>
        <div v-else-if="item.estatus == 3">
          <v-tooltip left color="cyan darken-4">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="vista == 1 || vista == 2"
                color="cyan darken-4"
                v-bind="attrs"
                v-on="on"
                @click="iniciarEncuesta(item)"
              >
                mdi-file-eye-outline
              </v-icon>
              <v-icon
                v-else-if="vista == 3"
                color="cyan darken-4"
                v-bind="attrs"
                v-on="on"
                @click="visualizarEncuestas(item)"
              >
                mdi-file-eye-outline
              </v-icon>
            </template>
            <label
              class="text-uppercase font-weight-bold white--text"
              v-if="vista == 1 || vista == 2"
              >Iniciar evaluación</label
            >
            <label
              class="text-uppercase font-weight-bold white--text"
              v-else-if="vista == 3"
              >Ver proceso</label
            >
          </v-tooltip>
        </div>
        <div v-else-if="item.estatus == 4">
          <v-tooltip left color="light-blue lighten-4">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="light-blue lighten-4"
                v-bind="attrs"
                v-on="on"
                @click="visualizarEncuestas(item)"
              >
                mdi-card-account-details-star
              </v-icon>
            </template>
            <label class="text-uppercase font-weight-bold blue--text"
              >Completar y terminar</label
            >
          </v-tooltip>
        </div>
        <div v-else-if="item.estatus == 5">
          <v-tooltip left color="indigo darken-4">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="vista == 3"
                color="indigo darken-4"
                v-bind="attrs"
                v-on="on"
                @click="visualizarEncuestas(item)"
              >
                mdi-account-check
              </v-icon>
            </template>
            <label class="text-uppercase font-weight-bold grey--text"
              >Terminado</label
            >
          </v-tooltip>
          <v-tooltip right color="indigo darken-2">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="vista == 3"
                color="indigo darken-2"
                v-bind="attrs"
                v-on="on"
                @click="confirmaCompensaciones(item)"
              >
                mdi-account-star-outline
              </v-icon>
            </template>
            <label class="text-uppercase font-weight-bold grey--text"
              >Confirmar finalización de proceso</label
            >
          </v-tooltip>
        </div>
        <div v-else-if="item.estatus == 6">
          <v-tooltip left color="pink">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="vista == 3"
                color="pink"
                v-bind="attrs"
                v-on="on"
                @click="visualizarEncuestas(item)"
              >
                mdi-account-star-outline
              </v-icon>
            </template>
            <label class="text-uppercase font-weight-bold white--text"
              >Planta Asignada</label
            >
          </v-tooltip>
        </div>
      </template>
    </v-data-table>

    <v-dialog v-model="confirmarPaso" max-width="600px" persistent>
      <v-card>
        <v-card-title class="text-subtitle-2 primary white--text">
          Confirmar inicio de evaluación
          <v-spacer></v-spacer>
          <v-icon small dark @click="confirmarPaso = false"
            >mdi mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                class="pb-0"
                cols="12"
                xl="12"
                lg="12"
                md="12"
                sm="12"
                xs="12"
              >
                ¿Estas seguro de iniciar la evaluación de
                <b>{{ encuesta.emplid }} {{ encuesta.nombre }}</b
                >? <br />
                Fecha de aplicación: {{ encuesta.fecha_aplicacion }}.
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg elevation-0"
            color="teal darken-1"
            dark
            :loading="loading"
            @click="updateEstatus()"
          >
            <v-icon left>mdi-account-arrow-right</v-icon> Continuar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogRemove" max-width="600px" persistent>
      <v-card>
        <v-card-title class="text-subtitle-2 primary white--text">
          Remover encuesta del proceso
          <v-spacer></v-spacer>
          <v-icon small dark @click="dialogRemove = false"
            >mdi mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col
                class="pb-0"
                cols="12"
                xl="12"
                lg="12"
                md="12"
                sm="12"
                xs="12"
              >
                El registro que intenta remover del proceso ya cuenta con
                <b>{{ item.DIAS }} </b> Días de haber ingresado ¿Está seguro de
                eliminar este registro?
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-lg elevation-0"
            color="teal darken-1"
            dark
            :loading="loading"
            @click="deleteRegistro()"
          >
            <v-icon left>mdi-check-circle-outline</v-icon> Si
          </v-btn>
          <v-btn
            class="rounded-lg elevation-0"
            color="pink"
            dark
            :loading="loading"
            @click="dialogRemove = false"
          >
            <v-icon left>mdi-minus-circle-outline</v-icon> No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogKardex" max-width="700px" persistent>
      <v-card>
        <v-card-title class="text-subtitle-2 primary white--text">
          Kardex de colaborador
          <v-spacer></v-spacer>
          <v-icon small dark @click="dialogKardex = false"
            >mdi mdi-close
          </v-icon>
        </v-card-title>
        <v-card-text>
          <v-container>
            <div v-show="dialogKardexProgress" class="text-center">
              <h2 class="text-center blue-grey--text text--lighten-2 mb-5">
                Consultando kardex...
              </h2>
              <v-progress-circular
                color="primary"
                indeterminate
                :size="70"
                :width="7"
              ></v-progress-circular>
            </div>
            <div v-show="!dialogKardexProgress">
              <div v-if="faltasKardex[0]">
                <v-alert
                  v-if="
                    faltasKardex[0]['faltasADM'] == 0 &&
                    faltasKardex[0]['faltasInjust'] == 0
                  "
                  prominent
                  color="teal darken-1"
                  type="success"
                  dark
                  dense
                  elevation="1"
                  border="left"
                >
                  ¡Excelente! No cuenta con faltas administrativas o faltas
                  injustificadas en los ultimos 90 días. Puede continuar con el
                  proceso
                </v-alert>
                <v-alert
                  v-else-if="
                    faltasKardex[0]['faltasADM'] > 0 ||
                    faltasKardex[0]['faltasInjust'] > 0
                  "
                  prominent
                  color="pink darken-1"
                  type="error"
                  dark
                  dense
                  elevation="1"
                  border="left"
                >
                  ¡Oh no! Su kardex en estos ultimos 90 días presenta faltas.
                </v-alert>
              </div>
              <v-row v-if="faltasKardex[0]">
                <v-col align="center" cols="6">
                  <v-card
                    elevation="2"
                    :color="
                      faltasKardex[0]['faltasADM'] == 0
                        ? 'teal lighten-3'
                        : 'red lighten-3'
                    "
                    class="py-5 mx-auto"
                  >
                    <h1 class="font-weight-bold white--text">
                      {{ faltasKardex[0]["faltasADM"] }}
                    </h1>
                    <br />
                    <v-divider class="white-divider"></v-divider>
                    <div class="font-weight-bold white--text">
                      Faltas administrativas
                    </div>
                  </v-card>
                </v-col>
                <v-col align="center" cols="6">
                  <v-card
                    elevation="2"
                    :color="
                      faltasKardex[0]['faltasInjust'] == 0
                        ? 'teal lighten-3'
                        : 'red lighten-3'
                    "
                    class="py-5 mx-auto"
                  >
                    <h1 class="font-weight-bold white--text">
                      {{ faltasKardex[0]["faltasInjust"] }}
                    </h1>
                    <br />
                    <v-divider class="white-divider"></v-divider>
                    <div class="font-weight-bold white--text">
                      Faltas injustificadas
                    </div>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="faltasKardex[0]">
          <v-spacer></v-spacer>
          <v-btn
            v-if="
              faltasKardex[0]['faltasADM'] == 0 &&
              faltasKardex[0]['faltasInjust'] == 0
            "
            class="rounded-lg elevation-0"
            color="teal darken-4"
            dark
            small
            @click="continuarProceso"
          >
            <v-icon left>mdi-check-circle-outline</v-icon> Continuar
          </v-btn>
          <v-btn
            v-else-if="
              faltasKardex[0]['faltasADM'] > 0 ||
              faltasKardex[0]['faltasInjust'] > 0
            "
            class="rounded-lg elevation-0"
            color="pink"
            dark
            small
            @click="dialogKardex = false"
          >
            <v-icon left>mdi-check-circle-outline</v-icon> Entendido
          </v-btn>
        </v-card-actions>
        <v-divider></v-divider>
      </v-card>
    </v-dialog>
    <consulta-encuestas-admin
      v-if="openConsultaEncuestas"
      :dialog="openConsultaEncuestas"
      :evaluado="item"
      @cierraDialgoConsultaEncuestas="cierraDialogConsultaEncuestas"
    />
    <confirma-compensaciones
      v-if="openDialogConfirmaCompensaciones"
      :dialog="openDialogConfirmaCompensaciones"
      :evaluado="item"
      @cierraDialgoConfirmaCompensaciones="cierraDialogConfirmaCompensaciones"
    />
    <encuestas-personal-temporal
      v-if="openEncuestas"
      :dialog="openEncuestas"
      :evaluado="item"
      @cierraDialgoEncuesta="cierraDialogEncuestas"
    />
    <carga-clientes-pares
      v-if="dialogCaptura"
      :dialog="dialogCaptura"
      :evaluado="item"
      @cierraDialgoCaptura="cierraCapturaClientes"
    />
    <SnackBar
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
    <ConfirmDialogReclutamiento
      :showConfirm="muestraConfirm"
      :question="question"
      @cierraConfirm="cierraConfirm"
    />
  </v-container>
</template>



<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";
import SnackBar from "../../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import CargaClientesPares from "./CargaClientesPares.vue";
import EncuestasPersonalTemporal from "./EncuestasPersonalTemporal.vue";
import ConsultaEncuestasAdmin from "./ConsultaEncuestasAdmin.vue";
import ConfirmaCompensaciones from "./ConfirmaCompensaciones.vue";
import ConfirmDialogReclutamiento from "../../../../AdmnVacantes/components/ConfirmDialogReclutamiento.vue";
export default {
  name: "lista-personal-temporal",
  components: {
    SnackBar,
    CargaClientesPares,
    EncuestasPersonalTemporal,
    ConsultaEncuestasAdmin,
    ConfirmaCompensaciones,
    ConfirmDialogReclutamiento,
  },
  props: ["ruta"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,

    item: [],
    personalTemporal: [],
    contadores: [],
    buscar: "",
    loadingInfo: true,
    dialogCaptura: false,
    loading: false,
    loadingColaboradores: false,
    nombre: "",
    colaboradores: [],
    colaboradoresNombre: [],
    sendPares: [],
    sendClientes: [],
    vista: 0,
    confirmarPaso: false,
    encuesta: "",
    openEncuestas: false,
    openConsultaEncuestas: false,
    estatus: "all_incidencias",
    estatusFilterValue: "",
    estatusPlantasAdmin: [],
    headers: [
      { text: "Emplid", value: "emplid", align: "center", sortable: true },
      { text: "Nombre", value: "nombre", align: "center", sortable: true },
      { text: "Puesto", value: "DESCR", align: "center", sortable: true },
      {
        text: "Departamento",
        value: "descr1",
        align: "center",
        sortable: true,
      },
      { text: "Area", value: "Area", align: "center", sortable: true },
      { text: "Días activos", value: "DIAS", align: "center", sortable: true },
      {
        text: "Grupo de pago",
        value: "grupoPago",
        align: "center",
        sortable: true,
      },
      { text: "Estatus", value: "nombreEstatus" },
      { text: "Acciones", value: "estatus", align: "center", sortable: true },
    ],
    colorsTema: [
      { color: "blue darken-4", tema: true },
      { color: "green darken-4", tema: true },
      { color: "cyan lighten-4", tema: false },
      { color: "light-blue lighten-4", tema: false },
      { color: "indigo darken-4", tema: true },
      { color: "pink", tema: true },
    ],
    openDialogConfirmaCompensaciones: false,
    dialogRemove: false,
    jobcodes: [],
    dialogKardex: false,
    dialogKardexProgress: false,
    faltasKardex: [],
    muestraConfirm: false,
    question: "",
    idAux: 0,
  }),
  created() {},
  computed: {
    rutaLocal: {
      get() {
        return this.ruta;
      },
      set(nuevaRuta) {
        // Modificar la prop a través de la propiedad computada
        this.$emit("update:ruta", nuevaRuta);
      },
    },
  },
  watch: {
    $route(to, from) {
      // Este watcher se activará cuando cambies de ruta
      const desde = to.path.split("/");
      const hacia = from.path.split("/");
      if (desde[2] == hacia[2]) {
        console.log("son iguales");
        if (desde[1] == hacia[1]) {
          console.log("son iguales 2");
        } else if (desde[1] == "MiEmpresa") {
          this.funcionPrueba(1);
        } else if (desde[1] == "MiEquipo") {
          this.funcionPrueba(2);
        } else if (desde[1] == "Especiales") {
          this.funcionPrueba(3);
        }
      } else if (desde[2] == "MiEmpresa") {
        this.funcionPrueba(1);
      } else if (desde[2] == "MiEquipo") {
        this.funcionPrueba(2);
      } else if (desde[2] == "Especiales") {
        this.funcionPrueba(3);
      }
    },
  },
  mounted() {
    this.getRuta();
    this.saveMonitoreo();
    this.getEstatusPlantasAdministrativas();
    this.getPuestosExcepcionesPuestos();
  },

  methods: {
    saveMonitoreo() {
      let params = {
        Opcion: 10,
        pantalla: "Plantas Administrativas",
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          console.log(response.data);
        });
    },

    filterStatus(status) {
      this.estatus = status;
      this.clean();
      this.getRuta();
    },

    funcionPrueba(opc) {
      this.vista = opc;
      this.clean();
      this.getPersonalTemporal(opc);
    },
    getPersonalTemporal(rol) {
      let params = {
        Opcion: 1,
        ruta: this.ruta,
        rol: rol,
        estatus: this.estatus,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          params
        )
        .then((response) => {
          this.loadingInfo = false;
          if (response.data.status == "OK") {
            this.personalTemporal = response.data.personalTemporal;
            this.contadores = response.data.contadores;
          } else if (response.data.status == "VACIO") {
            console.log("Vacio");
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },

    async getEstatusPlantasAdministrativas() {
      let params = {
        Opcion: 15,
        token: localStorage.getItem("token"),
      };

      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          params
        )
        .then((response) => {
          // this.loadingInfo = false;
          if (response.data.status == "OK") {
            this.estatusPlantasAdmin = response.data.estatusPlantas;
          } else if (response.data.status == "VACIO") {
            console.log("Vacio");
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },

    async getPuestosExcepcionesPuestos() {
      let params = {
        Opcion: 23,
        token: localStorage.getItem("token"),
      };

      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          params
        )
        .then((response) => {
          // this.loadingInfo = false;
          if (response.data.status == "OK") {
            this.jobcodes = response.data.data;
          } else if (response.data.status == "VACIO") {
            console.log("Vacio");
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },

    buscarNumeroEmpleado() {
      if (this.nombre) {
        let resultdo = this.colaboradores.filter(
          (dato) => "[" + dato["EMPLID"] + "] " + dato["NAME"] == this.nombre
        );
        this.clientes.push(resultdo[0]["EMPLID"]);
      }
    },

    dialogCapturaClientes(item) {
      this.item = item;
      this.dialogCaptura = true;
    },

    async getRuta() {
      this.rutaLocal = this.ruta;
      const rutaLocal = this.rutaLocal.split("/");
      if (rutaLocal[1] == "MiEmpresa" || rutaLocal[2] == "MiEmpresa") {
        this.funcionPrueba(1);
      } else if (rutaLocal[1] == "MiEquipo" || rutaLocal[2] == "MiEquipo") {
        this.funcionPrueba(2);
      } else if (rutaLocal[1] == "Especiales" || rutaLocal[2] == "Especiales") {
        this.funcionPrueba(3);
      }
    },

    clean() {
      this.personalTemporal = [];
      this.loadingInfo = true;
      this.encuesta = "";
      this.confirmarPaso = false;
      this.dialogRemove = false;
    },

    siguientePaso(item) {
      var fechaActual = new Date();
      // Obtener la fecha que deseas comparar (formato YYYY-MM-DD)
      var fechaAplicacion = new Date(item.fecha_aplicacion);

      // Comparar las fechas
      if (fechaAplicacion <= fechaActual) {
        this.encuesta = item;
        this.confirmarPaso = true;
        // this.updateEstatus(item);
      } else if (fechaAplicacion > fechaActual) {
        this.textoBarra =
          "Espera a que su fecha de evaluación esté disponible. Fecha disponible: " +
          item.fecha_aplicacion;
        this.colorBarra = "error";
        this.muestraBarra = true;
      }
    },

    updateEstatus() {
      let params = {
        Opcion: 4,
        token: localStorage.getItem("token"),
        item: this.encuesta,
      };
      this.loading = true;
      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.clean();
            this.textoBarra = "Solicitud registrada correctamente.";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.funcionPrueba(this.vista);
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "Se ha producido un error al registrar .";
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
      this.loading = false;
    },

    cierraCapturaClientes(result) {
      if (result.result == "OK") {
        this.textoBarra = "Clientes y pares cargados correctamente";
        this.colorBarra = "success";
        this.muestraBarra = true;
      }
      this.dialogCaptura = false;
      this.clean();
      this.funcionPrueba(this.vista);
    },

    iniciarEncuesta(item) {
      this.openEncuestas = true;
      this.item = item;
    },

    visualizarEncuestas(item) {
      this.openConsultaEncuestas = true;
      this.item = item;
    },

    cierraDialogEncuestas(result) {
      if (result.result == "OK") {
        this.textoBarra = "Gracias por sus respuestas";
        this.colorBarra = "success";
        this.muestraBarra = true;
      }
      this.openEncuestas = false;
      this.clean();
      this.funcionPrueba(this.vista);
    },

    cierraDialogConsultaEncuestas(result) {
      this.openConsultaEncuestas = false;
      if (result.result == "OK") {
        this.textoBarra =
          "Proceso terminado y notificación enviada. ¡Muchas Gracias!";
        this.colorBarra = "success";
        this.muestraBarra = true;
      } else if (result.result == "HabilitarBtn") {
        this.textoBarra =
          "Ultima encuesta pendiente removida, vuelva a ingresar para continar con el calculo de calificaciones";
        this.colorBarra = "success";
        this.muestraBarra = true;
      } else if (result.result == "calculado") {
        this.textoBarra =
          "Calificaciones registradas, vuelva a ingresar para notificar y terminar el proceso";
        this.colorBarra = "success";
        this.muestraBarra = true;
      }
      this.clean();
      this.funcionPrueba(this.vista);
    },

    cierraDialogConfirmaCompensaciones(result) {
      this.openDialogConfirmaCompensaciones = false;
      if (result.result == "OK") {
        this.textoBarra =
          "Se ha confirmado la asignación de planta administrativa. ¡Muchas gracias!";
        this.colorBarra = "success";
        this.muestraBarra = true;
        this.clean();
        this.funcionPrueba(this.vista);
      }
    },

    confirmaCompensaciones(item) {
      this.openDialogConfirmaCompensaciones = true;
      this.item = item;
    },

    deleteRegistro() {
      let params = {
        Opcion: 17,
        token: localStorage.getItem("token"),
        item: this.item,
      };
      this.loading = true;
      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          params
        )
        .then((response) => {
          if (response.data.status == "OK") {
            this.clean();
            this.textoBarra = "Registro removido correctamente.";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.funcionPrueba(this.vista);
          } else if (response.data.status == "ERROR") {
            this.textoBarra = "Se ha producido un error .";
            this.colorBarra = "error";
            this.muestraBarra = true;
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
      this.loading = false;
    },

    openDialogRemove(item) {
      this.dialogRemove = true;
      this.item = item;
    },

    openConsultaKardex(item) {
      this.dialogKardex = true;
      this.dialogKardexProgress = true;
      this.item = item;
      let params = {
        Opcion: 21,
        emplid: item.emplid,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          params
        )
        .then((response) => {
          this.dialogKardexProgress = false;
          if (response.data.status == "OK") {
            this.faltasKardex = response.data.kardex;
          } else if (response.data.status == "VACIO") {
            console.log("Vacio");
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },

    continuarProceso() {
      this.loading = true;
      let params = {
        Opcion: 22,
        item: this.item,
        token: localStorage.getItem("token"),
      };
      axios
        .post(
          `${this.$store.getters.getHost}/General/PersonalTemporal/ws-personal-temporal.php`,
          params
        )
        .then((response) => {
          this.loading = false;
          this.dialogKardex = false;
          if (response.data.status == "OK") {
            this.clean();
            this.textoBarra =
              "Colaborador ha continado al proceso de 'Cerrada'. Espere la aprovación de compensaciónes";
            this.colorBarra = "success";
            this.muestraBarra = true;
            this.funcionPrueba(this.vista);
          } else if (response.data.status == "VACIO") {
            console.log("Vacio");
          } else if (response.data.status == "EXPRESSION") {
            deleteToken();
          }
        });
    },
    openConfirm(id, nombre) {
      this.muestraConfirm = true;
      (this.idAux = id),
        (this.question =
          "¿Realmente deseas activar la evaluación de " + nombre + "?");
    },

    cierraConfirm(respuesta) {
      if (!respuesta) {
        this.muestraConfirm = false;
      } else {
        this.muestraConfirm = false;
        this.loadingData = true;
        this.activateSurvey();
      }
    },

    async activateSurvey() {
      const params = {
        Opcion: 2,
        token: localStorage.getItem("token"),
        idEvaluado: this.idAux,
      };

      try {
        const response = await axios.post(
          `${this.$store.getters.getHost}/RelacionesLaborales/PlantasAdministrativas/ws-plantas-administrativas.php`,
          params
        );

        if (response.data.status == "OK") {
          if (response.data.respuesta.status_tran == "OK") {
            this.muestraSnackBar(response.data.message, "teal darken-1");
            this.funcionPrueba(this.vista);
          } else {
            this.muestraSnackBar(response.data.message, "pink darken-3");
          }
        } else {
          this.muestraSnackBar("Ocurrió un error.", "pink darken-3");
        }
      } catch (error) {
        this.muestraSnackBar(error, "pink darken-3");
      }
    },
    muestraSnackBar(texto, color) {
      this.colorBarra = color;
      this.textoBarra = texto;
      this.muestraBarra = true;
    },
  },
};
</script>

<style scoped>
.white-divider {
  background-color: white; /* Cambia "white" al color que desees */
}
</style>