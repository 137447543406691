import { render, staticRenderFns } from "./AdmonBanyax.html?vue&type=template&id=187ecb94&scoped=true&"
import script from "./AdmonBanyax.js?vue&type=script&lang=js&"
export * from "./AdmonBanyax.js?vue&type=script&lang=js&"
import style0 from "./AdmonBanyax.scss?vue&type=style&index=0&id=187ecb94&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "187ecb94",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VContainer})
