<template>
    <v-card class="rounded-md elevation-3 mt-0 pa-1" color="#ECEFF1">
        <v-card class="rounded-xl elevation-2 mt-1 pa-4 ">
            <v-data-table dense :headers="headers" :search="search" :items="comisionistas"
                no-data-text="No hay registros de comisiones" :footer-props="{
                    'items-per-page-text': 'Elementos por página',
                }" :header-props="{ 'short-by-text': 'Ordenar por', }" loading-text="Cargando contenido..."
                :loading="loadingComisiones">
                <template v-slot:top>
                    <div style="display: flex; justify-content: space-between;">
                        <v-text-field v-model="search" outlined dense class="rounded-xl" style="max-width: 350px;"
                            prepend-inner-icon="mdi-table-search" placeholder="Buscar en tabla..."></v-text-field>

                        <v-select v-model="selectedMes" :items="meses" item-text="nombre" item-value="id" outlined dense
                            class="rounded-xl" style="max-width: 200px;" label="Buscar por Mes"
                            @change="fetchComisionistas"></v-select>

                        <vue-json-to-csv :json-data="itemsExcel" :labels="headers_report_to_export" :csv-title="title"
                            class="ma-0">
                            <v-tooltip bottom color="blue lighten-4">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn depressed class="rounded-pill ml-2" dark color="green darken-2"
                                        v-bind="attrs" v-on="on" @click="exportToCsv" outlined>
                                        <v-icon left>mdi-microsoft-excel</v-icon>
                                        Exportar
                                    </v-btn>
                                </template>
                                <label class="text-uppercase font-weight-bold primary--text">Descarga la info mostrada en la tabla</label>
                            </v-tooltip>
                        </vue-json-to-csv>
                        <div>
                            <v-tooltip bottom color="blue lighten-4">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn depressed class="rounded-pill ml-2" color="teal" outlined dark v-bind="attrs"
                                        v-on="on" @click="openAgregaBeneficio()">
                                        <v-icon left> mdi-plus </v-icon>
                                        <label class="text-uppercase font-weight-bold ">Calcular mes</label>
                                    </v-btn>
                                </template>
                                <label class="text-uppercase font-weight-bold primary--text">Carga excel con comisiones del mes</label>
                            </v-tooltip>
                            <v-tooltip bottom color="blue lighten-4">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn small fab depressed class="ml-2" v-bind="attrs" outlined v-on="on"
                                        @click="colseAgregaComisionistas(1)">
                                        <v-icon>mdi-reload</v-icon>
                                    </v-btn>
                                </template>
                                <label class="text-uppercase font-weight-bold primary--text">Actualizar tabla</label>
                            </v-tooltip>
                        </div>
                    </div>
                </template>

                <template v-slot:item.actions="{ item }">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon color="cyan darken-2" v-bind="attrs" v-on="on"
                                @click="consultaComisionistas(item.emplid)">
                                mdi-calendar-account-outline
                            </v-icon>
                        </template>
                        <span>Historial</span>
                    </v-tooltip>
                </template>


                <template v-slot:item.sueldoDiario="{ item }">
                    {{
                        parseFloat(item.sueldoDiario) == 0
                            ? ""
                            : formatMXN(parseFloat(item.sueldoDiario))
                    }}
                </template>
                <template v-slot:item.comisionMes="{ item }">
                    {{
                        parseFloat(item.comisionMes) == 0
                            ? ""
                            : formatMXN(parseFloat(item.comisionMes))
                    }}
                </template>
                <template v-slot:item.totalComisiones="{ item }">
                    {{
                        parseFloat(item.totalComisiones) == 0
                            ? ""
                            : formatMXN(parseFloat(item.totalComisiones))
                    }}
                </template>
                <template v-slot:item.comisionPromedio="{ item }">
                    {{
                        parseFloat(item.comisionPromedio) == 0
                            ? ""
                            : formatMXN(parseFloat(item.comisionPromedio))
                    }}
                </template>
                <template v-slot:item.promedioDiario="{ item }">
                    {{
                        parseFloat(item.promedioDiario) == 0
                            ? ""
                            : formatMXN(parseFloat(item.promedioDiario))
                    }}
                </template>
            </v-data-table>
            <v-dialog v-model="muestraComisionista" content-class="fix-border-dialog" max-width="700" scrollable>
                <v-card class="rounded-xl">
                    <v-card-title class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold">
                        Últimos 12 meses colaborador {{ colaborador }}
                        <v-spacer></v-spacer>
                        <v-btn icon dark @click="muestraComisionista = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-data-table :headers="headersColaborador" dense :items="itemsColaborador" hide-default-footer
                            :items-per-page="12" :loading="loadingColaborador" :footer-props="{
                                'items-per-page-text': 'Elementos por página',
                            }" :header-props="{ 'short-by-text': 'Ordenar por', }"
                            loading-text="Cargando contenido...">
                            <template v-slot:item.sueldoDiario="{ item }">
                                {{
                                    parseFloat(item.sueldoDiario) == 0
                                        ? ""
                                        : formatMXN(parseFloat(item.sueldoDiario))
                                }}
                            </template>
                            <template v-slot:item.comisionMes="{ item }">
                                {{
                                    parseFloat(item.comisionMes) == 0
                                        ? ""
                                        : formatMXN(parseFloat(item.comisionMes))
                                }}
                            </template>
                            <template v-slot:item.totalComisiones="{ item }">
                                {{
                                    parseFloat(item.totalComisiones) == 0
                                        ? ""
                                        : formatMXN(parseFloat(item.totalComisiones))
                                }}
                            </template>
                            <template v-slot:item.comisionPromedio="{ item }">
                                {{
                                    parseFloat(item.comisionPromedio) == 0
                                        ? ""
                                        : formatMXN(parseFloat(item.comisionPromedio))
                                }}
                            </template>
                            <template v-slot:item.promedioDiario="{ item }">
                                {{
                                    parseFloat(item.promedioDiario) == 0
                                        ? ""
                                        : formatMXN(parseFloat(item.promedioDiario))
                                }}
                            </template></v-data-table>
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions class="pa-0">
                        <!-- Add actions here -->
                    </v-card-actions>
                </v-card>
            </v-dialog>
            
            <v-dialog v-model="muestraAgregaExcel" content-class="fix-border-dialog" max-width="700" scrollable
                persistent>
                <v-card class="rounded-xl">
                    <v-card-title class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold">
                        Comisiones mensuales
                        <v-spacer></v-spacer>
                        <v-btn v-show="!mostrarLoading" icon dark @click="muestraAgregaExcel = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="4" md="4">
                                <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-menu ref="menu" v-model="menu" :close-on-content-click="false"
                                            :return-value.sync="date" transition="scale-transition" offset-y
                                            min-width="auto" v-bind="attrs" v-on="on">
                                            <template v-slot:activator="{ on: menuOn, attrs: menuAttrs }">
                                                <v-text-field v-model="date" label="Fecha de corte"
                                                    prepend-icon="mdi-calendar" readonly v-bind="menuAttrs"
                                                    v-on="menuOn"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="date" no-title scrollable>
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="menu = false">
                                                    Cancelar
                                                </v-btn>
                                                <v-btn text color="primary" @click="$refs.menu.save(date)">
                                                    OK
                                                </v-btn>
                                            </v-date-picker>
                                        </v-menu>
                                    </template>
                                    <span>Recuerda seleccionar la fecha del corte</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="12" sm="8" md="8">
                                <v-file-input v-show="!mostrarLoading" accept=".xlsx, .xls, .xlsm, .xlsb, .csv"
                                    placeholder="Comisiones del mes actual" label="Selecciona el excel"
                                    prepend-icon="mdi-microsoft-excel" @change="handleFileUpload"
                                    v-model="file"></v-file-input>
                                <span v-show="mostrarLoading">Procesando e insertando información...</span>
                                <v-progress-linear v-show="mostrarLoading" indeterminate color="blue darken-4"
                                    class="mt-2"></v-progress-linear>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-dialog>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color">
                {{ snackbar.text }}
                <v-btn color="white" text @click="snackbar.visible = false">Cerrar</v-btn>
            </v-snackbar>
        </v-card>
    </v-card>
</template>

<script>
import axios from "axios";
import VueJsonToCsv from 'vue-json-to-csv'

export default {
    name: "muestra-beneficios",
    components: { VueJsonToCsv },
    data() {
        return {
            headers: [
                {
                    text: "ID",
                    value: "id",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Clave",
                    value: "emplid",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Nombre",
                    value: "nombre",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Sueldo Diario",
                    value: "sueldoDiario",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Mes",
                    value: "mes",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Comisión del Mes",
                    value: "comisionMes",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Total de Comisiones",
                    value: "totalComisiones",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Comisión Promedio",
                    value: "comisionPromedio",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Promedio Diario",
                    value: "promedioDiario",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Acciones",
                    value: "actions",
                    sortable: false,
                    align: "center",
                    class:
                        "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
            ],
            comisionistas: [],
            loadingComisiones: true,
            //
            search: "",
            selectedMes: null,
            meses: [
                { id: 1, nombre: 'Enero' },
                { id: 2, nombre: 'Febrero' },
                { id: 3, nombre: 'Marzo' },
                { id: 4, nombre: 'Abril' },
                { id: 5, nombre: 'Mayo' },
                { id: 6, nombre: 'Junio' },
                { id: 7, nombre: 'Julio' },
                { id: 8, nombre: 'Agosto' },
                { id: 9, nombre: 'Septiembre' },
                { id: 10, nombre: 'Octubre' },
                { id: 11, nombre: 'Noviembre' },
                { id: 12, nombre: 'Diciembre' },
            ],
            muestraAgregaComisionista: false,
            muestraAgregaExcel: false,
            beneficioAux: '',
            //snackbar
            snackbar: {
                visible: false,
                text: '',
                color: ''
            },
            //dialog colaborador
            colaborador: null,
            muestraComisionista: false,
            itemsColaborador: [],
            headersColaborador: [
                {
                    text: "ID",
                    value: "id",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Sueldo Diario",
                    value: "sueldoDiario",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Mes",
                    value: "mes",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Comisión del Mes",
                    value: "comisionMes",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Total de Comisiones",
                    value: "totalComisiones",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Comisión Promedio",
                    value: "comisionPromedio",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                },
                {
                    text: "Promedio Diario",
                    value: "promedioDiario",
                    class: "text-uppercase font-weight-bold blue-grey--text text--darken-2",
                }
            ],
            loadingColaborador: true,
            //
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            menu: false,
            archivo: null,
            file: null,
            mostrarLoading: false,
            //Excel
            itemsExcel: [],
            title: 'Reporte de Zentric Comisionistas',
            headers_report_to_export: {
                emplid: { title: "Trabajador" },
                concepto: { title: "Concepto" },
                nomina: { title: "Nomina" },
                fechaCalculo: { title: "Fecha" },
                promedioDiario: { title: "Monto" },
                dato: { title: "Dato" },
                aplicado: { title: "Aplicado" },
            },

        };
    },
    async mounted() {
        const comisionesData = await this.getComisiones();
        this.comisionistas = comisionesData.comisionistas;
        this.itemsExcel = comisionesData.itemsExcel;
    },
    methods: {
        muestraSnackBar(color, texto) {
            this.colorBarra = color;
            this.textoBarra = texto;
            this.muestraBarra = true;
        },
        async getComisiones() {
            this.loadingComisiones = false;
            return new Promise((resolve, reject) => {
                axios
                    .get(`${this.$store.getters.getHostNode}/api/getComisionistas`, {
                        headers: {
                            authorization: `${localStorage.getItem("token")}`,
                        },
                    })
                    .then((response) => {
                        if (response.data.status == "OK") {
                            resolve({
                                comisionistas: response.data.data,
                                itemsExcel: response.data.dataZentric
                            });
                        } else {
                            resolve({
                                comisionistas: [],
                                itemsExcel: []
                            });
                        }
                    })
                    .catch((error) => {
                        reject({
                            error: error,
                        });
                    })
                    .finally(() => {
                        this.loadingComisiones = false;
                    });
            });
        },
        async fetchComisionistas() {
            if (this.selectedMes === null) return;

            this.loadingComisiones = true;

            try {
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/getComisionistasMes`, {
                    params: {
                        mes: this.selectedMes,
                    },
                    headers: {
                        authorization: `${localStorage.getItem("token")}`,
                    },
                });

                if (response.data.status == "OK") {
                    this.itemsExcel = response.data.dataZentric;
                    this.comisionistas = response.data.data;
                } else {
                    this.comisionistas = [];
                    this.itemsExcel = [];
                }
            } catch (error) {
                console.error('Error al obtener los comisionistas:', error);
                this.comisionistas = [];
            } finally {
                this.loadingComisiones = false;
            }
        },
        openComisionistas() {
            this.muestraAgregaComisionista = true;
        },
        async consultaComisionistas(emplid) {
            this.colaborador = emplid;

            if (this.colaborador === null) return;

            try {
                this.muestraComisionista = true;
                this.loadingColaborador = true;
                this.itemsColaborador = [];
                const response = await axios.get(`${this.$store.getters.getHostNode}/api/getComisionistaHistoric`, {
                    params: {
                        colaborador: this.colaborador,
                    },
                    headers: {
                        authorization: `${localStorage.getItem("token")}`,
                    },
                });

                if (response.data.status == "OK") {
                    this.itemsColaborador = response.data.data;
                } else {
                    this.itemsColaborador = [];
                }
            } catch (error) {
                console.error('Error al obtener los comisionistas:', error);
                this.itemsColaborador = [];
            } finally {
                this.loadingColaborador = false;
            }
        },
        async colseAgregaComisionistas(type) {
            if (type == 1) {
                this.loadingComisiones = true;
                this.comisionistas.length = 0;
                const comisionesData = await this.getComisiones();
                this.comisionistas = comisionesData.comisionistas;
                this.itemsExcel = comisionesData.itemsExcel;
                this.selectedMes = null;
            }
        },
        openAgregaBeneficio() {
            this.muestraAgregaExcel = true;
        },
        formatMXN(cadena) {
            return cadena.toLocaleString("es-MX", {
                style: "currency",
                currency: "MXN",
            });
        },

        async handleFileUpload() {
            if (!this.file) return;

            this.mostrarLoading = true;
            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('fechaCorte', this.date);

            try {
                const response = await axios.put(`${this.$store.getters.getHostNode}/api/setComisionistas`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        authorization: `${localStorage.getItem("token")}`,
                    },
                });

                if (response.data.status === 'OK') {
                    this.snackbar = {
                        visible: true,
                        text: 'Archivo procesado correctamente',
                        color: 'success'
                    };
                    this.muestraAgregaExcel = false;
                } else {
                    this.snackbar = {
                        visible: true,
                        text: 'Error al procesar el archivo',
                        color: 'error'
                    };
                }
            } catch (error) {
                this.snackbar = {
                    visible: true,
                    text: 'Error de servidor: ' + error.message,
                    color: 'error'
                };
            } finally {
                this.mostrarLoading = false;
                this.file = null;  // Clear the file input
                this.comisionistas.length = 0;
                const comisionesData = await this.getComisiones();
                this.comisionistas = comisionesData.comisionistas;
                this.itemsExcel = comisionesData.itemsExcel;
            }
        },
        exportToCsv() {
            // Exportar datos a CSV
            if (this.itemsExcel.length > 0) {
                this.$refs.exporter.exportCSV();
            } else {
                alert('No hay datos para exportar');
            }
        },
    },
};
</script>
