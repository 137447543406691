<template>
    <v-card class="rounded-md elevation-3 mt-1 pa-4" color="#ECEFF1" v-if="ruta == 'admon' && !moduloError">
        <v-tabs v-model="tabs">
            <v-tab key="tabulador-registros">
                Tarjetas
            </v-tab>
            <!-- <v-tab key="admin-maquinas">
                Maquinas
            </v-tab> -->
            <v-tab v-show="!perfilMto" key="admin-comunicados">
                Comunicados
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tabs" class="pa-1">
            <v-tab-item>
                <v-card class="rounded-xl elevation-4 mt-1 pa-3">

                    <v-data-table :headers="headers" :items="inconformidades" :search="buscar" :loading="loadingInfo"
                        loading-text="Cargando solicitudes" dense mobile-breakpoint="0" :footer-props="{
                            itemsPerPageText: 'Elementos por página',
                            itemsPerPageAllText: 'Todos',
                            pageText: '{0}-{1} de {2}',
                            noDataText: 'No hay datos disponibles',
                            nextPage: 'Siguiente',
                            prevPage: 'Anterior'
                        }" item-key="id">
                        <template v-slot:top>
                            <div style="display: flex; justify-content: space-between;">
                                <v-text-field v-model="buscar" outlined dense class="rounded-xl"
                                    style="max-width: 350px;" prepend-inner-icon="mdi-table-search" clearable
                                    placeholder="Buscar en tabla..."></v-text-field>

                                <v-spacer></v-spacer>
                                <vue-json-to-csv :json-data="inconformidades" :labels="headers_report_to_export"
                                    csv-title="Reporte de atención a colaboradores" v-show="!perfilMto">
                                    <v-btn color="green darken-2" dark outlined class="font-weight-bold rounded-xl ">
                                        <v-icon left>mdi-microsoft-excel</v-icon>
                                        {{ $t("DH360.export") }}
                                    </v-btn>
                                </vue-json-to-csv>
                                <div>
                                    <!-- <v-btn depressed class="rounded-pill ml-2" color="teal" dark
                                @click="openAgregaBeneficioExcel()">
                                <v-icon left>mdi-microsoft-excel</v-icon>
                                <label class="text-uppercase ">Masiva</label>
                            </v-btn> -->
                                    <v-tooltip bottom color="blue lighten-4">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn outlined color="primary" fab small depressed class="ml-5 mr-3"
                                                v-bind="attrs" v-on="on" @click="refrescar('OK')">
                                                <v-icon>mdi-reload</v-icon>
                                            </v-btn>
                                        </template>
                                        <label class="text-uppercase font-weight-bold primary--text">Actualizar
                                            tabla</label>
                                    </v-tooltip>
                                </div>
                            </div>
                        </template>
                        <template v-slot:item.EMPLID="{ item }">
                            <div v-if="item.anonimo === 1" align="left"> Anónimo</div>
                            <div v-else align="left">{{ item.EMPLID }}</div>
                        </template>
                        <template v-slot:item.nombre="{ item }">
                            <div v-if="item.anonimo === 1" align="left">Anónimo</div>
                            <div v-else align="left">{{ item.nombre }}</div>
                        </template>
                        <template v-slot:item.motivo="{ item }">
                            <div align="left">{{ item.motivo }}</div>
                        </template>
                        <template v-slot:item.departamento="{ item }">
                            <div align="left">{{ item.departamento }}</div>
                        </template>
                        <template v-slot:item.diasTranscurridos="{ item }">
                            <div v-if="item.diasTranscurridos > 1">
                                {{ item.diasTranscurridos }} Días
                            </div>
                            <div v-else-if="item.diasTranscurridos == 1">
                                {{ item.diasTranscurridos }} Día
                            </div>
                            <div v-else-if="item.diasTranscurridos == 0">0 Días</div>
                        </template>
                        <template v-slot:item.estatus="{ item }">
                            <div v-if="item.estatus == 1" class="d-flex justify-center">
                                <v-chip-group column>
                                    <v-btn x-small rounded elevation="1" color="red" dark>
                                        Abierta
                                    </v-btn>
                                </v-chip-group>
                            </div>
                            <div v-else-if="item.estatus == 2" class="d-flex justify-center">
                                <v-chip-group column>
                                    <v-btn x-small rounded elevation="1" color="lime darken-1" dark>
                                        Contactando
                                    </v-btn>
                                </v-chip-group>
                            </div>
                            <div v-else-if="item.estatus == 3" class="d-flex justify-center">
                                <v-chip-group column>
                                    <v-btn x-small rounded elevation="1" color="teal darken-1" dark>
                                        Cerrada
                                    </v-btn>
                                </v-chip-group>
                            </div>
                            <div v-else-if="item.estatus == 4" class="d-flex justify-center">
                                <v-chip-group column>
                                    <v-btn x-small rounded elevation="1" color="light-green darken-1" dark>
                                        Revisión
                                    </v-btn>
                                </v-chip-group>
                            </div>
                            <div v-else-if="item.estatus == 5" class="d-flex justify-center">
                                <v-chip-group column>
                                    <v-btn x-small rounded elevation="1" color="blue darken-1" dark>
                                        Reparación
                                    </v-btn>
                                </v-chip-group>
                            </div>
                        </template>
                        <template v-slot:item.tipoTarjeta="{ item }">
                            <div v-if="item.tipoTarjeta == 'Preventiva'" class="d-flex justify-center">
                                <v-tooltip top color="indigo darken-1">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="yellow darken-1" v-bind="attrs" v-on="on">
                                            mdi-sim-alert
                                        </v-icon>
                                    </template>
                                    <label class="text-uppercase font-weight-bold white--text">Tarjeta
                                        Preventiva</label>
                                </v-tooltip>
                            </div>
                            <div v-else-if="item.tipoTarjeta == 'Bloqueo'" class="d-flex justify-center">
                                <v-tooltip top color="indigo darken-1">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon color="pink darken-1" v-bind="attrs" v-on="on">
                                            mdi-sim-alert
                                        </v-icon>
                                    </template>
                                    <label class="text-uppercase font-weight-bold white--text">Tarjeta
                                        Bloqueo</label>
                                </v-tooltip>
                            </div>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-tooltip top color="indigo darken-1">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="indigo darken-4" v-bind="attrs" v-on="on"
                                        @click="dialogDetalles(item)">
                                        mdi-details
                                    </v-icon>
                                </template>
                                <label class="text-uppercase font-weight-bold white--text">Detalles</label>
                            </v-tooltip>
                            <v-tooltip top color="indigo darken-1">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="indigo darken-4" v-bind="attrs" v-on="on" class="mx-1"
                                        @click="dialogRevision(item)">
                                        mdi-eye-check-outline
                                    </v-icon>
                                </template>
                                <label class="text-uppercase font-weight-bold white--text">Revision</label>
                            </v-tooltip>
                            <v-tooltip top color="indigo darken-1">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="indigo darken-4" v-bind="attrs" v-on="on" class="mx-1"
                                        @click="dialogReparacion(item)">
                                        mdi-tools
                                    </v-icon>
                                </template>
                                <label class="text-uppercase font-weight-bold white--text">Reparacion</label>
                            </v-tooltip>
                            <v-tooltip top color="indigo darken-1" v-if="!perfilMto">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon color="indigo darken-4" v-bind="attrs" v-on="on"
                                        @click="dialogCierre(item)">
                                        mdi-clipboard-check-outline
                                    </v-icon>
                                </template>
                                <label class="text-uppercase font-weight-bold white--text">Cierre</label>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-card>
                <v-dialog v-model="openDetalles" max-width="80%" transition="dialog-top-transition" persistent
                    scrollable>
                    <v-card class="rounded-xl">
                        <v-card-title class="text-subtitle-2 primary white--text">
                            Detalles de solicitud
                            <v-spacer></v-spacer>
                            <v-icon small dark @click="openDetalles = false">mdi-close</v-icon>
                        </v-card-title>
                        <v-card-text class="pt-5">
                            <v-stepper v-model="e6" vertical>
                                <v-stepper-step :complete="e6 > 1" step="1" editable>
                                    Detalles de solicitud
                                    <small>Consulta para despues responder</small>
                                </v-stepper-step>

                                <v-stepper-content step="1">
                                    <v-card class="pa-5 mb-5 elevation-2">
                                        <v-row>
                                            <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10">
                                                <div v-if="item.EMPLID !== -1">
                                                    <label class="font-weight-bold">Colaborador:</label>
                                                    {{ item.EMPLID }}
                                                </div>
                                                <div>
                                                    <label class="font-weight-bold">Nombre:</label>
                                                    {{ item.nombre }}
                                                </div>
                                                <div>
                                                    <label class="font-weight-bold">Motivo:</label>
                                                    {{ item.motivo }}
                                                </div>
                                                <div>
                                                    <label class="font-weight-bold">Planta:</label>
                                                    {{ item.idPlanta }}
                                                </div>
                                                <div>
                                                    <label class="font-weight-bold">Maquina:</label>
                                                    {{ item.maquina }}
                                                </div>
                                                <div>
                                                    <label class="font-weight-bold">Condiciones:</label>
                                                    {{ item.reporteTipo }}
                                                </div>
                                                <div>
                                                    <label class="font-weight-bold">Fecha solicitud:</label>
                                                    {{ item.fechaInconformidad }}
                                                </div>
                                            </v-col>
                                            <v-col class="d-flex align-center justify-center" cols="12" xs="12" sm="12"
                                                md="2" lg="2" xl="2">
                                                <div>
                                                    <v-tooltip top color="blue lighten-3">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn color="blue" dark fab
                                                                @click="openEvidencia(item, 'evidencia')" v-bind="attrs"
                                                                v-on="on">
                                                                <v-icon small>mdi-image-search-outline</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span class="white---text"> Ver evidencia </span>
                                                    </v-tooltip>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                    <v-card class="pa-5 mb-5">
                                        <v-row>
                                            <v-col cols="12" xs="12" sm="12" md="10" lg="10" xl="10"
                                                class="text-justify">
                                                <div>
                                                    <label class="font-weight-bold blue--text">Comentario:</label>
                                                    {{ item.comentario }}
                                                </div>
                                                <div v-if="item.estatus > 1">
                                                    <label class="font-weight-bold green--text">Respuesta:</label>
                                                    {{ item.respuestaAdmon }}
                                                </div>
                                            </v-col>
                                            <v-col class="d-flex align-center justify-center" cols="12" xs="12" sm="12"
                                                md="2" lg="2" xl="2">
                                                <div v-if="item.estatus > 1">
                                                    <v-tooltip top color="green lighten-3">
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn color="green" dark fab
                                                                @click="openEvidencia(item, 'respuesta')" v-bind="attrs"
                                                                v-on="on">
                                                                <v-icon small>mdi-image-search-outline</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <span class="white---text">
                                                            Ver evidencia respuesta
                                                        </span>
                                                    </v-tooltip>
                                                </div>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                </v-stepper-content>

                                <v-stepper-step :complete="e6 > 2" step="2" editable
                                    v-if="item.estatus == 1 && ruta == 'admon' && !perfilMto">
                                    Atender solicitud
                                </v-stepper-step>

                                <v-stepper-content step="2" v-if="item.estatus == 1 && ruta == 'admon'">
                                    <v-card class="mb-5 py-5">
                                        <v-row>
                                            <v-col cols="12" xs="12" sm="12" md="12" lg="12" xl="12">
                                                <v-textarea v-model="form.respuesta"
                                                    label="Escriba su respuesta a la solicitud" maxlength="450"
                                                    :rules="nameRules" row-height="3" type="text" required auto-grow
                                                    outlined rows="1" counter></v-textarea>
                                            </v-col>
                                            <v-col class="pr-0" cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                                                <div class="pl-5">
                                                    <div v-if="form.procede == true">¿Procede? Si</div>
                                                    <div v-else>¿Procede? No</div>
                                                    <v-switch v-model="form.procede" color="primary"
                                                        hide-details></v-switch>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" xs="12" sm="9" md="9" lg="9" xl="9">
                                                <div class="pl-5">
                                                    <div>
                                                        ¿Desea complementar su respuesta con una captura/imagen?
                                                    </div>
                                                    <v-expand-transition transition="slide-y-transition">
                                                        <div v-show="expand">
                                                            <v-avatar size="50" rounded>
                                                                <img :src="vistapreviaimg" />
                                                            </v-avatar>
                                                            <v-avatar size="50" rounded>
                                                                <img :src="vistapreviaimg1" />
                                                            </v-avatar>
                                                            <v-avatar size="50" rounded>
                                                                <img :src="vistapreviaimg2" />
                                                            </v-avatar>
                                                            <v-avatar size="50" rounded>
                                                                <img :src="vistapreviaimg3" />
                                                            </v-avatar>
                                                            <v-btn v-if="fileBase64.length < 4" class="mx-2" fab dark
                                                                small color="teal" @click="openFileInput">
                                                                <v-icon dark> mdi-plus </v-icon>
                                                            </v-btn>
                                                        </div>
                                                    </v-expand-transition>
                                                    <v-btn v-if="!expand" class="rounded-lg" small depressed dark
                                                        outlined color="teal" @click="expand = !expand">
                                                        Cargar evidencia
                                                    </v-btn>
                                                </div>
                                            </v-col>
                                            <v-col cols="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                                <v-file-input v-model="evidenciaInconformidad" ref="fileInput"
                                                    v-on:change="cargarImg" placeholder="Ahora puedes agregar evidencia"
                                                    show-size accept="image/*" class="rounded-lg" style="display: none"
                                                    outlined dense></v-file-input>
                                            </v-col>
                                        </v-row>
                                    </v-card>
                                    <div class="d-flex justify-end">
                                        <v-btn v-if="form.respuesta" :loading="loading" color="success" outlined small
                                            @click="guardar">
                                            Guardar
                                        </v-btn>
                                        <v-btn v-else disabled @click="guardar"> Guardar </v-btn>
                                    </div>
                                </v-stepper-content>
                            </v-stepper>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="openReparacion" max-width="80%" transition="dialog-top-transition" persistent
                    scrollable>
                    <v-card class="rounded-xl">
                        <v-card-title class="text-subtitle-2 primary white--text">
                            Reparación de solicitud de tarjeta
                            <v-spacer></v-spacer>
                            <v-icon small dark @click="openReparacion = false; e6 = 1;">mdi-close</v-icon>
                        </v-card-title>
                        <v-card-text class="pt-5">
                            <v-form ref="formReparacion" v-model="valid">
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-textarea v-model="reparacion.causaRaiz" label="Causa raíz" auto-grow outlined
                                            rows="1" :rules="[rules.required]"
                                            :readonly="reparacion.estatus === 3"></v-textarea>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-textarea v-model="reparacion.accionesCorrectivas"
                                            label="Acciones correctivas a tomar" auto-grow outlined rows="1"
                                            :rules="[rules.required]" :readonly="reparacion.estatus === 3"></v-textarea>
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-text-field v-if="reparacion.estatus === 3"
                                            v-model="reparacion.fechaCompromisoAccionesCorrectivas"
                                            label="Fecha compromiso" prepend-icon="mdi-calendar"
                                            readonly></v-text-field>
                                        <v-menu v-else v-model="menu" :close-on-content-click="false" :nudge-right="40"
                                            transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="reparacion.fechaCompromisoAccionesCorrectivas"
                                                    label="Fecha compromiso" prepend-icon="mdi-calendar" readonly
                                                    v-bind="attrs" v-on="on" :rules="[rules.required]"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="reparacion.fechaCompromisoAccionesCorrectivas"
                                                @input="menu = false" locale="es-mx"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="12" md="8">
                                        <v-autocomplete
                                            v-if="!reparacion.responsableAccionesCorrectivas && reparacion.estatus !== 3"
                                            v-model="responsableAccionesCorrectivas" chips deletable-chips multiple
                                            small-chips :items="colaboradores" item-text="nombreMostrar"
                                            label="Responsables" no-data-text="Sin coincidencias"
                                            :rules="[rules.required]"></v-autocomplete>
                                        <v-textarea v-else v-model="reparacion.responsableAccionesCorrectivas"
                                            label="Responsables" readonly auto-grow outlined rows="1"
                                            :rules="[rules.required]"></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :loading="loadingReparacion" :disabled="!valid" color="success" outlined rounded
                                class="mt-4" @click="enviarReparacion">
                                Guardar
                            </v-btn>
                            <v-btn v-if="!perfilMto" :loading="loadingReparacionCorreo" color="cyan darken-1" outlined
                                rounded class="mt-4" @click="enviarReparacionCorreo">
                                <v-icon left>
                                    mdi-email-fast-outline
                                </v-icon> Recordatorio
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="openRevision" max-width="80%" transition="dialog-top-transition" persistent
                    scrollable>
                    <v-card class="rounded-xl">
                        <v-card-title class="text-subtitle-2 primary white--text">
                            Revisión de solicitud de tarjeta
                            <v-spacer></v-spacer>
                            <v-icon small dark @click="openRevision = false; e6 = 1;">mdi-close</v-icon>
                        </v-card-title>
                        <v-card-text class="pt-5">
                            <v-form ref="formRevision" v-model="valid">
                                <v-row>
                                    <v-col cols="12" md="6">
                                        <v-text-field v-if="revision.estatus === 3" v-model="revision.fechaBloqueo"
                                            label="Fecha de bloqueo" prepend-icon="mdi-calendar"
                                            readonly></v-text-field>
                                        <v-menu v-else v-model="menuFechaBloqueo" :close-on-content-click="false"
                                            :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="revision.fechaBloqueo" label="Fecha de bloqueo"
                                                    prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"
                                                    :rules="[rules.required]"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="revision.fechaBloqueo"
                                                @input="menuFechaBloqueo = false" locale="es-mx"></v-date-picker>
                                        </v-menu>

                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-textarea v-model="revision.justificacion" label="Justificación" auto-grow
                                            rows="1" :rules="[rules.required]"
                                            :readonly="revision.estatus === 3"></v-textarea>
                                    </v-col>
                                    <v-col cols="12" md="6">
                                        <v-textarea v-model="revision.accionContencion" label="Acción de contención"
                                            auto-grow rows="1" :rules="[rules.required]"
                                            :readonly="revision.estatus === 3"></v-textarea>
                                    </v-col>
                                    <v-col cols="12" md="6"
                                        v-if="revision.estatus === 3 || revision.estatus === 4 || revision.estatus === 5">
                                        <v-text-field v-model="revision.fechaAccionContencion"
                                            label="Fecha de contención" prepend-icon="mdi-calendar"
                                            readonly></v-text-field>
                                        <!-- <v-menu v-else v-model="menuFechaContencion" :close-on-content-click="false"
                                            :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="revision.fechaAccionContencion"
                                                    label="Fecha de contención" prepend-icon="mdi-calendar" readonly
                                                    v-bind="attrs" v-on="on" :rules="[rules.required]"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="revision.fechaAccionContencion"
                                                @input="menuFechaContencion = false" locale="es-mx"></v-date-picker>
                                        </v-menu>-->
                                    </v-col>
                                    <v-col cols="12" md="4">
                                        <v-select v-if="!revision.tipoTarjeta && revision.estatus !== 3"
                                            :items="itemsTarjetas" label="Tipo tarjeta" v-model="revision.tipoTarjeta"
                                            :rules="[rules.required]"></v-select>
                                        <v-textarea v-else v-model="revision.tipoTarjeta" label="Tipo tarjeta" readonly
                                            auto-grow rows="1" :rules="[rules.required]"></v-textarea>
                                    </v-col>
                                    <v-col cols="12" md="8">
                                        <v-autocomplete
                                            v-if="!revision.responsableAccionContencion && revision.estatus !== 3"
                                            v-model="responsableAccionContencion" chips deletable-chips multiple
                                            small-chips label="Responsables" no-data-text="Sin coincidencias"
                                            :items="colaboradores" item-text="nombreMostrar"
                                            :rules="[rules.required]"></v-autocomplete>
                                        <v-textarea v-else v-model="revision.responsableAccionContencion"
                                            label="Responsables" readonly auto-grow rows="1"
                                            :rules="[rules.required]"></v-textarea>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                        <v-card-actions v-if="!perfilMto">
                            <v-spacer></v-spacer>
                            <v-btn :loading="loadingRevision" :disabled="!valid" color="success" outlined rounded
                                class="mt-4" @click="enviarRevision">
                                Guardar
                            </v-btn>
                            <v-btn :loading="loadingRevisionCorreo" color="cyan darken-1" outlined rounded class="mt-4"
                                @click="enviarRevisionCorreo">
                                <v-icon left>
                                    mdi-email-fast-outline
                                </v-icon> Recordatorio
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="openCierre" max-width="80%" transition="dialog-top-transition" persistent scrollable>
                    <v-card class="rounded-xl">
                        <v-card-title class="text-subtitle-2 primary white--text">
                            Cierre de reporte de tarjeta
                            <v-spacer></v-spacer>
                            <v-icon small dark @click="openCierre = false; e6 = 1;">mdi-close</v-icon>
                        </v-card-title>
                        <v-card-text class="pt-5">
                            <v-form ref="formCierre" v-model="valid">
                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-if="cerrar.estatus === 3"
                                            v-model="cerrar.fechaRealAccionesCorrectivas" label="Fecha real acciones"
                                            prepend-icon="mdi-calendar" readonly></v-text-field>
                                        <v-menu v-else v-model="menuFechaRealAcciones" :close-on-content-click="false"
                                            :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field v-model="cerrar.fechaRealAccionesCorrectivas"
                                                    label="Fecha real acciones" prepend-icon="mdi-calendar" readonly
                                                    v-bind="attrs" v-on="on" :rules="[rules.required]"></v-text-field>
                                            </template>
                                            <v-date-picker v-model="cerrar.fechaRealAccionesCorrectivas"
                                                @input="menuFechaRealAcciones = false" locale="es-mx"></v-date-picker>
                                        </v-menu>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-textarea v-model="cerrar.comentariosAdicionales"
                                            label="Comentarios adicionales" auto-grow outlined rows="1"
                                            :rules="[rules.required]" :readonly="cerrar.estatus === 3"></v-textarea>
                                    </v-col>
                                </v-row>

                            </v-form>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn :loading="loadingCierre" :disabled="!valid" color="primary" outlined rounded
                                class="mt-4" @click="enviarCierre">
                                Enviar
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <v-dialog v-model="muestraAgregaExcel" content-class="fix-border-dialog" max-width="700" scrollable
                    persistent>
                    <v-card class="rounded-xl">
                        <v-card-title class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold">
                            carga masiva de tarjetas de bloqueo y preventivas
                            <v-spacer></v-spacer>
                            <v-btn v-show="!mostrarLoading" icon dark @click="muestraAgregaExcel = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text>
                            <v-row>
                                <v-col cols="12" sm="8" md="8">
                                    <v-file-input v-show="!mostrarLoading" accept=".xlsx, .xls, .xlsm, .xlsb, .csv"
                                        placeholder="Comisiones del mes actual" label="Selecciona el excel"
                                        prepend-icon="mdi-microsoft-excel" @change="handleFileUpload"
                                        v-model="file"></v-file-input>
                                    <span v-show="mostrarLoading">Procesando e insertando información...</span>
                                    <v-progress-linear v-show="mostrarLoading" indeterminate color="blue darken-4"
                                        class="mt-2"></v-progress-linear>
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="pa-0">
                            <!-- Add actions here -->
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <SnackBar v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra" :color="colorBarra"
                    @cierraBarra="muestraBarra = false" />
                <evaluacion-respuesta-aclaracion v-if="evaluarRespuestaAclaracion" :dialog="evaluarRespuestaAclaracion"
                    :IDAclaracion="this.ID" ruta="inconformidad" @closed="refrescar"></evaluacion-respuesta-aclaracion>
                <inconformidades-evidencia v-if="verEvidencia" :dialog="verEvidencia" :item="item"
                    :tipoEvidencia="tipoEvidencia" @cierraDialogEvidencia="cerrarDialog"></inconformidades-evidencia>
                <mi-nomina-form-inconformidades v-if="muestraFormInconformidades" :dialog="muestraFormInconformidades"
                    :item="itemForm"
                    @cierraFormInconformidades="closeFormInconformidades"></mi-nomina-form-inconformidades>

            </v-tab-item>
            <v-tab-item>
                <AdminComunicados />
            </v-tab-item>
            <v-tab-item>
                <AdmiMaquinas />
            </v-tab-item>
        </v-tabs-items>
    </v-card>
    <v-container v-else-if="moduloError">
        <mantenimiento-modulo></mantenimiento-modulo>
    </v-container>
</template>

<script>
import axios from "axios";
import VueJsonToCsv from "vue-json-to-csv";
import SnackBar from "../../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import { deleteToken } from "../../store/Funciones/funciones";
import EvaluacionRespuestaAclaracion from "../../views/generated/Nominas/Aclaraciones/components/EvaluacionRespuestaAclaracion.vue";
import InconformidadesEvidencia from "../componentsInconformidades/InconformidadesEvidencia.vue";
import MiNominaFormInconformidades from "./MiNominaFormInconformidades.vue";
import MantenimientoModulo from '../MantenimientoModulo.vue';
import AdmiMaquinas from '../../views/generated/AdmGeneral/AdmonTarjetasMFA/componentes/AdminMaquinas.vue';
import AdminComunicados from '../../views/generated/AdmGeneral/AdmonTarjetasMFA/componentes/AdminComunicados.vue';

export default {
    name: "reporte-tarjetas",
    components: {
        SnackBar,
        EvaluacionRespuestaAclaracion,
        VueJsonToCsv,
        InconformidadesEvidencia,
        MiNominaFormInconformidades,
        MantenimientoModulo,
        AdmiMaquinas,
        AdminComunicados
    },
    props: ["dialog", "ruta"],
    data: () => ({
        textoBarra: "",
        colorBarra: "",
        muestraBarra: false,

        headers: [
            {
                text: "Folio",
                value: "id",
                align: "center",
                sortable: true
            },
            {
                text: "N.P.",
                value: "EMPLID",
                align: "center",
                sortable: true
            },
            {
                text: "Nombre",
                value: "nombre",
                align: "center",
                sortable: true
            },
            {
                text: "fecha Registro",
                value: "fechaRegistro",
                align: "center",
                sortable: true,
            },
            {
                text: "Dias transcurridos",
                value: "diasTranscurridos",
                align: "center",
                sortable: true,
            },
            {
                text: "Estatus",
                value: "estatus",
                align: "center",
            },
            {
                text: "Tarjeta",
                value: "tipoTarjeta",
                align: "center",
            },
            {
                text: "Detalles",
                value: "actions",
                align: "center",
            },
        ],
        inconformidades: [],
        inconformidadesData: [],
        headers_report_to_export: {
            EMPLID: { title: "N.P." },
            nombre: { title: "Nombre" },
            DeptoColaborador: { title: "Departamento de colaborador" },
            motivo: { title: "Motivo" },
            departamento: { title: "Departamento de inconformidad" },
            comentario: { title: "Comentario" },
            fechaInconformidad: { title: "Fecha Inconformidad" },
            fechaRegistro: { title: "Fecha Registro" },
            diasTranscurridos: { title: "Días transcurridos" },
            fechaRespuesta: { title: "Fecha de respuesta" },
            emplidRespuesta: { title: "N.P. de quien responde" },
            nameEmplidRespuesta: { title: "Nombre de quien responde" },
            DeptoResponde: { title: "Departamento de quien responde" },
            nivelSatisfaccion: { title: "Nivel de satisfacción" },
        },
        buscar: "",
        loadingInfo: true,
        loading: false,
        authFilterValue: null,
        openDetalles: false,
        item: {},
        itemForm: "",
        e6: 1,
        form: {
            respuesta: "",
            procede: false,
        },
        nameRules: [
            (v) => !!v || "Comentario es requerido",
            (v) =>
                v.length < 450 || "Llegaste al limite de caracteres del comentario",
        ],
        evaluarRespuestaAclaracion: false,
        loadingReporte: true,
        verEvidencia: false,
        tipoEvidencia: "",

        vistapreviaimg: "",
        vistapreviaimg1: "",
        vistapreviaimg2: "",
        vistapreviaimg3: "",
        expand: false,
        fileBase64: [],
        evidenciaInconformidad: null,
        nombresFotosEvidencia: [],
        muestraFormInconformidades: false,
        moduloError: false,
        //
        muestraAgregaExcel: false,
        archivo: null,
        file: null,
        mostrarLoading: false,
        openCierre: false,
        openRevision: false,
        openReparacion: false,
        menu: false,
        revision: {
            fechaBloqueo: '',
            justificacion: '',
            accionContencion: '',
            fechaAccionContencion: '',
            responsableAccionContencion: null,
        },
        responsableAccionContencion: '',
        menuFechaBloqueo: false,
        menuFechaContencion: false,
        loadingRevision: false,
        loadingRevisionCorreo: false,
        itemsTarjetas: [
            "Preventiva",
            "Bloqueo"
        ],
        //
        reparacion: {
            causaRaiz: '',
            accionesCorrectivas: '',
            fechaCompromisoAccionesCorrectivas: '',
            responsableAccionesCorrectivas: null,
        },
        valid: false,
        responsableAccionesCorrectivas: '',
        loadingReparacionCorreo: false,

        rules: {
            required: v => !!v || 'Este campo es obligatorio',
        },
        loadingReparacion: false,
        //
        menuFechaRealAcciones: false,
        cerrar: {
            fechaRealAccionesCorrectivas: '',
            comentariosAdicionales: '',
        },
        loadingCierre: false,
        //
        colaboradores: [],
        //
        tabs: 0,
        //
        perfilAdmin: false,
        perfilMto: true,
        perfilGeneral: false,
    }),
    watch: {

    },
    mounted() {
        this.getInconformidades();
        this.saveMonitoreo();
        this.getColaboradores();
        this.vistapreviaimg = require("@/assets/img/img-icon.png");
        this.vistapreviaimg1 = require("@/assets/img/img-icon.png");
        this.vistapreviaimg2 = require("@/assets/img/img-icon.png");
        this.vistapreviaimg3 = require("@/assets/img/img-icon.png");
    },
    methods: {
        saveMonitoreo() {
            let params = {
                Opcion: 10,
                pantalla: "Reporte inconformidades tarjetas",
                token: localStorage.getItem("token"),
            };
            axios
                .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
                .then((response) => {
                    console.log(response.data);
                });
        },
        refrescar(response) {
            if (response == "OK") {
                this.inconformidades = [];
                this.loadingInfo = true;
                this.getInconformidades();
            }
            this.evaluarRespuestaAclaracion = false;
        },
        openEvaluacionDialog(item) {
            this.evaluarRespuestaAclaracion = true;
            this.ID = item.id;
        },
        guardar() {
            let params = {
                Opcion: 7,
                token: localStorage.getItem("token"),
                item: this.item,
                form: this.form,
                evidencia: this.fileBase64,
                evidencia2: [
                    this.vistapreviaimg,
                    this.vistapreviaimg1,
                    this.vistapreviaimg2,
                    this.vistapreviaimg3,
                ],
                filename: this.nombresFotosEvidencia,
            };
            this.loading = true;
            axios
                .post(
                    `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
                    params
                )
                .then((response) => {
                    this.loading = false;
                    if (response.data.status == "OK") {
                        if (response.data.notificar > 0) {
                            this.textoBarra = "Respuesta enviada a colaborador.";
                        } else {
                            this.textoBarra =
                                "Respuesta enviada. Falló el envío de notificación a su portal. Favor de notificar a TI.";
                        }
                        this.colorBarra = "success";
                        this.muestraBarra = true;
                        this.openDetalles = false;
                        this.form["respuesta"] = "";
                        this.form["procede"] = false;
                        this.inconformidades = [];
                        this.loadingInfo = true;
                        this.getInconformidades();
                    } else if (response.data.status == "ERROR") {
                        this.textoBarra = "No se pudo enviar la respuesta.";
                        this.colorBarra = "primary";
                        this.muestraBarra = true;
                    } else if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    }
                });
        },
        getInconformidades() {
            this.loadingInfo = true;
            this.loadingReporte = true;
            let params = {
                Opcion: 11,
                ruta: this.ruta,
                token: localStorage.getItem("token"),
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
                    params
                )
                .then((response) => {
                    this.loadingReporte = false;
                    this.loadingInfo = false;
                    if (response.data.status == "OK") {
                        this.inconformidadesData = response.data.inconformidad;
                        this.getPuesto();
                    } else if (response.data.status == "VACIO") {
                        this.textoBarra = "Sin registros hasta el momento.";
                        this.colorBarra = "primary";
                        this.muestraBarra = true;
                    } else if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else if (response.data.status == "ERROR" || !response.data.status) {
                        this.moduloError = true;
                    }
                });
        },
        dialogDetalles(item) {
            this.item = item;
            this.openDetalles = true;
        },
        dialogRevision(item) {
            this.revision = {};
            this.responsableAccionContencion = '';
            this.revision = {
                idTarjeta: item.id,
                estatus: item.estatus,
                fechaBloqueo: item.fechaBloqueo,
                justificacion: item.justificacion,
                accionContencion: item.accionContencion,
                fechaAccionContencion: item.fechaAccionContencion,
                responsableAccionContencion: item.responsableAccionContencion,
                tipoTarjeta: item.tipoTarjeta
            };
            this.openRevision = true;
        },
        dialogCierre(item) {
            this.cerrar = {};
            this.cerrar = {
                idTarjeta: item.id,
                estatus: item.estatus,
                fechaRealAccionesCorrectivas: item.fechaRealAccionesCorrectivas,
                comentariosAdicionales: item.comentariosAdicionales
            };
            this.openCierre = true;
        },
        dialogReparacion(item) {
            this.reparacion = {};
            this.responsableAccionesCorrectivas = '';
            console.log(item);
            this.reparacion = {
                idTarjeta: item.id,
                causaRaiz: item.causaRaiz,
                estatus: item.estatus,
                accionesCorrectivas: item.accionesCorrectivas,
                fechaCompromisoAccionesCorrectivas: item.fechaCompromisoAccionesCorrectivas,
                responsableAccionesCorrectivas: item.responsableAccionesCorrectivas,
                fechaBloqueo: item.fechaBloqueo
            };
            this.openReparacion = true;
        },
        openEvidencia(item, tipoEvidencia) {
            this.item = item;
            this.verEvidencia = true;
            this.tipoEvidencia = tipoEvidencia;
        },
        cerrarDialog(response) {
            console.log(response);
            this.verEvidencia = false;
        },
        openFileInput() {
            // Obtener la referencia al componente v-file-input
            const fileInput = this.$refs.fileInput;

            // Activar el método click() del componente v-file-input para abrir el selector de archivos
            fileInput.$el.querySelector('input[type="file"]').click();
        },
        cargarImg() {
            if (this.evidenciaInconformidad) {
                let cont = this.fileBase64.length;
                this.nombresFotosEvidencia.push(this.evidenciaInconformidad.name);
                this.fileBase64.push(
                    this.readFileAsBase64(this.evidenciaInconformidad, cont)
                );
            }
        },
        async readFileAsBase64(file, cont) {
            return new Promise((resolve, reject) => {
                if (!file) {
                    reject(new Error("No file provided."));
                    return;
                }

                const reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onload = () => {
                    if (cont == 0) {
                        this.vistapreviaimg = reader.result;
                    } else if (cont == 1) {
                        this.vistapreviaimg1 = reader.result;
                    } else if (cont == 2) {
                        this.vistapreviaimg2 = reader.result;
                    } else if (cont == 3) {
                        this.vistapreviaimg3 = reader.result;
                    }
                    resolve(reader.result);
                };

                reader.onerror = (error) => {
                    reject(error);
                };
            });
        },
        openForm(item) {
            this.itemForm = {
                id: item.id,
                motivo: {
                    id: item.idmotivo,
                    motivo: item.motivo,
                    inicial: item.iniciales,
                    departamento: "[" + item.DEPTID + "] " + item.departamento,
                },
                comentario: item.comentario,
                fechaInconformidad: item.fechaRegistroForm,
                EMPLID: item.EMPLID,
            };
            this.muestraFormInconformidades = true;
        },
        closeFormInconformidades(response) {
            this.muestraFormInconformidades = false;
            if (response == "OK") {
                this.openDetalles = false;
                this.inconformidades = [];
                this.loadingInfo = true;
                this.getInconformidades();
            }
        },
        openAgregaBeneficioExcel() {
            this.muestraAgregaExcel = true;
        },
        async handleFileUpload() {
            if (!this.file) return;

            this.mostrarLoading = true;
            const formData = new FormData();
            formData.append('file', this.file);
            formData.append('fechaCorte', this.date);

            try {
                const response = await axios.put(`${this.$store.getters.getHostNode}/api/setMasivoTarjetasMFA`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        authorization: `${localStorage.getItem("token")}`,
                    },
                });

                if (response.data.status === 'OK') {
                    this.snackbar = {
                        visible: true,
                        text: 'Archivo procesado correctamente',
                        color: 'success'
                    };
                    this.muestraAgregaExcel = false;
                } else {
                    this.snackbar = {
                        visible: true,
                        text: 'Error al procesar el archivo',
                        color: 'error'
                    };
                }
            } catch (error) {
                this.snackbar = {
                    visible: true,
                    text: 'Error de servidor: ' + error.message,
                    color: 'error'
                };
            } finally {
                this.mostrarLoading = false;
                this.file = null;  // Clear the file input
                this.comisionistas.length = 0;
            }
        },
        //
        enviarReparacion() {
            if (this.$refs.formReparacion.validate()) {
                this.loadingReparacion = true;
                if (this.reparacion.responsableAccionesCorrectivas === null) {
                    this.reparacion.responsableAccionesCorrectivas = this.responsableAccionesCorrectivas;
                }
                let params = {
                    Opcion: 12,
                    reporte: this.reparacion,
                    token: localStorage.getItem("token"),
                };
                axios
                    .post(
                        `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
                        params
                    )
                    .then((response) => {
                        if (response.data.status == "OK") {
                            this.getInconformidades();
                            this.openReparacion = false;
                            this.textoBarra = "Registro actualizado correctamente.";
                            this.colorBarra = "success";
                            this.muestraBarra = true;
                        } else if (response.data.status == "EXPRESSION") {
                            deleteToken();
                        } else if (response.data.status == "ERROR" || !response.data.status) {
                            this.textoBarra = "Error de al actualizar. Intenta nuevamente.";
                            this.colorBarra = "error";
                            this.muestraBarra = true;
                        }
                    }).catch((error) => {
                        console.error("Error al obtener los datos:", error);
                        this.textoBarra = "Error de conexión. Intenta nuevamente.";
                        this.colorBarra = "error";
                        this.muestraBarra = true;
                    })
                    .finally(() => {
                        this.loadingReparacion = false;
                    });
            }
        },
        enviarRevision() {
            if (this.$refs.formRevision.validate()) {
                this.loadingRevision = true;
                if (this.revision.responsableAccionContencion === null) {
                    this.revision.responsableAccionContencion = this.responsableAccionContencion;
                }
                let params = {
                    Opcion: 13,
                    reporte: this.revision,
                    token: localStorage.getItem("token"),
                };
                axios
                    .post(
                        `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
                        params
                    )
                    .then((response) => {
                        if (response.data.status == "OK") {
                            this.getInconformidades();
                            this.openRevision = false;
                            this.textoBarra = "Registro actualizado correctamente.";
                            this.colorBarra = "success";
                            this.muestraBarra = true;
                        } else if (response.data.status == "EXPRESSION") {
                            deleteToken();
                        } else if (response.data.status == "ERROR" || !response.data.status) {
                            this.textoBarra = "Error de al actualizar. Intenta nuevamente.";
                            this.colorBarra = "error";
                            this.muestraBarra = true;
                        }
                    }).catch((error) => {
                        console.error("Error al obtener los datos:", error);
                        this.textoBarra = "Error de conexión. Intenta nuevamente.";
                        this.colorBarra = "error";
                        this.muestraBarra = true;
                    })
                    .finally(() => {
                        this.loadingRevision = false;
                    });
            }
        },
        enviarCierre() {
            if (this.$refs.formCierre.validate()) {
                this.loadingCierre = true;
                let params = {
                    Opcion: 14,
                    reporte: this.cerrar,
                    token: localStorage.getItem("token"),
                };
                axios
                    .post(
                        `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
                        params
                    )
                    .then((response) => {
                        if (response.data.status == "OK") {
                            this.getInconformidades();
                            this.openCierre = false;
                            this.textoBarra = "Registro actualizado correctamente.";
                            this.colorBarra = "success";
                            this.muestraBarra = true;
                        } else if (response.data.status == "EXPRESSION") {
                            deleteToken();
                        } else if (response.data.status == "ERROR" || !response.data.status) {
                            this.textoBarra = "Error de al actualizar. Intenta nuevamente.";
                            this.colorBarra = "error";
                            this.muestraBarra = true;
                        }
                    }).catch((error) => {
                        console.error("Error al obtener los datos:", error);
                        this.textoBarra = "Error de conexión. Intenta nuevamente.";
                        this.colorBarra = "error";
                        this.muestraBarra = true;
                    })
                    .finally(() => {
                        this.loadingCierre = false;
                    });
            }
        },
        getColaboradores() {
            const params = {
                Opcion: 11,
                token: localStorage.getItem("token"),
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/SeguridadPatrimonial/ws-pases-de-salida.php`,
                    params
                )
                .then((response) => {
                    this.loadingData = false;
                    if (response.data.status === "OK") {
                        this.colaboradores = response.data.respuesta;
                    } else if (response.data.status == "EXPRESSION") {
                        deleteToken();
                        throw new Error('Token expired');
                    } else {
                        throw new Error('Failed to load colaboradores');
                    }
                })
                .catch((error) => {
                    this.openSnackBar(error);
                    console.error('Error loading colaboradores:', error);
                })
                .finally(() => {
                    this.loadingColaboradores = false;
                });
        },
        enviarRevisionCorreo() {
            this.loadingRevisionCorreo = true;

            const params = {
                Opcion: 15,
                reporte: this.revision,
                token: localStorage.getItem("token"),
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status === "OK") {
                        this.openRevision = false;
                        this.textoBarra = "Recordatorio de revisión enviado correctamente.";
                        this.colorBarra = "success";
                        this.muestraBarra = true;
                    } else if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else if (response.data.status == "ERROR" || !response.data.status) {
                        this.textoBarra = "Error al enviar correo. Intenta nuevamente.";
                        this.colorBarra = "error";
                        this.muestraBarra = true;
                    }
                })
                .catch((error) => {
                    this.openSnackBar(error);
                    console.error('Error envio correo revision:', error);
                })
                .finally(() => {
                    this.loadingRevisionCorreo = false;
                });
        },
        enviarReparacionCorreo() {
            this.loadingReparacionCorreo = true;

            const params = {
                Opcion: 16,
                reporte: this.reparacion,
                token: localStorage.getItem("token"),
            };
            axios
                .post(
                    `${this.$store.getters.getHost}/Nominas/AdmInconformidades/ws-inconformidades.php`,
                    params
                )
                .then((response) => {
                    if (response.data.status === "OK") {
                        this.openReparacion = false;
                        this.textoBarra = "Recordatorio de reparacion enviado correctamente.";
                        this.colorBarra = "success";
                        this.muestraBarra = true;
                    } else if (response.data.status == "EXPRESSION") {
                        deleteToken();
                    } else if (response.data.status == "ERROR" || !response.data.status) {
                        this.textoBarra = "Error al enviar correo. Intenta nuevamente.";
                        this.colorBarra = "error";
                        this.muestraBarra = true;
                    }
                })
                .catch((error) => {
                    this.openSnackBar(error);
                    console.error('Error envio correo reparacion:', error);
                })
                .finally(() => {
                    this.loadingReparacionCorreo = false;
                });
        },
        async getPuesto() {
            this.loadingInfo = true;
            axios.get(`${this.$store.getters.getHostNode}/api/getPerfilTarjetas`, {
                headers: {
                    Authorization: localStorage.getItem("token")
                },
                timeout: 0
            })
                .then((response) => {
                    if (response.data.status) {
                        if (response.data.data.tiporol === 0) {
                            this.perfilAdmin = true;
                            this.inconformidades = this.inconformidadesData;
                            this.perfilMto = false;
                        }
                        else if (response.data.data.tiporol === 1) {
                            this.perfilMto = true;
                            const folio = Number(localStorage.getItem("folio"));
                            //console.log("Folio obtenido:", folio, "Tipo:", typeof folio); // Depuración

                            if (folio) {
                                const filteredArray = this.inconformidadesData.filter(item => {
                                    if (!item.responsableAccionContencion) return false; // Ignorar si es null o vacío

                                    // Extraer números de responsableAccionContencion usando RegExp
                                    const matches = item.responsableAccionContencion.match(/\d+/g); // Buscar solo los números

                                    if (!matches) return false;

                                    // Convertir los valores extraídos a números
                                    const responsables = matches.map(num => Number(num));
                                    //console.log("Responsables extraídos:", responsables, "Tipo:", typeof responsables[0]); // Debug

                                    // Comparar estrictamente los valores numéricos
                                    const coincidencia = responsables.some(responsable => responsable === folio);

                                    //console.log("Coincidencia:", coincidencia);

                                    return coincidencia;
                                });
                                // console.log("Resultados filtrados:", filteredArray);
                                this.inconformidades = filteredArray;
                            }
                        }
                    }
                    this.loadingInfo = false;
                })
                .catch((error) => {
                    console.error(error);
                });

        },

    },
};
</script>