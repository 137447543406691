import CalendarioTabs from "./components/IncidenciasComite.vue"

export default {
  name: 'incidencias-comite',
  components: {CalendarioTabs},
  props: [],
  data () {
    return {

    }
  },
  computed: {

  },
  mounted () {

  },
  methods: {

  }
}


