<template>
  <v-container fluid class="pt-0">
    <v-card elevation="2">
      <v-tabs
        v-model="tab"
        background-color="primary"
        centered
        dark
        icons-and-text
      >
        <v-tabs-slider></v-tabs-slider>

        <v-tab href="#tab-1">
          Mi información
          <v-icon>mdi-account-box</v-icon>
        </v-tab>

        <v-tab
          href="#tab-2"
          v-if="
            !gruposTrabajoArray.includes(colaborador.grupo_Trabajo) &&
            (miArea == 3933 || miArea == 3934)
          "
        >
          Escalera de crecimiento
          <v-icon>mdi-stairs-up</v-icon>
        </v-tab>

        <v-tab href="#tab-3">
          Mi CV
          <v-icon>mdi-card-account-details-outline</v-icon>
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item value="tab-1">
          <p
            :class="
              $vuetify.breakpoint.name == 'xs' ||
              $vuetify.breakpoint.name == 'sm'
                ? 'mb-1'
                : 'd-flex mb-1'
            "
            v-if="$store.getters.getUser['compania'] == '00100'"
          >
            <v-card
              :width="
                $vuetify.breakpoint.name == 'xs' ||
                $vuetify.breakpoint.name == 'sm'
                  ? '100%'
                  : '60%'
              "
              color="grey lighten-4"
            >
              <v-card-title
                class="text-subtitle-2 font-italic primary white--text d-flex justify-center"
              >
                <v-icon color="white">mdi-account</v-icon> Información
                colaborador
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col
                    class="d-flex align-center"
                    align="left"
                    xl="4"
                    lg="4"
                    md="4"
                    sm="12"
                    xs="12"
                  >
                    <v-hover v-slot="{ hover }">
                      <v-avatar size="150" rounded>
                        <v-img
                          margin="auto"
                          class="rounded-circle"
                          :src="fotoPerfil"
                        >
                          <v-expand-transition bottom>
                            <div
                              v-if="hover"
                              class="fondo-imagen d-flex transition-fast-in-fast-out white--text"
                              style="
                                display: flex;
                                justify-content: center;
                                height: 100%;
                                width: 100%;
                              "
                            >
                              <v-chip
                                class="fondo-chip"
                                style="margin-top: 45%"
                                @click="abrirModal = true"
                              >
                                <v-icon>mdi-camera</v-icon> Editar foto</v-chip
                              >
                            </div>
                          </v-expand-transition>
                        </v-img>
                      </v-avatar>
                    </v-hover>
                  </v-col>
                  <v-col
                    class="d-flex align-center black--text"
                    align="left"
                    xl="8"
                    lg="8"
                    md="8"
                    sm="12"
                    xs="12"
                  >
                    <v-card-text class="pt-0">
                      <b>Número colaborador: </b>
                      {{ colaborador.EMPLID }} <br />
                      <b>Nombre: </b> {{ colaborador.NAME }} <br />
                      <b>Area: </b> [{{ colaborador.DEPTID }}]
                      {{ colaborador.area }} <br />
                      <b>Departamento: </b> [{{ colaborador.EMP_KEYDEP }}]
                      {{ colaborador.DESCR1 }}
                      <br />
                      <b>Puesto: </b>[{{ colaborador.JOBCODE }}]
                      {{ colaborador.DESCR }} <br />

                      <b>Estatus:</b> {{ colaborador.EMP_STATUS }} <br />
                      <b>Ingreso</b> {{ colaborador.HIRE_DT }} <br />
                      <b>Antiguedad</b>
                      {{ colaborador.antiguedad }} Año(s)<br />
                      <div
                        v-if="
                          colaborador['NAME_RESP'] == 'Responsable no asignado'
                        "
                      >
                        <v-btn
                          x-small
                          rounded
                          elevation="1"
                          column
                          color="red"
                          dark
                        >
                          {{ colaborador["NAME_RESP"] }}
                        </v-btn>
                      </div>
                      <div v-else>
                        <b> Responsable: </b>{{ colaborador["NAME_RESP"] }}
                      </div>
                      <div v-if="colaborador['turno'] == 'Turno no asignado'">
                        <b>Horarios:</b>
                        <v-btn
                          x-small
                          rounded
                          elevation="1"
                          column
                          color="red"
                          dark
                        >
                          {{ colaborador["turno"] }}
                        </v-btn>
                      </div>
                      <div v-else>
                        <b>Horarios: </b>{{ colaborador["turno"] }}
                      </div>
                      <b>Correo</b> {{ colaborador.Correo }} <br />
                      <b>Grupo trabajo: </b> {{ colaborador.grupo_Trabajo }}
                      <v-form
                        class="mt-5 d-flex"
                        ref="form"
                        v-model="valid"
                        lazy-validation
                      >
                        <v-autocomplete
                          v-model="form.backup"
                          label="En caso de ausencia ¿Quién podría apoyar en tus actividades?"
                          hint="Selecciona al colaborador que te pueda apoyar en caso de estar ausente"
                          :rules="comboBoxPlan"
                          :items="colaboradores"
                          :loading="loadingColaboradores"
                          filled
                          rounded
                          item-text="nombreMostrar"
                          item-value="EMPLID"
                        ></v-autocomplete>
                        <v-tooltip top color="teal lighten-1">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              :loading="loading"
                              :disabled="form.backup == '' ? true : false"
                              color="teal darken-2"
                              class="white--text"
                              fab
                              @click="validar"
                            >
                              <v-icon dark> mdi-content-save </v-icon>
                            </v-btn>
                          </template>
                          <label
                            class="text-uppercase font-weight-bold white--text"
                          >
                            Guardar
                          </label>
                        </v-tooltip>
                      </v-form>
                    </v-card-text>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <div v-if="colaborador['EMP_STATUS'] == 'Activo'" class="pt-5">
                  <p v-if="!ubicacion" class="d-flex justify-center">
                    <v-btn
                      rounded
                      color="error"
                      dark
                      v-if="esAdminTI > 0 || esAdminRL > 0"
                      @click="openDialogBajas()"
                      >Solicitar baja
                    </v-btn>

                    <v-btn
                      v-if="esAdminTI > 0 || perfil > 0"
                      rounded
                      color="teal darken-2"
                      dark
                      @click="imprimirGafete()"
                      >Imprimir Gafete</v-btn
                    >
                  </p>
                </div>
              </v-card-text>
            </v-card>
            <v-card
              :width="
                $vuetify.breakpoint.name == 'xs' ||
                $vuetify.breakpoint.name == 'sm'
                  ? '100%'
                  : '40%'
              "
              color="grey lighten-4"
            >
              <v-card-title
                class="text-subtitle-2 font-italic primary white--text d-flex justify-center"
              >
                <v-icon color="white">mdi-account-hard-hat</v-icon>
                Generalista Asignado
              </v-card-title>
              <v-card-text class="d-flex align-center">
                <v-row>
                  <v-col
                    class="d-flex align-center"
                    align="left"
                    xl="4"
                    lg="4"
                    md="4"
                    sm="12"
                    xs="12"
                  >
                    <v-avatar size="150" rounded>
                      <v-img
                        margin="auto"
                        class="rounded-circle"
                        :src="colaborador['linkCompletoGeneralista']"
                      >
                      </v-img>
                    </v-avatar>
                  </v-col>
                  <v-col
                    class="d-flex align-center black--text"
                    align="left"
                    xl="8"
                    lg="8"
                    md="8"
                    sm="12"
                    xs="12"
                  >
                    <v-card-text>
                      <b>Nombre</b>
                      {{ colaborador.nombreGeneralista }}
                      <br />
                      <b>Telefono</b>
                      {{ colaborador.telefonoGeneralista }} <br />
                      <b>Correo</b>
                      {{ colaborador.correoGeneralista }}
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </p>
          <p v-else>
            <v-card>
              <v-card-title style="display: flex; justify-content: center">
                <v-hover v-slot="{ hover }">
                  <v-avatar size="150" rounded>
                    <v-img
                      margin="auto"
                      class="rounded-circle"
                      :src="fotoPerfil"
                    >
                      <v-expand-transition bottom>
                        <div
                          v-if="hover"
                          class="fondo-imagen d-flex transition-fast-in-fast-out white--text"
                          style="
                            display: flex;
                            justify-content: center;
                            height: 100%;
                            width: 100%;
                          "
                        >
                          <v-chip
                            class="fondo-chip"
                            style="margin-top: 45%"
                            @click="abrirModal = true"
                          >
                            <v-icon>mdi-camera</v-icon> Editar foto</v-chip
                          >
                        </div>
                      </v-expand-transition>
                    </v-img>
                  </v-avatar>
                </v-hover>
              </v-card-title>
              <v-card-text>
                <center>
                  <h2>{{ colaborador["NAME"] }}</h2>
                  <h3>{{ colaborador["EMPLID"] }}</h3>
                </center>
              </v-card-text>
            </v-card>
          </p>
          <p
            :class="
              $vuetify.breakpoint.name == 'xs' ||
              $vuetify.breakpoint.name == 'sm'
                ? 'mb-1'
                : 'd-flex mb-1'
            "
          >
            <v-card
              class="mx-auto"
              color="grey lighten-4"
              :width="
                $vuetify.breakpoint.name == 'xs' ||
                $vuetify.breakpoint.name == 'sm'
                  ? '100%'
                  : '30%'
              "
            >
              <v-card-text
                align="left"
                v-if="$store.getters.getUser['compania'] == '00100'"
              >
                <b>IMSS: </b> {{ colaborador.imss }} <br />
                <b>CURP: </b> {{ colaborador.curp }} <br />
                <b>RFC: </b> {{ colaborador.rfc }} <br />
                <b>Dirección: </b> {{ colaborador.direccion }} <br />
                <b>Colonia: </b> {{ colaborador.colonia }} <br />
                <b>Municipio: </b> {{ colaborador.municipio }} <br />
                <b>Código Postal: </b> {{ colaborador.postal }} <br />
                <b>Proceso: </b> {{ colaborador.estado }}
              </v-card-text>
              <v-card-text
                align="left"
                v-else-if="$store.getters.getUser['compania'] == '01000'"
              >
                <b>Cedula de ciudadania</b> {{ colaborador.rfc }} <br />
                <b>Dirección</b> {{ colaborador.direccion }} <br />
                <b>Colonia</b> {{ colaborador.colonia }} <br />
                <b>Municipio</b> {{ colaborador.municipio }} <br />
                <b>Municipio</b> {{ colaborador.postal }} <br />
                <b>Proceso</b> {{ colaborador.estado }}
              </v-card-text>
            </v-card>
            <v-card
              class="mx-auto"
              color="grey lighten-4"
              :width="
                $vuetify.breakpoint.name == 'xs' ||
                $vuetify.breakpoint.name == 'sm'
                  ? '100%'
                  : '40%'
              "
            >
              <v-card-text
                align="left"
                v-if="$store.getters.getUser['compania'] == '00100'"
              >
                <p
                  v-if="muestraDesc == false"
                  class="d-inline-block text-truncate text-justify"
                  style="max-width: 350px; cursor: pointer"
                  @click="mostrarMas"
                >
                  <b>Descripción:</b> {{ colaborador.descripcionPuesto }}
                </p>
                <p
                  v-else
                  @click="mostrarMas"
                  style="cursor: pointer"
                  class="text-justify"
                >
                  <b>Descripción:</b> {{ colaborador["descripcionPuesto"] }}
                </p>
                <br />
                <b>Escolaridad</b> {{ colaborador["carrera"] }} <br />
                <b>Conocimientos</b>
                <div
                  v-for="(item, index) in colaborador['conocimiento']"
                  :key="index"
                >
                  {{ index + 1 }}.- {{ item }}
                </div>
                <br />
              </v-card-text>
            </v-card>
            <v-card
              class="mx-auto"
              color="grey lighten-4"
              :width="
                $vuetify.breakpoint.name == 'xs' ||
                $vuetify.breakpoint.name == 'sm'
                  ? '100%'
                  : '30%'
              "
            >
              <v-card-text align="left">
                <b>Objetivos</b>
                <div
                  v-for="(item, index) in colaborador['objetivos']"
                  :key="index"
                >
                  {{ item }}
                </div>
                <br />
              </v-card-text>
            </v-card>
          </p>
        </v-tab-item>
        <v-tab-item value="tab-2">
          <v-card color="blue-grey lighten-4">
            <familias-escaleras-crecimiento
              ref="familiasComponent"
              :item="colaborador.DEPTID"
              :depto="colaborador.EMP_KEYDEP"
              :modulo="'MiPersona'"
            ></familias-escaleras-crecimiento>
          </v-card>
        </v-tab-item>
        <v-tab-item value="tab-3">
          <v-row v-if="loadingSkeleton">
            <v-col cols="4">
              <v-skeleton-loader
                type="list-item-avatar-three-line"
              ></v-skeleton-loader>
              <v-skeleton-loader
                type="list-item-three-line"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="4">
              <v-skeleton-loader
                type="list-item-three-line"
              ></v-skeleton-loader>
              <v-skeleton-loader
                type="list-item-three-line"
              ></v-skeleton-loader>
              <v-skeleton-loader type="actions"></v-skeleton-loader>
            </v-col>
            <v-col cols="4">
              <v-skeleton-loader
                type="list-item-three-line"
              ></v-skeleton-loader>
              <v-skeleton-loader
                type="list-item-three-line,actions"
              ></v-skeleton-loader>
            </v-col>
            <v-col cols="12">
              <v-skeleton-loader type="image"></v-skeleton-loader>
            </v-col>
          </v-row>
          <v-card v-else>
            <v-card-text class="d-flex">
              <v-avatar size="10em" rounded>
                <v-img margin="auto" class="rounded-circle" :src="fotoPerfil">
                </v-img>
              </v-avatar>
              <p align="left" class="px-5">
                <b>Nombre</b><br />
                {{ colaborador.NAME }} <br />
                <b>Antiguedad</b><br />
                {{ colaborador.antiguedad }} Año(s)<br />

                <b>Puesto actual</b> <br />
                [{{ colaborador.JOBCODE }}] {{ colaborador.DESCR }}
                <br />
              </p>

              <div class="px-5" align="left">
                <b>Escolaridad</b> <br />
                {{ colaborador.carrera }} <br />
                <b>Conocimientos</b>
                <div v-if="conocimientosCV.length > 0">
                  <div v-for="(item, index) in conocimientosCV" :key="index">
                    {{ index + 1 }}.- {{ item.descr }}
                  </div>
                </div>
                <div v-else>
                  <div class="font-italic">
                    Seleccione sus conocimientos registrando su CV
                  </div>
                </div>
              </div>
              <div class="px-5" align="left">
                <b>Competencias</b>
                <div v-if="competencias.length > 0">
                  <div v-for="(item, index) in competencias" :key="index">
                    {{ index + 1 }}.- {{ item.competencia }}
                  </div>
                </div>
                <div v-else>
                  <div class="font-italic">
                    Seleccione sus competencias registrando su CV
                  </div>
                </div>

                <br />
                <b>Area de interés</b>
                <v-form
                  class="mt-5"
                  ref="formArea"
                  v-model="validArea"
                  lazy-validation
                >
                  <v-autocomplete
                    v-model="formCV.area"
                    label="Area"
                    hint="Selecciona un área que te interece"
                    :rules="comboBoxPlan"
                    :items="areas"
                    item-text="Area"
                    item-value="id"
                    deletable-chips
                    dense
                    filled
                    rounded
                    small-chips
                    color="cyan"
                    v-on:change="getPuestos(2)"
                  ></v-autocomplete>
                  <v-autocomplete
                    v-model="formCV.puestos"
                    :items="puestos"
                    :loading="loading"
                    :rules="comboBoxPuestos"
                    label="Puestos"
                    hint="Selecciona qué puestos te interesan"
                    deletable-chips
                    dense
                    filled
                    multiple
                    rounded
                    small-chips
                    item-value="JOBCODE"
                    item-text="DESCR"
                    color="cyan"
                  >
                  </v-autocomplete>
                </v-form>
                <p class="d-flex justify-end">
                  <v-spacer></v-spacer>
                  <v-btn
                    class="rounded-lg"
                    color="teal"
                    dark
                    :loading="loadingForm"
                    @click="guardarPuestos"
                  >
                    Guardar
                  </v-btn>
                </p>
              </div>
            </v-card-text>
            <p>
              <v-btn color="px-3 teal darken-2" dark @click="openFormCV = true">
                Registra tu CV
              </v-btn>
              <v-btn
                v-if="urlPsicometricos"
                color="px-3 teal darken-2"
                text
                @click="showLink = !showLink"
              >
                Psicometricos
              </v-btn>
            </p>

            <p v-if="showLink == true" class="d-flex justify-center">
              <v-card max-width="60%" class="pa-5 ma-2">
                <p>
                  <a
                    :href="urlPsicometricos"
                    target="_blank"
                    @click="viewAlert = true"
                  >
                    Mis Psicometricos
                  </a>
                </p>
                <v-alert
                  v-if="viewAlert"
                  border="left"
                  colored-border
                  type="info"
                  color="primary"
                  elevation="2"
                >
                  Una vez respondiendo sus psicometricos, favor de registrar su
                  id
                </v-alert>
                <p class="d-flex justify-center align-center">
                  <v-text-field
                    v-model="idPsicometrico"
                    label="¿Respondiste tus psicometrías?"
                    hint="Ingresa el ID que obtuviste al finalizar"
                    filled
                    rounded
                  ></v-text-field>
                  <v-btn
                    :loading="loadingPsicometricos"
                    :disabled="idPsicometrico == '' ? true : false"
                    color="teal darken-2"
                    class="mb-8 white--text"
                    fab
                    @click="guardarPsicometrico"
                  >
                    <v-icon dark> mdi-content-save </v-icon>
                  </v-btn>
                </p>
              </v-card>
            </p>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <b>Indicadores</b> <br />
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-data-table
                    :items="indicadoresPersonal"
                    :headers="headers"
                    item-key="id"
                    class="elevation-1"
                    dense
                    mobile-breakpoint="0"
                    :footer-props="{
                      'items-per-page-text': 'Elementos por página',
                    }"
                    no-data-text="Clic en cargar tema para agregar"
                  >
                  </v-data-table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <curriculum-colaborador
      v-if="openFormCV"
      :dialog="openFormCV"
      :colaborador="colaborador"
      @cierraForm="cierraForm"
    >
    </curriculum-colaborador>
    <CargarFotoPerfil
      v-if="abrirModal"
      :openDialogSubirImg="abrirModal"
      @cerrarModal="cierraModalPerfil"
      :folio="colaborador['EMPLID']"
      :ruta="fotoPerfil"
    >
    </CargarFotoPerfil>
    <gafeteColaborador
      v-if="dialogImprimirGafete"
      :dialog="dialogImprimirGafete"
      :colaborador="colaborador"
      @cierraImpresionGafete="dialogImprimirGafete = false"
    />
    <form-bajas-de-empleados
      v-if="dialogBajas"
      :dialog="dialogBajas"
      :colaborador="colaborador"
      @cierraDialogBajas="cierraDialog"
    ></form-bajas-de-empleados>

    <SnackBarErrorIncidencias
      v-if="muestraBarra"
      :muestraBarra="muestraBarra"
      :texto="textoBarra"
      :color="colorBarra"
      @cierraBarra="muestraBarra = false"
    />
  </v-container>
</template>



<script>
import axios from "axios";
import { deleteToken } from "../store/Funciones/funciones";
import CargarFotoPerfil from "../components/CargaFotoPerfil.vue";
import gafeteColaborador from "../views/generated/RelacionesLaborales/BajasDeEmpleados/EmpleadosActivos/components/GafeteColaborador.vue";
import FormBajasDeEmpleados from "../views/generated/RelacionesLaborales/BajasDeEmpleados/EmpleadosActivos/components/FormBajasDeEmpleados.vue";
import FamiliasEscalerasCrecimiento from "../views/generated/General/MiEmpresa/EscalerasDeCrecimiento/components/FamiliasEscalerasCrecimiento.vue";
import SnackBarErrorIncidencias from "../views/generated/General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue";
import CurriculumColaborador from "./componentsCVColaborador/CurriculumColaborador.vue";
export default {
  name: "persona-perfil",
  components: {
    CargarFotoPerfil,
    gafeteColaborador,
    FormBajasDeEmpleados,
    FamiliasEscalerasCrecimiento,
    SnackBarErrorIncidencias,
    CurriculumColaborador,
  },
  props: ["colaborador", "perfil", "esAdminTI", "esAdminRL"],
  data: () => ({
    textoBarra: "",
    colorBarra: "",
    muestraBarra: false,
    muestraDesc: false,

    loadingSkeleton: true,
    url: "",
    ubicacion: false,
    hover: false,
    abrirModal: false,
    dialogImprimirGafete: false,
    dialogBajas: false,
    showInfoUrrea: false,
    showInfoPersonal: false,
    showInfoPuesto: false,
    fotoPerfil: "",
    folio: "",
    tab: null,
    valid: true,
    validArea: true,
    form: {
      emplid: "",
      backup: "",
    },
    formCV: {
      emplid: "",
      area: "",
      puestos: [],
    },
    areas: [],
    puestos: [],
    colaboradores: [],
    comboBoxPlan: [
      (value) => !!value || "Este campo es requerido", // Validación de campo requerido
    ],
    loadingColaboradores: true,
    loadingForm: true,
    loading: false,
    gruposTrabajoArray: [
      "ALMACENISTA FIJO",
      "ALMACENISTA TEMPORAL",
      "MANUFACTURA FIJO",
      "MANUFACTURA TEMPORAL",
    ],
    miArea: "",
    miJobcode: "",
    indicadoresPersonal: [],
    competencias: [],
    headers: [
      {
        text: "Indicador",
        value: "DescObjetivo",
        align: "start",
        sortable: true,
      },
      {
        text: "Julio",
        value: "ValorPrimer",
        align: "center",
        sortable: true,
      },
      {
        text: "Agosto",
        value: "ValorSegundo",
        align: "center",
        sortable: true,
      },
      {
        text: "Septiembre",
        value: "ValorTercero",
        align: "center",
        sortable: true,
      },
    ],
    comboBoxPuestos: [
      (value) => !!value || "Este campo es requerido", // Validación de campo requerido
      (value) =>
        (Array.isArray(value) && value.length <= 3) ||
        "Selecciona al menos tres puestos",
    ],
    puestosInteres: [],
    area: "",
    openFormCV: false,

    conocimientosCV: [],
    showLink: false,
    idPsicometrico: "",
    urlPsicometricos: "",
    loadingPsicometricos: false,
    viewAlert: false,
  }),
  created() {},
  async mounted() {
    this.url = window.location.hash;
    if (this.url.includes("MiPersona")) {
      this.ubicacion = true;
      this.getPerfilColaborador(this.colaborador["EMPLID"]);
    }
    this.getColaboradores();
    await this.getIndicadores();
    await this.getAreas();
    await this.getPuestosInteres();
    this.getCurriculumColaborador();
    this.getUrlPsicometricos();
  },

  methods: {
    formatMXN(cadena) {
      return cadena.toLocaleString("es-MX", {
        style: "currency",
        currency: "MXN",
      });
    },

    getPerfilColaborador(EMPLID) {
      let params = {
        Opcion: 19,
        NPcliente1: EMPLID,
        token: localStorage.getItem("token"),
      };
      axios
        .post(`${this.$store.getters.getHost}/General/ws-general.php`, params)
        .then((response) => {
          if (response.data.status == "OK") {
            this.fotoPerfil =
              // this.$store.getters.getHost +
              "https://apidh.urrea.com/ArchivosCargadosEnPortal2/FotosPerfilUsuarios/" +
              response.data.data["foto"];
            this.folio = response.data.data["EMPLID"];
            this.miArea = response.data.data["DEPTID"];
            this.loading = false;
          }
        });
    },

    getColaboradores() {
      this.colaboradores = [];
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-all-colaboradores`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.colaboradores);

              this.form.backup = this.colaborador["backup"];
              response.data.colaboradores.forEach((element) => {
                if (element.departamento == this.colaborador.EMP_KEYDEP) {
                  this.colaboradores.push(element);
                }
              });
            } else if (response.data.status == "VACIO") {
              res(response.data.data);
              console.log("vacio");
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
            this.loadingColaboradores = false;
          });
      });
    },
    async getIndicadores() {
      this.miJobcode = this.colaborador.jobcode;
      this.indicadoresPersonal = [];
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-indicadores-personal`,
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.indicadoresPersonal = response.data.data.data;
            } else if (response.data.status == "VACIO") {
              res(response.data.data);
              console.log("vacio");
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
          });
      });
    },
    async getAreas() {
      const headers = { Authorization: localStorage.getItem("token") };

      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-areas`, {
            headers: headers,
          })
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.areas = response.data.data;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    getPuestos(opcion) {
      if (opcion == 2) {
        this.formCV.puestos = "";
      }
      if (this.formCV.area) {
        this.loading = true;
        const headers = { Authorization: localStorage.getItem("token") };
        return new Promise((res) => {
          axios
            .get(
              `${this.$store.getters.getHostNode}/api/get-puestos-por-area-mi-persona/${this.formCV.area}`,
              { headers }
            )
            .then((response) => {
              if (response.data.status == "OK") {
                res(response.data.data);
                this.puestos = response.data.data;
              } else if (response.data.status == "EXPRESSION") {
                deleteToken();
              } else {
                res([]);
              }
            })
            .finally(() => {
              this.loading = false;
            });
        });
      }
    },

    getCurriculumColaborador() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-curriculum-colaborador/${this.colaborador.EMPLID}`,
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.conocimientosCV = response.data.data.conocimientos;
              this.competencias = response.data.data.competencias;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
            this.loadingSkeleton = false;
          });
      });
    },

    getUrlPsicometricos() {
      const headers = {
        Authorization: localStorage.getItem("token"),
      };

      return new Promise((res) => {
        axios
          .get(
            `${this.$store.getters.getHostNode}/api/get-url-psicometricos/${this.colaborador.EMPLID}`,
            {
              headers: headers,
            }
          )
          .then((response) => {
            if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else if (response.data.status == "OK") {
              res(response.data.data);
              this.urlPsicometricos = response.data.data;
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingForm = false;
            this.loadingSkeleton = false;
            this.idPsicometrico = this.colaborador.idPsicometrico;
          });
      });
    },
    validar() {
      this.form.emplid = this.colaborador.EMPLID;
      this.loading = true;
      const validar = this.$refs.form.validate();
      if (validar) {
        if (this.colaborador.id_backup) {
          this.editar();
        } else {
          this.guardar();
        }
      } else {
        this.textoBarra = "Por favor, complete los datos requeridos";
        this.colorBarra = "error";
        this.muestraBarra = true;
        this.loading = false;
      }
    },

    guardar() {
      const headers = { Authorization: localStorage.getItem("token") };
      const params = {
        form: this.form,
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/set-backup-colaborador`,
            params,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
              this.colaborador.id_backup = response.data.data;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },

    editar() {
      const headers = { Authorization: localStorage.getItem("token") };
      const params = {
        form: this.form,
        id_backup: this.colaborador.id_backup,
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/update-backup-colaborador`,
            params,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    cierraModalPerfil(response) {
      this.abrirModal = false;
      if (response == "OK") {
        this.fotoPerfil = "";
        this.getPerfilColaborador();
      }
    },
    mostrarMas() {
      if (this.muestraDesc == true) {
        this.muestraDesc = false;
      } else {
        this.muestraDesc = true;
      }
    },

    cierraDialog(estatus) {
      this.dialogBajas = false;
      if (estatus.estatus == "reload") {
        window.location.reload();
      }
    },

    imprimirGafete() {
      this.dialogImprimirGafete = true;
    },

    openDialogBajas() {
      this.dialogBajas = true;
    },

    async getPuestosInteres() {
      this.loading = true;
      const headers = { Authorization: localStorage.getItem("token") };
      return new Promise((res) => {
        axios
          .get(`${this.$store.getters.getHostNode}/api/get-puestos-interes`, {
            headers,
          })
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.formCV.puestos = response.data.data.jobcode;
              this.formCV.area = response.data.data.area;
              this.getPuestos(1);
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },

    guardarPuestos() {
      const validar = this.$refs.formArea.validate();
      if (validar) {
        this.loadingForm = true;
        const headers = { Authorization: localStorage.getItem("token") };
        this.formCV.emplid = this.colaborador.EMPLID;
        const params = {
          form: this.formCV,
        };
        return new Promise((res) => {
          axios
            .post(
              `${this.$store.getters.getHostNode}/api/set-puestos-interes`,
              params,
              { headers }
            )
            .then((response) => {
              if (response.data.status == "OK") {
                res(response.data.data);
                this.textoBarra = response.data.message;
                this.colorBarra = "success";
                this.muestraBarra = true;
              } else if (response.data.status == "EXPRESSION") {
                deleteToken();
              } else {
                res([]);
              }
            })
            .finally(() => {
              this.loadingForm = false;
            });
        });
      } else {
        this.textoBarra =
          "Por favor, selecciona al menos 3 puestos para continuar";
        this.colorBarra = "error";
        this.muestraBarra = true;
      }
    },

    guardarPsicometrico() {
      this.loadingPsicometricos = true;
      const headers = { Authorization: localStorage.getItem("token") };
      const params = {
        idPsicometrico: this.idPsicometrico,
        folio: this.colaborador.EMPLID,
      };
      return new Promise((res) => {
        axios
          .post(
            `${this.$store.getters.getHostNode}/api/set-psicometricos-colaborador`,
            params,
            { headers }
          )
          .then((response) => {
            if (response.data.status == "OK") {
              res(response.data.data);
              this.textoBarra = response.data.message;
              this.colorBarra = "success";
              this.muestraBarra = true;
            } else if (response.data.status == "EXPRESSION") {
              deleteToken();
            } else {
              res([]);
            }
          })
          .finally(() => {
            this.loadingPsicometricos = false;
          });
      });
    },

    cierraForm(response) {
      this.openFormCV = false;
      if (response == "OK") {
        this.loadingSkeleton = true;
        this.conocimientosCV = [];
        this.competencias = [];
        this.getCurriculumColaborador();
      }
    },
  },
};
</script>
<style lang="sass" scoped>
.fondo-imagen
  background: rgb(232,239,237)
  background: radial-gradient(circle, rgba(232,239,237,1) 0%, rgba(113,142,187,0.7203081916360294) 100%)
  .fondo-chip
    background: rgb(240,244,246)
    background: radial-gradient(circle, rgba(240,244,246,1) 4%, rgba(255,255,255,1) 100%)
</style>