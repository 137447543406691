<template>
  <v-dialog v-model="dialogPreguntas" max-width="1200px">
    <snack-bar-error-incidencias v-if="muestraBarra" :muestraBarra="muestraBarra" :texto="textoBarra"
      :color="colorBarra" @cierraBarra="muestraBarra = false"></snack-bar-error-incidencias>
    <v-card>
      <v-toolbar color="primary" dark style="margin-bottom: 20px;">
        Preguntas de {{ this.nombre }}
        <v-spacer></v-spacer>

        <v-btn icon dark @click="$emit('cierraDialogEdicion')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-spacer></v-spacer>
      <v-card-text>
        <v-data-table v-model="selected" :headers="headersPreguntas" :items="preguntas" dense
          no-data-text="No hay preguntas" :search="search" :loading="loading" :footer-props="{
            'items-per-page-text': 'Elementos por página',
            pageText: '{0}-{1} de {2}',
          }" show-select>
          <template v-slot:top>
            <div style="display: flex; justify-content: space-between; align-items: center;"
              class="primary pt-4 rounded-l-t-header-pases-admin rounded-r-t-header-pases-admin rounded-lg rounded-b-0">
              <v-text-field v-model="search" label="Buscar..." dense flat solo-inverted dark clearable
                append-icon="mdi-magnify" style="max-height: 42px; max-width: 400px;"
                class="mx-4 rounded-lg"></v-text-field>
              <v-btn color="success" small @click="abrirDialogAgregarPregunta" class="mr-4">
                <v-icon left>mdi-plus-box</v-icon>Preguntas
              </v-btn>
              <v-btn color="teal" dark small @click="abrirDialogAsignarSeccion" class="mr-4">
                <v-icon left>mdi-plus-box</v-icon>Seccion
              </v-btn>

             <v-btn color="success" dark small @click="descargarReporteExcel" class="mr-4">
                <v-icon left>mdi-microsoft-excel</v-icon>Generar 
              </v-btn>
              
            </div>
          </template>

          <template v-slot:item.opciones="{ item }">
            <span v-if="item.opciones">
              {{ item.opciones.map(opcion => opcion.opcion).join(', ') }}
            </span>
          </template>


          <template v-slot:item.actions="{ item }">
            <v-chip-group column>
              <v-btn x-small rounded elevation="1" color="red" dark @click="eliminarPregunta(item)">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
              <v-btn x-small rounded elevation="1" color="primary" dark @click="editarPregunta(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </v-chip-group>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" elevation="2" @click="$emit('cierraDialogEdicion')">Cerrar</v-btn>
      </v-card-actions>
    </v-card>
    <v-dialog v-model="dialogAgregarPregunta" max-width="600px">
      <v-card>
        <v-toolbar color="primary" dark>
          {{ editMode ? 'Editar' : 'Agregar' }} Pregunta
          <v-spacer></v-spacer>

          <v-btn icon dark @click="dialogAgregarPregunta = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>

        </v-toolbar>
        <v-card-text>
          <v-alert v-if="alertaVisible" :type="alertaTipo" dismissible transition="scale-transition" class="mb-4">
            {{ alertaMensaje }}
          </v-alert>

          <v-select v-model="preguntaSeleccionada" :items="tiposDePregunta" label="Tipo de pregunta" outlined dense
            class="mt-4"></v-select>

          <v-text-field v-model="preguntaTexto" label="Escribe tu pregunta" outlined dense></v-text-field>

          <v-row v-if="preguntaSeleccionada === 'Cerrada' || preguntaSeleccionada === 'Opcion Multiple'">
            <v-col v-for="(opcion, index) in opciones" :key="index" cols="12" sm="6" md="4">
              <v-text-field v-model="opciones[index].opcion" :label="'Opción ' + (index + 1)" outlined
                dense></v-text-field>
            </v-col>
          </v-row>

          <v-btn small @click="agregarOpcion" class="mt-4" color="primary">Agregar Opcion</v-btn>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="teal" dark elevation="2" @click="confirmarAgregarPregunta">
            {{ editMode ? 'Actualizar' : 'Agregar' }} Pregunta
          </v-btn>
          <v-btn color="error" elevation="2" @click="dialogAgregarPregunta = false">
            Cerrar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogAsignarSeccion" max-width="500px" >
      <v-card>
        <v-toolbar color="primary" dark>
          Administrar Secciones
          <v-spacer></v-spacer>
          <v-btn icon dark @click="dialogAsignarSeccion = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-radio-group v-model="opcionSeleccionada" row>
            <v-radio label="Asignar preguntas a sección" value="asignar"></v-radio>
            <v-radio label="Editar secciones" value="editar"></v-radio>
          </v-radio-group>
          <div v-if="opcionSeleccionada === 'asignar'">
            <v-select v-model="seccionSeleccionada" :items="secciones" item-text="SECCION" item-value="ID"
              label="Selecciona una sección" outlined dense class="mt-4"></v-select>
          </div>

          <div v-else-if="opcionSeleccionada === 'editar'">
            <v-select v-model="seccionSeleccionada" :items="secciones" item-text="SECCION" item-value="ID"
              label="Selecciona una sección" outlined dense class="mt-4"></v-select>
            <v-menu ref="menuInicio" v-model="menuInicio" :close-on-content-click="false" transition="scale-transition"
              offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="nuevaSeccion.fechaInicio" label="Fecha de Inicio" class="rounded-lg" dense
                  outlined prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="nuevaSeccion.fechaInicio" scrollable @input="menuInicio = false"></v-date-picker>
            </v-menu>

            <v-menu ref="menuFin" v-model="menuFin" :close-on-content-click="false" transition="scale-transition"
              offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field v-model="nuevaSeccion.fechaFin" label="Fecha de Fin" class="rounded-lg" dense outlined
                  prepend-inner-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
              </template>
              <v-date-picker v-model="nuevaSeccion.fechaFin" scrollable @input="menuFin = false"></v-date-picker>
            </v-menu>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="opcionSeleccionada === 'asignar'" color="teal" dark elevation="2"
            @click="confirmarAsignarSeccion">
            Asignar
          </v-btn>
          <v-btn v-if="opcionSeleccionada === 'editar'" color="success" dark elevation="2" @click="actualizarSeccion">
            Actualizar Sección
          </v-btn>

          <v-btn color="error" elevation="2" @click="dialogAsignarSeccion = false">Cerrar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>


<script>
import axios from 'axios';
import SnackBarErrorIncidencias from '../../../General/MisIncidencias/components/componentsExtra/SnackBarErrorIncidencias.vue';


export default {
  components: {
    SnackBarErrorIncidencias
  },
  props: {
    dialogPreguntas: Boolean,
    idEncuesta: Number,
    nombre: String,
  },
  data() {
    return {
      textoBarra: '',
      colorBarra: '',
      muestraBarra: false,
      selected: [],
      alertaVisible: false,
      alertaTipo: '',
      alertaMensaje: '',
      search: '',
      loading: false,
      headersPreguntas: [
        { text: 'Número de Pregunta', value: 'numPregunta' },
        { text: 'Pregunta', value: 'pregunta' },
        { text: 'Tipo de Pregunta', value: 'tipoPregunta' },
        { text: 'Opciones', value: 'opciones' },
        { text: 'Seccion', value: 'seccion', sortable: false },
        { text: 'Acciones', value: 'actions', sortable: false },
      ],
      preguntas: [],
      dialogAgregarPregunta: false,
      dialogAsignarSeccion: false,
      tiposDePregunta: ['Abierta', 'Cerrada', 'Opcion Multiple'],
      preguntaSeleccionada: '',
      preguntaTexto: '',
      opciones: [],
      editMode: false,
      preguntaEditando: null,
      secciones: [],
      seccionSeleccionada: null,
      opcionSeleccionada: 'asignar',
      nuevaSeccion: { fechaInicio: '', fechaFin: '' },
      menuInicio: false,
      menuFin: false,
    };
  },
  watch: {
    dialogPreguntas(newVal) {
      if (newVal) this.cargarDetallesEncuesta();
    },
    seccionSeleccionada() {
      if (this.opcionSeleccionada === 'editar' || this.opcionSeleccionada === 'asignar') {
        this.cargarFechasSeccion();
      }
    },
    dialogAsignarSeccion(newVal) {
    if (!newVal) {
      this.limpiarDatosSeccion();
    }
  },
  },
  methods: {
    async actualizarSeccion() {
      if (!this.nuevaSeccion.fechaInicio || !this.nuevaSeccion.fechaFin || !this.seccionSeleccionada) {
        this.mostrarSnackBar('Por favor complete todos los campos para actualizar la sección', 'error');
        return;
      }
      const data = {
        fechaInicio: this.nuevaSeccion.fechaInicio,
        fechaFin: this.nuevaSeccion.fechaFin,
        Id: this.seccionSeleccionada,
      };

      try {
        const response = await axios.post(
          `${this.$store.getters.getHostNode}/api/editarFechaSeccion`,
          data,
          {
            headers: {
              Authorization: localStorage.getItem('token'),
            },
          }
        );
        if (response.data.status) {
          this.mostrarSnackBar('Sección actualizada con éxito', 'success');
          this.dialogAsignarSeccion = false;
          await this.cargarDetallesEncuesta();

        }
      } catch (error) {
        console.error('Error al actualizar la sección:', error);
        this.mostrarSnackBar('Error al actualizar la sección', 'error');
      }
    },
    mostrarSnackBar(mensaje, color) {
      this.textoBarra = mensaje;
      this.colorBarra = color;
      this.muestraBarra = true;
    },
    limpiarDatosSeccion() {
  this.seccionSeleccionada = null;
  this.nuevaSeccion = { fechaInicio: '', fechaFin: '' };
  this.opcionSeleccionada = 'asignar';
  this.menuInicio = false;
  this.menuFin = false;
},

    async cargarFechasSeccion() {
      const seccion = this.secciones.find(sec => sec.ID === this.seccionSeleccionada);
      if (seccion) {
        this.nuevaSeccion.fechaInicio = seccion.FECHA_INICIO ? new Date(seccion.FECHA_INICIO).toISOString().substr(0, 10) : '';
        this.nuevaSeccion.fechaFin = seccion.FECHA_FIN ? new Date(seccion.FECHA_FIN).toISOString().substr(0, 10) : '';
      }
    },
    async cargarDetallesEncuesta() {
      this.preguntas = [];
      this.loading = true;
      try {
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/getEncuestaDetallada/${this.idEncuesta}`, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        const preguntasRaw = response.data.data;
        const preguntas = [];

        preguntasRaw.forEach((pregunta) => {
          let existingQuestion = preguntas.find((q) => q.numPregunta === pregunta.NUM_PREGUNTA);
          if (existingQuestion) {
            if (pregunta.OPCION) {
              existingQuestion.opciones.push({
                idOpcion: pregunta.ID_OPCION,
                opcion: pregunta.OPCION
              });
            }
          } else {
            preguntas.push({
              id: pregunta.ID_PREGUNTA,
              numPregunta: pregunta.NUM_PREGUNTA,
              pregunta: pregunta.PREGUNTA,
              tipoPregunta: pregunta.TIPO_PREGUNTA,
              opciones: pregunta.OPCION ? [{
                idOpcion: pregunta.ID_OPCION,
                opcion: pregunta.OPCION
              }] : [],
              seccion: pregunta.SECCION
            });
          }
        });

        this.preguntas = preguntas;
      } catch (error) {
        console.error('Error al obtener preguntas:', error);
      } finally {
        this.loading = false;
      }
    },
    async abrirDialogAgregarPregunta() {
      this.dialogAgregarPregunta = true;
      this.preguntaSeleccionada = '';
      this.preguntaTexto = '';
      this.opciones = [];
      this.editMode = false;
      this.preguntaEditando = null;
    },
    async abrirDialogAsignarSeccion() {
      this.dialogAsignarSeccion = true;
      this.seccionSeleccionada = null;
      try {
        const response = await axios.get(`${this.$store.getters.getHostNode}/api/getSecciones/${this.idEncuesta}`, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        this.secciones = response.data.data;
      } catch (error) {
        console.error('Error al obtener secciones:', error);
      }
    },
    agregarOpcion() {
      this.opciones.push({ idOpcion: null, opcion: '' });
    },

    async confirmarAgregarPregunta() {
      if (!this.validarCampos()) return;

      const maxNumPregunta = this.preguntas.length > 0
        ? Math.max(...this.preguntas.map(pregunta => pregunta.numPregunta))
        : 0;

      // Construcción de las opciones incluyendo su ID
      const opcionesConId = this.opciones.map((opcionObj) => ({
  idOpcion: opcionObj.idOpcion || null,
  opcion: typeof opcionObj.opcion === 'string' ? opcionObj.opcion.trim() : String(opcionObj.opcion).trim()
}));


      const nuevaPregunta = {
        idEncuesta: this.idEncuesta,
        idPregunta: this.editMode ? this.preguntaEditando.id : undefined,
        numPregunta: this.editMode ? this.preguntaEditando.numPregunta : maxNumPregunta + 1,
        pregunta: this.preguntaTexto,
        tipoPregunta: this.preguntaSeleccionada,
        opciones: this.preguntaSeleccionada !== 'Abierta' ? opcionesConId : null
      };

      try {
        const response = await axios.post(`${this.$store.getters.getHostNode}/api/agregarPregunta`, nuevaPregunta, {
          headers: {
            Authorization: localStorage.getItem('token')
          }
        });

        if (response.data.status) {
          if (this.editMode) {
            const index = this.preguntas.findIndex((preg) => preg.id === this.preguntaEditando.id);
            if (index !== -1) {
              // Actualiza la pregunta en la lista local
              this.preguntas.splice(index, 1, { ...nuevaPregunta, id: this.preguntaEditando.id });
            }
            this.mostrarSnackBar('Pregunta actualizada con éxito', 'success');
          } else {
            nuevaPregunta.id = response.data.data.idPregunta; // Asigna el ID devuelto por el servidor
            this.preguntas.push(nuevaPregunta);
            this.mostrarSnackBar('Pregunta agregada con éxito', 'success');
          }
        } else {
          this.mostrarSnackBar('Error al agregar/actualizar la pregunta', 'red accent-2');
        }
      } catch (error) {
        console.log('Error al agregar/actualizar la pregunta:', error);
        this.mostrarSnackBar('Error al agregar/actualizar la pregunta', 'red accent-2');
      }

      this.dialogAgregarPregunta = false;
      this.editMode = false;
      this.preguntaEditando = null;
      await this.cargarDetallesEncuesta();
    },
    async confirmarAsignarSeccion() {
      if (!this.seccionSeleccionada) {
        this.mostrarSnackBar('Seleccione una sección', 'error');
        return;
      }

      const idsPreguntasSeleccionadas = this.selected.map((pregunta) => pregunta.id);
      const data = {
        idEncuesta: this.idEncuesta,
        idSeccion: this.seccionSeleccionada,
        idsPreguntas: idsPreguntasSeleccionadas,
      };

      try {
        await axios.post(`${this.$store.getters.getHostNode}/api/asignarPreguntasASeccion`, data, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        this.mostrarSnackBar('Preguntas asignadas a la sección con éxito', 'success');
        this.dialogAsignarSeccion = false;
        await this.cargarDetallesEncuesta();
      } catch (error) {
        console.error('Error al asignar preguntas a la sección:', error);
        this.mostrarSnackBar('Debes seleccionar al menos una pregunta', 'error');
      }
    },
    async agregarSeccion() {
      if (!this.nuevaSeccion.trim()) {
        this.mostrarSnackBar('El nombre de la sección no puede estar vacío', 'error');
        return;
      }
      try {
        const response = await axios.post(`${this.$store.getters.getHostNode}/api/agregarSeccion`, {
          nombre: this.nuevaSeccion,
          idEncuesta: this.idEncuesta,
        }, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        this.secciones.push(response.data.data);
        this.nuevaSeccion = '';
        this.mostrarSnackBar('Sección agregada con éxito', 'success');
      } catch (error) {
        console.error('Error al agregar la sección:', error);
        this.mostrarSnackBar('Error al agregar la sección', 'error');
      }
    },
    async eliminarSeccion(idSeccion) {
      try {
        await axios.delete(`${this.$store.getters.getHostNode}/api/eliminarSeccion/${idSeccion}`, {
          headers: {
            Authorization: localStorage.getItem('token'),
          },
        });
        this.secciones = this.secciones.filter(seccion => seccion.ID !== idSeccion);
        this.mostrarSnackBar('Sección eliminada con éxito', 'success');
      } catch (error) {
        console.error('Error al eliminar la sección:', error);
        this.mostrarSnackBar('Error al eliminar la sección', 'error');
      }
    },
    validarCampos() {
      if (!this.preguntaSeleccionada) {
        this.mostrarSnackBar('Seleccione un tipo de pregunta', 'error');
        return false;
      }
      if (!this.preguntaTexto.trim()) {
        this.mostrarSnackBar('El texto de la pregunta no puede estar vacío', 'error');
        return false;
      }
      if ((this.preguntaSeleccionada === 'Cerrada' || this.preguntaSeleccionada === 'Opcion Multiple') && this.opciones.length < 2) {
        this.mostrarSnackBar('Debe agregar al menos dos opciones', 'error');
        return false;
      }
      for (const opcion of this.opciones) {
        // Acceder a la propiedad 'opcion' del objeto y luego aplicar 'trim()'
        if (!opcion.opcion.trim()) {
          this.mostrarSnackBar('Las opciones no pueden estar vacías', 'error');
          return false;
        }
      }
      return true;
    },
    editarPregunta(item) {
      this.dialogAgregarPregunta = true;
      this.editMode = true;
      this.preguntaEditando = { ...item };
      this.preguntaTexto = item.pregunta;
      this.preguntaSeleccionada = item.tipoPregunta;

      // Asegurarse de que las opciones también incluyan el ID
      this.opciones = item.opciones.map(opcion => ({
        idOpcion: opcion.idOpcion,
        opcion: opcion.opcion
      }));
    },
    async eliminarPregunta(item) {
      const data = {
        idPregunta: item.id,
        idEncuesta: this.idEncuesta,
        numPregunta: item.numPregunta
      };
      try {
        const response = await axios.post(`${this.$store.getters.getHostNode}/api/eliminarPregunta`, data, {
          headers: {
            Authorization: localStorage.getItem('token')
          }
        });
        if (response.data.status) {
          this.preguntas = this.preguntas.filter(pregunta => pregunta.numPregunta !== item.numPregunta);
          await this.cargarDetallesEncuesta();
        } else {
          console.log('Error al eliminar la pregunta:', response.data.message);
        }
      } catch (error) {
        console.error('Error al eliminar la pregunta:', error);
      }
    },async descargarReporteExcel() {
        this.loading = true; 
        try {
            const response = await axios.get(
                `${this.$store.getters.getHostNode}/api/generarReporteExcel/${this.idEncuesta}`, 
                {
                    headers: { Authorization: localStorage.getItem('token') },
                    responseType: 'blob', // Importante para manejar el archivo binario
                }
            );

            // Crear un enlace para descargar el archivo
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `reporte_encuesta_${this.idEncuesta}.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error al descargar el reporte Excel:', error);
            this.mostrarSnackBar('Error al descargar el reporte', 'red accent-2');
        } finally {
            this.loading = false; // Desactiva el indicador de carga
        }
    },
  },
};
</script>