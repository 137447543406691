<template>
    <v-card elevation="0" class="mx-auto rounded-xl rounded-t-0" color="blue-grey lighten-5" max-width="95%">
        <v-tabs v-model="tab">
            <v-tab v-for="(empleado, index) in empleadosUnicos" :key="index">
                {{ empleado }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item v-for="(empleado, index) in empleadosUnicos" :key="index">
                <v-dialog v-model="selectedDetalle" content-class="fix-border-dialog" scrollable persistent>
                    <v-card class="rounded-xl elevation-0" color="blue-grey lighten-5">
                        <v-card-title class="primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold">
                            Cumplimiento al plan: {{ empleadoView }}
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="selectedDetalle = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text class="pt-1">
                            <v-data-table :headers="headersPlan" :items="datosPlan"
                                no-data-text="Ningún elemento coincide con el criterio de búsqueda" class="ma-3"
                                :footer-props="{
                                    'items-per-page-text': 'Elementos por página',
                                }" :header-props="{ 'short-by-text': 'Ordenar por', }"
                                loading-text="Cargando contenido..." :loading="cargaDetallePlan" dense>
                                <template v-slot:item.cumplimiento="{ item }">
                                    {{ formatPorcentaje(item.cumplimiento) }}
                                </template>
                                <!-- Formatear horas a dos decimales -->
                                <template v-slot:item.horas_finales_requeridas="{ item }">
                                    {{ formatHoras(item.horas_finales_requeridas) }}
                                </template>

                                <template v-slot:item.horas_finales_trabajadas="{ item }">
                                    {{ formatHoras(item.horas_finales_trabajadas) }}
                                </template>

                                <!-- Formatear fecha a un formato más legible -->
                                <template v-slot:item.semana_inicio="{ item }">
                                    {{ formatFecha(item.semana_inicio) }}
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <v-card class="rounded-xl elevation-0 pa-4" color="blue-grey lighten-5">
                    <v-sheet tile height="54" class="d-flex rounded-lg">
                        <!--<v-btn outlined class="mb-1" rounded color="grey darken-2" @click="setToday">Hoy</v-btn>-->
                        <v-btn fab text class="mb-1" color="grey darken-2" @click="prev(index)">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <!-- Aquí mostramos el mes actual -->
                        <span class="text-overline mt-3 font-weight-regular">{{ nombreMes }}</span>

                        <v-btn fab class="mb-1" text color="grey darken-2" @click="next(index)">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn class="text-overline mt-2 mx-2 font-weight-regular" small rounded outlined
                            color="grey darken-2" @click="selectedDetalleDialog()">
                            Detalle cumplimiento
                            <v-icon right>mdi-account-details-outline</v-icon>
                        </v-btn>
                    </v-sheet>
                    <v-calendar :ref="`calendar-${index}`" v-model="focusByEmpleado[index]" locale="es-mx"
                        color="primary" show-all :events="getEventsByEmpleado(empleado)"
                        :event-text-color="getEventTextColor" @click:event="showEvent" @click:more="viewDay"
                        :event-overlap-mode="mode" @click:date="viewDay"
                        style="background-color: transparent !important;" @change="updateRange(empleado)">
                    </v-calendar>
                </v-card>
                <v-dialog v-model="selectedOpen" content-class="fix-border-dialog" :max-width="dialogMaxWidth"
                    scrollable persistent>
                    <v-card elevation="24" color="grey lighten-4">
                        <v-toolbar :color="selectedEvent.color" dense>
                            <v-toolbar-title v-html="selectedEvent.name"
                                :class="selectedEvent['event-text-color']"></v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-btn icon dark @click="selectedOpen = false">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-toolbar>
                        <v-card-text class="mt-2">
                            <span v-show="!loadingButtonDetails" v-html="selectedEvent.details">
                            </span>
                            <br>
                            <span v-show="!loadingButtonDetails" v-html="selectedEvent.comentario">
                            </span>
                            <v-container v-if="respuestaDetalles.length > 0 && !loadingButtonDetails">
                                <p class="text-body-2 mb-2">
                                    <strong>Solicitada el día: </strong> {{ respuestaDetalles[0].FLARGA }}
                                </p>

                                <p v-if="respuestaDetalles[0].descIncidencia !== 'Tiempo por tiempo'"
                                    class="text-body-2 mb-2">
                                    <strong>Para el día:</strong>
                                    {{ respuestaDetalles[0].fechaInIncidencia }}
                                    <template
                                        v-if="respuestaDetalles[0].fechaInIncidencia !== respuestaDetalles[0].fechaFiIncidencia">
                                        <strong> al </strong> {{ respuestaDetalles[0].fechaFiIncidencia }}.
                                    </template>
                                </p>


                                <p v-else class="text-body-2 mb-2">
                                    <strong>Descansa el día:</strong> {{ respuestaDetalles[0].fechaInIncidencia
                                    }}.
                                    <strong>Por el día trabajado:</strong> {{
                                        respuestaDetalles[0].fechaFiIncidencia }}.
                                </p>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-tab-item>
        </v-tabs-items>

        <v-dialog v-model="dialogFecha" content-class="fix-border-dialog" :max-width="dialogMaxWidth" scrollable
            persistent>
            <v-card v-if="loadingChecadas" max-width="100%" min-height="50" class="center-item-in-div">
                <v-progress-circular indeterminate :size="30" :width="3" color="primary"></v-progress-circular>
            </v-card>
            <v-card v-else>
                <v-card-title v-if="selectedDate" class="primary white--text subtitle-2">
                    Registro de checadas: {{ selectedDate.date }}
                    <v-spacer></v-spacer>
                    <v-btn icon dark @click="dialogFecha = false">
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text v-if="typeof checadas === 'object' && checadas.checadasBiometricos">
                    <div v-if="checadas.checadasBiometricos.datos.length > 0">
                        <label class="overline caption">Biométricos</label>
                        <div v-for="(checada, index) in checadas.checadasBiometricos.datos" :key="index">
                            <div class="caption">
                                <strong>{{ formatDateHour(checada.fechaHora) }}</strong> ({{ checada.nombreDispositivo
                                }})
                            </div>
                        </div>
                    </div>
                    <div v-if="checadas.checadasLectores.datos.length > 0">
                        <label class="overline caption">Tarjetas</label>
                        <div v-for="(checada, index) in checadas.checadasLectores.datos" :key="index">
                            <div class="caption">
                                <strong>{{ formatDateHour(checada.dtEventReal) }}</strong> ({{ checada.tDesc }})
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="checadas.checadasLectores.datos.length == 0 && checadas.checadasBiometricos.datos.length == 0">
                        <label class="overline caption">Sin Registros de checadas</label>
                    </div>
                </v-card-text>
                <v-card-actions>
                    <v-btn small depressed class="rounded-pill mr-1" color="teal darken-3" outlined
                        @click="abrirDialogo('in')">
                        <v-icon left>mdi-arrow-collapse-up</v-icon>
                        agregar entrada
                    </v-btn>
                    <v-btn small depressed class="rounded-pill ml-1" color="cyan darken-3" outlined
                        @click="abrirDialogo('out')">
                        <v-icon left>mdi-arrow-collapse-down</v-icon>
                        agregar salida
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog" content-class="fix-border-dialog" :max-width="dialogMaxWidth" scrollable persistent>
            <v-card>
                <v-card-title class="primary white--text subtitle-2">
                    Seleccionar Hora de {{ tipoChecada === 'in' ? 'Entrada' : 'Salida' }}
                </v-card-title>
                <v-card-text class="ma-0 pa-0">
                    <v-time-picker v-model="horaSeleccionada" format="24hr" full-width></v-time-picker>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="dialog = false">Cancelar</v-btn>
                    <v-btn color="success" outlined @click="enviarHora" class="rounded-pill"
                        :loading="newChecada">Confirmar</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogChecadas" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Obteniendo checadas...
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogIncidencias" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Obteniendo incidencias...
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogFestivos" hide-overlay persistent width="300">
            <v-card color="primary" dark>
                <v-card-text>
                    Obteniendo dias festivos...
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-card>
</template>

<script>
import axios from "axios";
import { deleteToken } from "../../../../../../store/Funciones/funciones";



export default {
    data() {
        return {
            focus: new Date().toISOString().substr(0, 10),
            focusByEmpleado: {},
            eventsData: [],
            festivosData: [],
            IncidenciasData: [],
            dialogChecadas: false,
            dialogFestivos: false,
            dialogIncidencias: false,
            tab: 0,
            //
            selectedDate: null,
            dialogFecha: false, // Nueva variable para el diálogo
            //
            colorsText: [
                'yellow--text text--lighten-3',
                'green--text text--',
                'red--text text--',
                'red--text text--darken-4',
                'green--text text--darken-4',
                'grey--text text--darken-1',
                'yellow--text text--accent-4',
                'blue--text text--',
                'blue--text text--darken-4',
                'blue--text text--darken-2',
                'blue-grey--text text--lighten-3',
                'black--text',
                'white--text'
            ],
            estatusIncidencias: [],
            colors: [
                'lime darken-3',
                'green',
                'red',
                'red darken-4',
                'green darken-4',
                'grey darken-1',
                // 'yellow accent-4',
                'deep-purple lighten-1',
                'blue',
                'blue darken-4',
                'blue darken-2',
                'blue-grey lighten-3',
                'pink darken-1',
            ],
            secondarray: [],
            mode: 'stack',
            weekday: [0, 1, 2, 3, 4, 5, 6],
            modes: ['stack', 'column'],
            selectedOpen: false,
            selectedEvent: {},
            selectedElement: null,
            respuestaDetalles: [],
            loadingButtonDetails: false,
            checadas: [],
            empleadoView: null,
            loadingChecadas: false,
            //
            dialog: false,
            tipoChecada: null,
            horaSeleccionada: null,
            director: null,
            newChecada: false,
            //
            selectedDetalle: false,
            cargaDetallePlan: false,
            headersPlan: [
                { text: "Semana Inicio", value: "semana_inicio", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Días con Retardo", value: "dias_retardo", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Horas plan", value: "horas_finales_requeridas", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Horas trabajadas", value: "horas_finales_trabajadas", class: "text-uppercase font-weight-bold blue primary white--text" },
                { text: "Cumplimiento", value: "cumplimiento", class: "text-uppercase font-weight-bold blue primary white--text" },

            ],
            datosPlan: [],
        };
    },
    mounted() {
        this.cargarChecadas();
        this.cargarFestivos();
        this.getStatusIncedence();
        this.empleadosUnicos.forEach((empleado, index) => {
            const fechaActual = new Date();
            const primerDiaMes = new Date(fechaActual.getFullYear(), fechaActual.getMonth(), 1)
                .toISOString().substr(0, 10); // YYYY-MM-DD

            this.$set(this.focusByEmpleado, index, primerDiaMes);
        });
        if (!this.focusByEmpleado[this.tab]) {
            this.$set(this.focusByEmpleado, this.tab, new Date().toISOString().split("T")[0]);
        }
    },
    methods: {
        async cargarChecadas() {
            this.dialogChecadas = true;
            axios
                .get(`${this.$store.getters.getHostNode}/api/getChecadas`, {
                    headers: {
                        authorization: `${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    if (
                        response.data.message === "Token inválido"
                    ) {
                        deleteToken();
                    } else if (response.data.status == "OK") {
                        this.eventsData = response.data.data;
                    } else {
                        console.log('no ok');
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.dialogChecadas = false;
                });
        },
        async cargarFestivos() {
            this.dialogFestivos = true;
            try {
                const paramsHoliday = {
                    Opcion: 3,
                    token: localStorage.getItem('token')
                }; // Si necesitas enviar parámetros, agrégalo aquí
                const response = await axios.post(
                    `${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`,
                    paramsHoliday
                );

                if (response.data.festivos) {
                    this.festivosData = response.data.festivos.map(festivo => ({
                        name: festivo.DESCR,
                        start: festivo.dca_fecdia.split(" ")[0], // Solo la fecha
                        end: festivo.dca_fecdia.split(" ")[0],
                        color: "red", // Color rojo
                        textColor: "white" // Letras blancas
                    }));
                }
            } catch (error) {
                console.error("Error al obtener los festivos:", error);
            } finally {
                this.dialogFestivos = false;
            }
        },
        async cargarIncidencias(empleado) {
            this.dialogIncidencias = true;
            try {
                const paramsHoliday = {
                    Opcion: 2,
                    token: localStorage.getItem('token'),
                    esAdmin: true,
                    empleado: empleado
                }; // Si necesitas enviar parámetros, agrégalo aquí
                const response = await axios.post(
                    `${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`,
                    paramsHoliday
                );

                if (response.data.incidencias != null && response.data.incidencias.length > 0) {
                    var data = response.data.incidencias.map(incidencia => {
                        var opc = incidencia['Estatus'];

                        var textColor = this.colorsText[12];
                        var descripocionIncidencia = '';
                        var icono = '';
                        var detalleComentario = incidencia['Comentario'] ? "Comentario: " + incidencia['Comentario'] : '';
                        var detalleEstatus = "Estatus: " + this.estatusIncidencias[opc - 1]['EstDescripcion'];

                        if (incidencia['TipoIncidencia'] == 2) {
                            icono = 'mdi-door-open'
                        } else if (incidencia['TipoIncidencia'] == 6) {
                            icono = 'mdi-arrow-decision'
                        } else if (incidencia['TipoIncidencia'] == 4) {
                            icono = 'mdi-account-child-circle'
                        } else if (incidencia['TipoIncidencia'] == 1) {
                            icono = 'mdi-beach'
                        } else if (incidencia['TipoIncidencia'] == 7 || incidencia['TipoIncidencia'] == 11) {
                            icono = 'mdi-home'
                        } else if (incidencia['TipoIncidencia'] == 12) {
                            icono = 'mdi-calendar-alert-outline'
                        } else if (incidencia['TipoIncidencia'] == 0) {
                            icono = 'mdi-hospital-box-outline'
                        }

                        if (incidencia['TipoIncidencia'] == 4) {
                            descripocionIncidencia = incidencia['IncDescripcion'] + ": " + incidencia['PermDescripcion']
                        } else {
                            descripocionIncidencia = incidencia['IncDescripcion']
                        }

                        this.colorIncidences = this.getColor(opc);
                        if (opc == 1 || opc == 2) {
                            textColor = this.colorsText[11];
                        }

                        if ((incidencia['FechaInicialDes'] == incidencia['FechaFinalDes']) || incidencia['FechaFinalDes'] == null) {
                            var fecha_i = new Date(incidencia['FechaInicialDes']);

                            if (incidencia['TipoIncidencia'] == 2) {
                                this.secondarray.push({
                                    icon: icono,
                                    name: descripocionIncidencia,
                                    formatoHora: this.modifyHours(fecha_i.getHours()) + ":" + this.modifyMinutes(fecha_i.getMinutes()),
                                    start: fecha_i,
                                    color: this.colorIncidences,
                                    'event-text-color': textColor,
                                    details: detalleEstatus,
                                    estatus: incidencia['Estatus'],
                                    idIncidencia: incidencia['ID'],
                                    comentario: detalleComentario,


                                })
                            } else {
                                this.secondarray.push({
                                    icon: icono,
                                    name: descripocionIncidencia,
                                    start: fecha_i,
                                    color: this.colorIncidences,
                                    'event-text-color': textColor,
                                    details: detalleEstatus,
                                    estatus: incidencia['Estatus'],
                                    idIncidencia: incidencia['ID'],
                                    comentario: detalleComentario,

                                })
                            }
                        } else if (incidencia['TipoIncidencia'] === 0) {
                            var inicio = new Date(incidencia['FechaInicialDes']);
                            var fin = new Date(incidencia['FechaFinalDes']);
                            this.secondarray.push({
                                icon: icono,
                                name: descripocionIncidencia,
                                start: inicio,
                                end: fin,
                                color: this.getColor(12),
                                'event-text-color': this.colorsText[12],
                                details: detalleEstatus,
                                estatus: incidencia['Estatus'],
                                idIncidencia: incidencia['ID'],
                                comentario: detalleComentario,
                            })
                        } else {
                            var i = new Date(incidencia['FechaInicialDes']);
                            var f = new Date(incidencia['FechaFinalDes']);
                            this.secondarray.push({
                                icon: icono,
                                name: descripocionIncidencia,
                                start: i,
                                end: f,
                                color: this.colorIncidences,
                                'event-text-color': textColor,
                                details: detalleEstatus,
                                estatus: incidencia['Estatus'],
                                idIncidencia: incidencia['ID'],
                                comentario: detalleComentario,
                            })
                        }
                        return incidencia;
                    });
                    console.log(data.length);
                    this.IncidenciasData = this.secondarray;

                }
            } catch (error) {
                console.error("Error al obtener las incidencias:", error);
            } finally {
                this.dialogIncidencias = false;
            }
        },
        getEventTextColor(event) {
            return event.textColor || "white"; // Usa el color definido o blanco por defecto
        },
        showEvent({ nativeEvent, event }) {
            // Cerrar el menú antes de actualizar los datos
            if (event.idIncidencia) {
                this.selectedOpen = false;
                // Usar $nextTick para asegurar que el DOM actualiza antes de reabrir
                this.$nextTick(() => {
                    this.selectedEvent = event;
                    this.selectedElement = nativeEvent.target;
                    this.respuestaDetalles = []; // Limpiar detalles antes de abrir
                    this.selectedOpen = true;
                    this.muestraDetalles(this.selectedEvent.idIncidencia);
                });
            }
        },
        viewDay(date) {
            this.selectedDate = date;
            this.dialogFecha = true;
            const eventoEncontrado = this.eventsData.find(evento => evento.nombreDirector === this.empleadoView);
            if (eventoEncontrado) {
                this.getChecadas(eventoEncontrado.idEmpleado, this.selectedDate.date);
            } else {
                console.log("No se encontró el empleado en los eventos.");
            }
        },
        updateRange(empleado) {
            this.IncidenciasData = [];
            this.secondarray = [];
            this.empleadoView = empleado;
            const eventoEncontrado = this.eventsData.find(evento => evento.nombreDirector === empleado);
            if (eventoEncontrado) {
                this.cargarIncidencias(eventoEncontrado.idEmpleado);
            } else {
                console.log("No se encontró el empleado en los eventos.");
            }
        },

        setToday() {
            if (this.focusByEmpleado[this.tab] !== undefined) {
                this.focusByEmpleado[this.tab] = new Date().toISOString().substr(0, 10);
            }
        },
        prev() {
            const refName = `calendar-${this.tab}`;
            if (this.$refs[refName] && this.$refs[refName][0]) {
                this.$refs[refName][0].prev();
            }
        },
        next() {
            const refName = `calendar-${this.tab}`;
            if (this.$refs[refName] && this.$refs[refName][0]) {
                this.$refs[refName][0].next();
            }
        },
        getEventsByEmpleado(idEmpleado) {
            const eventosEmpleado = this.eventsData
                .filter(evento => evento.nombreDirector === idEmpleado)
                .flatMap(evento => {
                    // Validar si las horas son válidas antes de agregarlas
                    const primeraHora = evento.primera_hora ? evento.primera_hora.replace("T", " ").slice(0, 19) : undefined;
                    let ultimaHora = evento.ultima_hora ? evento.ultima_hora.replace("T", " ").slice(0, 19) : undefined;

                    ultimaHora = primeraHora === ultimaHora ? undefined : ultimaHora;
                    // Solo agregar el evento si las horas son válidas
                    const eventos = [];

                    if (primeraHora) {
                        eventos.push({
                            name: `Entrada`,
                            start: primeraHora,
                            end: primeraHora,
                            color: "blue-grey lighten-3",
                            textColor: "blue darken-3"
                        });
                    }

                    if (ultimaHora) {
                        eventos.push({
                            name: `Salida`,
                            start: ultimaHora,
                            end: ultimaHora,
                            color: "blue-grey lighten-3",
                            textColor: "blue darken-3"
                        });
                    }

                    return eventos;
                });

            return [...eventosEmpleado, ...this.festivosData, ... this.IncidenciasData]; // Agregar los extras
        },
        modifyHours(hours) {
            if (hours < 10) {
                return "0" + hours.toString();
            }

            return hours.toString();
        },
        modifyMinutes(minutes) {
            if (minutes < 10) {
                return "0" + minutes.toString();
            }

            return minutes.toString();
        },
        async getStatusIncedence() {
            let paramsEstatusInc = {
                Opcion: 4,
                token: localStorage.getItem('token')
            };
            //(paramsEstatusInc)
            axios.post(`${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`, paramsEstatusInc)
                .then((response) => {
                    if (response.data.status == "EXPSESSION") {
                        deleteToken()
                    } else {
                        var data = response.data.estatusIncidencias;
                        this.estatusIncidencias = data;
                    }

                })
                .catch(function (e) {

                    let result = e.toString().includes("401");
                    if (result) {
                        window.location.reload();
                    } else {
                        alert(e);
                    }
                });

        },
        getColor(opc) {
            let color = '';

            switch (opc) {
                case 1:
                    color = this.colors[opc - 1];
                    break;
                case 2:
                    color = this.colors[opc - 1];
                    break;
                case 3:
                    color = this.colors[opc - 1];
                    break;
                case 4:
                    color = this.colors[opc - 1];
                    break;
                case 5:
                    color = this.colors[opc - 1];
                    break;
                case 6:
                    color = this.colors[opc - 1];
                    break;
                case 7:
                    color = this.colors[opc - 1];
                    break;
                case 8:
                    color = this.colors[opc - 1];
                    break;
                case 9:
                    color = this.colors[opc - 1];
                    break;
                case 10:
                    color = this.colors[opc - 1];
                    break;
                case 11:
                    color = this.colors[opc - 1];
                    break;
                case 12://Incapacidades no tiene estatus
                    color = this.colors[opc - 1];
                    break;
                default:
                    color = 'white';
                    break;
            }

            return color;
        },
        muestraDetalles(id) {
            this.respuestaDetalles.length = 0;
            this.loadingButtonDetails = true;
            var params = {
                Opcion: 7,
                id: id,
                token: localStorage.getItem('token')
            };
            axios.post(`${this.$store.getters.getHost}/Incidencias/ws-incidencias-calendario.php`, params)
                .then((response) => {
                    var data = response.data.detalleIncidencia;
                    this.respuestaDetalles = data;
                    this.loadingButtonDetails = false;
                    console.log(this.respuestaDetalles.length);
                    this.selectedOpen = true;

                })
                .catch(function (e) {
                    console.error(e);
                });


        },
        getChecadas(empleado, fecha) {
            this.loadingChecadas = true;
            this.checadas = [];
            const headers = {
                Authorization: localStorage.getItem("token"),
            };

            axios
                .get(
                    `${this.$store.getters.getHostNode
                    }/api/get-detalles-checadas-empleado/${fecha}/${empleado}`,
                    { headers }
                )
                .then((response) => {
                    if (response.data.status === "EXPSESSION") {
                        deleteToken();
                    } else {
                        this.checadas = response.data.data;
                    }
                })
                .catch((error) => {
                    console.error("Error al obtener checadas:", error);
                    this.$toast.error("Error al obtener los datos, intenta de nuevo.");
                })
                .finally(() => {
                    this.loadingChecadas = false;
                });
        },
        formatDateHour(fechaHora) {
            var fechaObjeto = new Date(fechaHora);
            var hora = fechaObjeto.getUTCHours();
            var minutos = fechaObjeto.getUTCMinutes();
            var segundos = fechaObjeto.getUTCSeconds();

            var horaFormateada = (hora < 10 ? "0" : "") + hora;
            var minutosFormateados = (minutos < 10 ? "0" : "") + minutos;
            var segundosFormateados = (segundos < 10 ? "0" : "") + segundos;

            var horaCompleta =
                horaFormateada + ":" + minutosFormateados + ":" + segundosFormateados;

            return horaCompleta;
        },
        abrirDialogo(tipo) {
            this.tipoChecada = tipo;
            this.dialog = true;
            this.horaSeleccionada = null;
        },
        async enviarHora() {
            this.newChecada = true;

            if (!this.horaSeleccionada) {
                alert("Selecciona una hora antes de confirmar.");
                return;
            }
            const eventoEncontrado = this.eventsData.find(evento => evento.nombreDirector === this.empleadoView);
            if (eventoEncontrado) {
                this.director = eventoEncontrado.idEmpleado;
            } else {
                console.log("No se encontró el empleado en los eventos.");
                return;
            }
            try {
                const response = await axios.post(`${this.$store.getters.getHostNode
                    }/api/setChecadasComite`, {
                    tipo: this.tipoChecada,
                    hora: this.horaSeleccionada,
                    dia: this.selectedDate.date,
                    director: this.director
                }, {
                    headers: {
                        Authorization: localStorage.getItem("token"),
                    }
                }
                );

                if (response.data.status == 'OK') {
                    this.cargarChecadas();
                    this.dialog = false;
                    this.dialogFecha = false;

                } else {
                    alert("Error al registrar la checada");
                }
            } catch (error) {
                console.error("Error en la petición:", error);
                alert("Hubo un error en la conexión");
            } finally {
                this.newChecada = false;
            }
        },
        selectedDetalleDialog() {
            this.selectedDetalle = true;
            const eventoEncontrado = this.eventsData.find(evento => evento.nombreDirector === this.empleadoView);
            if (eventoEncontrado) {
                this.getDetallePlan(eventoEncontrado.idEmpleado);
            } else {
                console.log("No se encontró el empleado en los eventos.");
            }
        },
        async getDetallePlan(empleado) {
            this.cargaDetallePlan = true;
            this.datosPlan = [];
            axios
                .get(`${this.$store.getters.getHostNode}/api/getCumplimientoComite/${empleado}`, {
                    headers: {
                        authorization: `${localStorage.getItem("token")}`,
                    },
                })
                .then((response) => {
                    if (
                        response.data.message === "Token inválido"
                    ) {
                        deleteToken();
                    } else if (response.data.status == "OK") {
                        this.datosPlan = response.data.data;
                    } else {
                        console.log('no ok');
                    }
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.cargaDetallePlan = false;
                });
        },
        formatPorcentaje(valor) {
            return `${valor.toFixed(2)} %`;
        },
        formatHoras(valor) {
            return valor.toFixed(2);
        },
        formatFecha(fecha) {
            return new Date(fecha).toISOString().split("T")[0]; // Devuelve solo YYYY-MM-DD
        },
    },
    computed: {
        empleadosUnicos() {
            return [...new Set(this.eventsData.map(evento => evento.nombreDirector))];
        },
        dialogMaxWidth() {
            // Verifica el tamaño de la pantalla
            if (this.$vuetify.breakpoint.smAndDown) {
                return '90%'; // 80% en pantallas pequeñas (sm o xs)
            } else {
                return '30%'; // 30% en pantallas grandes
            }
        },
        nombreMes() {
            if (!this.focusByEmpleado[this.tab]) return "";

            // Convertir focusByEmpleado[this.tab] a una fecha real
            const fecha = new Date(this.focusByEmpleado[this.tab] + "T00:00:00");
            return fecha.toLocaleDateString("es-MX", { month: "long", year: "numeric" });
        }
    },
    watch: {
        focusByEmpleado: {
            deep: true,
            handler() {
                this.$forceUpdate(); // Forzar actualización para reflejar el nuevo mes
            }
        }
    }

};
</script>
<style>
.src-views-mis-incidencias {}

.loader {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.loader::before,
.loader:after {
    content: "";
    position: absolute;
    border-radius: inherit;
}

.loader:before {
    width: 100%;
    height: 100%;
    background-image: linear-gradient(0deg, #1565C0 0%, #84FFFF 100%);
    animation: spin .5s infinite linear;
}

.loader:after {
    width: 85%;
    height: 85%;
    background-color: #F5F5F5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}
</style>