var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-md elevation-3 mt-0 pa-1",attrs:{"color":"#ECEFF1"}},[_c('v-card',{staticClass:"rounded-xl elevation-2 mt-1 pa-4 "},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"search":_vm.search,"items":_vm.usuarios,"no-data-text":"No hay registros ","footer-props":{
                'items-per-page-text': 'Elementos por página',
            },"header-props":{ 'short-by-text': 'Ordenar por', },"loading-text":"Cargando contenido...","loading":_vm.loadingComisiones},scopedSlots:_vm._u([{key:"item.Fecha",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatearFecha(item.Fecha))+" ")]}},{key:"top",fn:function(){return [_c('div',{staticStyle:{"display":"flex","justify-content":"space-between"}},[_c('v-text-field',{staticClass:"rounded-xl",staticStyle:{"max-width":"350px"},attrs:{"outlined":"","dense":"","prepend-inner-icon":"mdi-table-search","placeholder":"Buscar en tabla..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('vue-json-to-csv',{staticClass:"ma-0",attrs:{"json-data":_vm.itemsExcel,"labels":_vm.headers_report_to_export,"csv-title":_vm.title}},[_c('v-tooltip',{attrs:{"bottom":"","color":"blue lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill ml-2",attrs:{"depressed":"","dark":"","color":"green darken-2","outlined":""},on:{"click":_vm.exportToCsv}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-microsoft-excel")]),_vm._v(" Exportar ")],1)]}}])},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Descarga la info mostrada en la tabla")])])],1),_c('div',[_c('v-tooltip',{attrs:{"bottom":"","color":"blue lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded-pill ml-2",attrs:{"depressed":"","color":"teal","outlined":"","dark":""},on:{"click":function($event){return _vm.abrirCargaReporte()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v(" mdi-plus ")]),_c('label',{staticClass:"text-uppercase font-weight-bold "},[_vm._v("Cargar Reporte")])],1)]}}])},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("CARGA REPORTE SEMANAL ")])]),_c('v-tooltip',{attrs:{"bottom":"","color":"blue lighten-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"small":"","fab":"","depressed":"","outlined":""},on:{"click":function($event){return _vm.colseAgregaComisionistas(1)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-reload")])],1)]}}])},[_c('label',{staticClass:"text-uppercase font-weight-bold primary--text"},[_vm._v("Actualizar tabla")])])],1)],1)]},proxy:true}])}),_c('v-dialog',{attrs:{"max-width":"700","scrollable":"","persistent":"","content-class":"fix-border-dialog"},model:{value:(_vm.mostrarCargarUsuarios),callback:function ($$v) {_vm.mostrarCargarUsuarios=$$v},expression:"mostrarCargarUsuarios"}},[_c('v-card',{staticClass:"rounded-xl"},[_c('v-card-title',{staticClass:"primary rounded-xl rounded-b-0 white--text subtitle-1 font-weight-bold"},[_vm._v(" Cargar Reporte "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){_vm.mostrarCargarUsuarios = false}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"8"}},[_c('v-file-input',{attrs:{"accept":".xlsx, .xls, .csv","placeholder":"Selecciona el archivo","prepend-icon":"mdi-microsoft-excel"},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}}),_c('v-btn',{staticClass:"mt-2",attrs:{"color":"primary","disabled":!_vm.file},on:{"click":_vm.handleFileUpload}},[_vm._v(" Cargar Archivo ")])],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"color":_vm.snackbar.color},model:{value:(_vm.snackbar.visible),callback:function ($$v) {_vm.$set(_vm.snackbar, "visible", $$v)},expression:"snackbar.visible"}},[_vm._v(" "+_vm._s(_vm.snackbar.text)+" "),_c('v-btn',{attrs:{"color":"white","text":""},on:{"click":function($event){_vm.snackbar.visible = false}}},[_vm._v("Cerrar")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }